import { NotImplementedError, assertNever } from '@kpler/generic-utils';
import {
  Platform as GraphQlPlatform,
  Granularity as GraphQlGranularity,
  InventoriesSplit as GraphQlInventoriesSplit,
  ResourceType as GraphQlResourceType,
  InventoriesSnapshotSplit as GraphQlInventoriesSnapshotSplit,
  InventoriesDatasetName as GraphQLInventoriesDatasetName,
} from '@kpler/terminal-graphql';
import { Granularity } from '@kpler/terminal-utils';

import type {
  InventoriesSeriesQuery as GraphQlInventoriesTimeSeries,
  InventoriesSnapshotQuery as GraphQlInventoriesSnapshot,
} from '@kpler/terminal-graphql';
import type {
  InventoriesParams,
  InventoriesSnapshot,
  InventoriesSnapshotDetailCountry,
  InventoriesSnapshotDetailInstallation,
  InventoriesSnapshotDetailTank,
  InventoriesSnapshotDetailTankBase,
  InventoriesSnapshotParams,
  TimeSeriesInventories,
} from 'types/inventories';
import {
  InventoriesDatasetName,
  InventoriesSnapshotSplit,
  InventoriesSplit,
  TankRoofType,
  TankType,
} from 'types/inventories';
import { ResourceType } from 'types/legacy-globals';
import type { TimeSeriesPayload } from 'types/series';

const marketToGraphQlPlatform = (feValue: 'lng' | 'commodities') =>
  feValue === 'lng' ? GraphQlPlatform.Lng : GraphQlPlatform.Commodities;

const frontendGranularityToGraphQlGranularity = (feValue: Granularity) => {
  switch (feValue) {
    case Granularity.DAYS:
      return GraphQlGranularity.Days;
    case Granularity.WEEKS:
      return GraphQlGranularity.Weeks;
    case Granularity.EIAS:
      return GraphQlGranularity.Eias;
    case Granularity.MONTHS:
      return GraphQlGranularity.Months;
    case Granularity.YEARS:
      return GraphQlGranularity.Years;
    case Granularity.MID_WEEKS:
      return GraphQlGranularity.MidWeeks;
    case Granularity.QUARTERS:
      throw new NotImplementedError();
    default:
      return assertNever(feValue);
  }
};

const frontendInventoriesSplitToGraphQlInventoriesSplit = (feValue: InventoriesSplit) => {
  switch (feValue) {
    case InventoriesSplit.COUNTRY:
      return GraphQlInventoriesSplit.Country;
    case InventoriesSplit.INSTALLATION:
      return GraphQlInventoriesSplit.Installation;
    case InventoriesSplit.PLAYER:
      return GraphQlInventoriesSplit.Player;
    case InventoriesSplit.STATUS:
      return GraphQlInventoriesSplit.Status;
    case InventoriesSplit.TANK_TYPE:
      return GraphQlInventoriesSplit.TankType;
    case InventoriesSplit.TOTAL:
      return GraphQlInventoriesSplit.Total;
    case InventoriesSplit.TANK_STATUS:
      return GraphQlInventoriesSplit.TankStatus;
    default:
      return assertNever(feValue);
  }
};

const frontendInventoriesSnapshotSplitToGraphQlInventoriesSnapshotSplit = (
  feValue: InventoriesSnapshotSplit,
) => {
  switch (feValue) {
    case InventoriesSnapshotSplit.COUNTRY:
      return GraphQlInventoriesSnapshotSplit.Country;
    case InventoriesSnapshotSplit.INSTALLATION:
      return GraphQlInventoriesSnapshotSplit.Installation;
    case InventoriesSnapshotSplit.TANK:
      return GraphQlInventoriesSnapshotSplit.Tank;
    default:
      return assertNever(feValue);
  }
};

const frontendResourceTypeToGraphQlResourceType = (feValue: ResourceType): GraphQlResourceType => {
  switch (feValue) {
    case ResourceType.ZONE:
      return GraphQlResourceType.Zone;
    case ResourceType.INSTALLATION:
      return GraphQlResourceType.Installation;
    case ResourceType.PLAYER:
    case ResourceType.PRODUCT:
    case ResourceType.PROVIDER:
    case ResourceType.STATIC_BERTH_CREATE:
    case ResourceType.STATIC_BERTH_EDIT:
    case ResourceType.STATIC_INSTALLATION_CREATE:
    case ResourceType.STATIC_INSTALLATION_EDIT:
    case ResourceType.STATIC_PLAYER_CREATE:
    case ResourceType.STATIC_PLAYER_EDIT:
    case ResourceType.STATIC_PRODUCT_CREATE:
    case ResourceType.STATIC_PRODUCT_EDIT:
    case ResourceType.STATIC_PROVIDER_CREATE:
    case ResourceType.STATIC_PROVIDER_EDIT:
    case ResourceType.STATIC_TANK_CREATE:
    case ResourceType.STATIC_TANK_EDIT:
    case ResourceType.STATIC_ZONE_CREATE:
    case ResourceType.STATIC_ZONE_EDIT:
    case ResourceType.BERTH:
    case ResourceType.UNLOCODE:
    case ResourceType.VESSEL:
    case ResourceType.CAPACITY_HOLDER:
    case ResourceType.CHARTER:
    case ResourceType.CONTRACT:
    case ResourceType.STATIC_VESSEL_EDIT:
      throw new NotImplementedError();
    default:
      return assertNever(feValue);
  }
};

const frontendInventoriesDatasetToGraphQlInventoriesDataset = (feValue: InventoriesDatasetName) => {
  switch (feValue) {
    case InventoriesDatasetName.LEVEL:
      return GraphQLInventoriesDatasetName.Level;
    case InventoriesDatasetName.DELTA_LEVEL:
      return GraphQLInventoriesDatasetName.DeltaLevel;
    case InventoriesDatasetName.CAPACITY:
      return GraphQLInventoriesDatasetName.Capacity;
    case InventoriesDatasetName.CARGO:
    case InventoriesDatasetName.CRUDE_CAPACITY:
    case InventoriesDatasetName.ONSHORE_OUTFLOW:
    case InventoriesDatasetName.EIA_CAPACITY:
    case InventoriesDatasetName.EIA_DELTA_LEVEL:
    case InventoriesDatasetName.EIA_LEVEL:
    case InventoriesDatasetName.HEEL:
    case InventoriesDatasetName.HEEL_CAPACITY:
    case InventoriesDatasetName.IMAGE:
    case InventoriesDatasetName.REVISIT_RATE:
    case InventoriesDatasetName.UTILIZATION:
    case InventoriesDatasetName.PORT_CALLS_NET:
    case InventoriesDatasetName.ONSHORE_INFLOW:
      throw new NotImplementedError();
    default:
      return assertNever(feValue);
  }
};

const getFrontendInventoriesSnapshotSplit = (gqlValue: GraphQlInventoriesSplit) => {
  switch (gqlValue) {
    case GraphQlInventoriesSplit.Country:
      return InventoriesSnapshotSplit.COUNTRY;
    case GraphQlInventoriesSplit.Installation:
      return InventoriesSnapshotSplit.INSTALLATION;
    case GraphQlInventoriesSplit.Tank:
      return InventoriesSnapshotSplit.TANK;
    case GraphQlInventoriesSplit.Player:
    case GraphQlInventoriesSplit.Status:
    case GraphQlInventoriesSplit.TankType:
    case GraphQlInventoriesSplit.Total:
    case GraphQlInventoriesSplit.TankStatus:
      throw new NotImplementedError();
    default:
      return assertNever(gqlValue);
  }
};

export const inventoriesParamsFrontendToGraphql = (source: InventoriesParams) => ({
  cargoTrackingEnhancement: source.cargoTrackingEnhancement,
  endDate: source.endDate,
  fleetMetricsEnhancement: source.fleetMetricsEnhancement,
  floatingStorageDuration: source.floatingStorageDuration,
  granularity: frontendGranularityToGraphQlGranularity(source.granularity),
  locationIds: source.locationIds,
  locationResourceType: frontendResourceTypeToGraphQlResourceType(source.locationResourceType),
  numberOfSplits: source.numberOfSplits,
  splitCriteria: frontendInventoriesSplitToGraphQlInventoriesSplit(source.splitCriteria),
  startDate: source.startDate,
  platform: marketToGraphQlPlatform(source.platform),
  droneData: source.droneData,
  addEiaDatasets: source.addEiaDatasets,
  deltaLevel: source.deltaLevel,
  selection: source.selection
    ? {
        splitValuesMetadata: source.selection.splitValuesMetadata,
        datasetsSplitValues: source.selection.datasetsSplitValues.map(x =>
          frontendInventoriesDatasetToGraphQlInventoriesDataset(x),
        ),
      }
    : undefined,
  droneDataAtmEnhancement: source.droneDataAtmEnhancement,
});

export const inventoriesSnapshotParamsFrontendToGraphql = (source: InventoriesSnapshotParams) => {
  const getStorageFilter = (
    filters: Array<{
      splitType: string;
      splitValue: string;
    }>,
  ) => {
    const filtersBySplitType: { [key: string]: string[] } = {};
    filters.forEach(filter => {
      (filtersBySplitType[filter.splitType] ??= []).push(filter.splitValue);
    });
    return filtersBySplitType;
  };

  return {
    cargoTrackingEnhancement: source.cargoTrackingEnhancement,
    endDate: source.endDate,
    granularity: frontendGranularityToGraphQlGranularity(source.granularity),
    locationIds: source.locationIds,
    locationResourceType: frontendResourceTypeToGraphQlResourceType(source.locationResourceType),
    splitCriteria: frontendInventoriesSnapshotSplitToGraphQlInventoriesSnapshotSplit(
      source.splitCriteria,
    ),
    platform: source.platform
      ? marketToGraphQlPlatform(source.platform)
      : GraphQlPlatform.Commodities,
    filters: getStorageFilter(source.filters),
    notFilters: source.notFilters ? getStorageFilter(source.notFilters) : {},
    droneData: source.droneData,
  };
};

export const timeSeriesPayloadGraphqlToFrontend = (
  sourceQuery: GraphQlInventoriesTimeSeries,
): TimeSeriesPayload<TimeSeriesInventories, InventoriesDatasetName> => {
  const source = sourceQuery.inventoriesSeries;
  return {
    metadata: {
      forecastSince: source.metadata.forecastSince,
      granularity: Granularity[source.metadata.granularity],
    },
    series: source.series.map(serie => ({
      date: serie.date,
      datasets: serie.datasets.map(dataset => ({
        datasetName: dataset.datasetName as InventoriesDatasetName,
        values: dataset.values as TimeSeriesInventories,
        splitValues: dataset.splitValues?.map(splitValue => ({
          name: splitValue.name,
          id: splitValue.splitId,
          values: splitValue.values as TimeSeriesInventories,
        })),
      })),
    })),
  };
};

export const inventoriesSnapshotGraphqlToFrontend = (
  sourceQuery: GraphQlInventoriesSnapshot,
): InventoriesSnapshot => {
  const source = sourceQuery.inventoriesSnapshot;
  const remappedBaseInventoriesSnapshot = {
    granularity: Granularity[source.granularity],
    endDate: source.endDate,
    volume: source.volume,
    deltaVolume: source.deltaVolume ?? undefined,
    revisitRate: source.revisitRate ?? undefined, // @TODO : manage undefined revisitRate in FE
    capacity: source.capacity,
  };

  if (!source.details) {
    return {
      ...remappedBaseInventoriesSnapshot,
      splitBy: getFrontendInventoriesSnapshotSplit(source.splitBy),
    };
  }

  switch (source.__typename) {
    case 'InventoriesSnapshotCountry':
      return {
        ...remappedBaseInventoriesSnapshot,
        splitBy: InventoriesSnapshotSplit.COUNTRY,
        details: source.details.map(item => ({ ...item })) as InventoriesSnapshotDetailCountry[],
      };
    case 'InventoriesSnapshotInstallation':
      return {
        ...remappedBaseInventoriesSnapshot,
        splitBy: InventoriesSnapshotSplit.INSTALLATION,
        details: source.details.map(item => {
          const { penultimateImageDate, ...other } = item;
          return { ...other, lastLastImageDate: penultimateImageDate };
        }) as InventoriesSnapshotDetailInstallation[],
      };
    case 'InventoriesSnapshotTank':
      return {
        ...remappedBaseInventoriesSnapshot,
        splitBy: InventoriesSnapshotSplit.TANK,
        details: source.details.map((detail): InventoriesSnapshotDetailTank => {
          const remappedBaseInventoriesSnapshotDetail: InventoriesSnapshotDetailTankBase = {
            id: detail.id,
            name: detail.name,
            volume: detail.volume ?? undefined,
            deltaVolume: detail.deltaVolume ?? undefined,
            capacity: detail.capacity,
            crudeCapacity: 0, // @TODO crudeCapacity is no longer retrieved from the server
            roofType: TankRoofType[detail.roofType],
          };
          switch (detail.__typename) {
            case 'InventoriesSnapshotDetailTankOil':
              return {
                ...remappedBaseInventoriesSnapshotDetail,
                tankType: TankType.OIL,
                lastImageDate: detail.lastImageDate ?? undefined,
              };
            case 'InventoriesSnapshotDetailTankProducts':
              return {
                ...remappedBaseInventoriesSnapshotDetail,
                tankType: TankType.PRODUCTS,
              };
            default:
              return assertNever(detail);
          }
        }),
      };
    default:
      return assertNever(source);
  }
};
