#import 'src/main/graphql/search/SearchVessel.fragment.gql'

query searchVessels($text: String!, $options: SearchOptionsInput, $size: Int) {
  completionSearch(text: $text, category: [VESSEL], options: $options, size: $size) {
    ...vesselSearch
  }
}

query searchVesselsControlledByPlayer($playerId: ID!) {
  vesselsControlledByPlayer(id: $playerId) {
    vessels {
      ...vesselWithCapacity
    }
    averageAge
  }
}

query searchVesselsOwnedByPlayer($playerId: ID!) {
  vesselsOwnedByPlayer(id: $playerId) {
    vessels {
      ...vesselWithOwnersAncestors
      ...vesselWithCapacity
    }
    averageAge
  }
}
