#import 'src/main/graphql/search/SearchZone.fragment.gql'
#import 'src/main/graphql/search/SearchInstallation.fragment.gql'
#import 'src/main/graphql/search/SearchPlayer.fragment.gql'
#import 'src/main/graphql/search/SearchProduct.fragment.gql'
#import 'src/main/graphql/search/SearchVessel.fragment.gql'

query searchCompletion(
  $text: String!
  $options: SearchOptionsInput
  $category: [SearchCategory!]!
) {
  completionSearch(text: $text, category: $category, options: $options) {
    ...zoneSearch
    ...installationSearch
    ...playerSearch
    ...productSearch
    ...vesselSearch
  }
}
