import store from 'src/store';

import { CargoRouteName } from './enums';

import { forceRefreshGuard } from 'src/services/appUpdate.service';

import type { RouteRecordRaw } from '@kpler/vue2-utils';

const TheCargoWorkspace = () =>
  import(/* webpackChunkName: "cargo-workspace" */ './TheCargoWorkspace.vue');

const Flows = () =>
  store.getters.userHasOnePermission(['flows_navigator', 'flows:read'])
    ? import(/* webpackChunkName: "cargo-workspace" */ './flows/components/Flows.vue')
    : import(/* webpackChunkName: "cargo-workspace" */ './flows/components/FlowsPreview.vue');
const TheFlowsRightPanel = () =>
  import(
    /* webpackChunkName: "cargo-workspace" */ 'src/domains/cargo/flows/components/TheFlowsRightPanel.vue'
  );

const FleetMetrics = () =>
  store.getters.userHasOnePermission(['fleet_metrics', 'fleet_metrics:read'])
    ? import(/* webpackChunkName: "cargo-workspace" */ './fleetMetrics/components/FleetMetrics.vue')
    : import(
        /* webpackChunkName: "cargo-workspace" */ './fleetMetrics/components/FleetMetricsPreview.vue'
      );
const TheFleetMetricsRightPanel = () =>
  import(
    /* webpackChunkName: "cargo-workspace" */ 'src/domains/cargo/fleetMetrics/components/TheFleetMetricsRightPanel.vue'
  );

export const routes: RouteRecordRaw = {
  name: CargoRouteName.CARGO,
  path: 'cargo',
  component: TheCargoWorkspace,
  redirect: '/cargo/flows',
  meta: { title: 'Cargo' },
  children: [
    {
      name: CargoRouteName.CARGO_FLOWS,
      path: 'flows',
      component: Flows,
      children: [
        {
          name: CargoRouteName.CARGO_FLOWS_DETAILS,
          path: 'details/:date',
          component: TheFlowsRightPanel,
          beforeEnter: (to, from, next) => {
            const nextWithRefresh = forceRefreshGuard(to, next);
            if (!store.getters.userHasOnePermission(['flows_navigator', 'flows:read'])) {
              nextWithRefresh({ name: CargoRouteName.CARGO_FLOWS });
              return;
            }
            nextWithRefresh();
          },
        },
      ],
    },
    {
      name: CargoRouteName.CARGO_FLEET_METRICS,
      path: 'fleet-metrics',
      component: FleetMetrics,
      children: [
        {
          name: CargoRouteName.CARGO_FLEET_METRICS_DETAILS,
          path: 'details/:date',
          component: TheFleetMetricsRightPanel,
          beforeEnter: (to, from, next) => {
            const nextWithRefresh = forceRefreshGuard(to, next);
            if (!store.getters.userHasOnePermission(['fleet_metrics', 'fleet_metrics:read'])) {
              nextWithRefresh({ name: CargoRouteName.CARGO_FLEET_METRICS });
              return;
            }
            nextWithRefresh();
          },
        },
      ],
    },
  ],
};
