import type { Position } from 'types/map';
/**
 * As soon as a vessel enter a zone/berth, the positions are flagged as loaded if the vessel is empty
 * (and ballast if the vessel if loaded)
 * But in fact we want the first position inside the zone to remain the same, because otherwise it can
 * suggest that the vessel started to load outside the zone.
 * This method is used to fix the positions loaded status, but it's an issue that should fixed on the back-end side.
 * images: https://github.com/Kpler/web-app/pull/956
 */
export const fixLoadedStatusHelper = (positions: Position[], currentIndex: number): boolean => {
  const currentPosition = positions[currentIndex];

  if (currentIndex === positions.length - 1) {
    return currentPosition.currentCargo.loaded;
  }

  const nextPosition = positions[currentIndex + 1];
  const isNotInBerthYet = currentPosition.speed > 2;
  if (currentPosition.currentCargo.loaded !== nextPosition.currentCargo.loaded && isNotInBerthYet) {
    return !currentPosition.currentCargo.loaded;
  }

  return currentPosition.currentCargo.loaded;
};
