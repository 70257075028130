import { isArray } from '@kpler/generic-utils';
import {
  useRouter,
  areParamsEqual,
  readFromQueryParams,
  formatToQueryParams,
} from '@kpler/terminal-utils';

import MapFiltersService from 'src/main/map/mapFilters.service';
import { apolloClient } from 'src/services/apollo.service';
import PlayerService from 'src/services/player.service';
import SearchService from 'src/services/search.service';
import ZoneService from 'src/services/zone.service';

import {
  getDefaultSearch,
  getDefaultFilters,
  searchMapping,
  filterMapping,
  layerMapping,
  getSerializableSearch,
  getSerializableFilters,
  getDefaultLayers,
  getSerializableLayers,
} from 'src/common/map/map.helper';
import { extractInstallationIds, extractZoneIds } from 'src/main/map/helpers/locationId.helper';
import {
  betaStatusFromRoute,
  cargoStatusFromRoute,
  getMapSearchProperties,
  installationTypesFromRoute,
  isMapSearchLocationEmpty,
  layerStateFromRoute,
  speedFromRoute,
  vesselStatesFromRoute,
  yesNoFromRoute,
  zoneTypesFromRoute,
} from 'src/main/map/helpers/mapSearch.helper';

import { zoneCountries } from 'src/main/components/graphql/ZoneCountries.gql';
import { hydrateSearch } from 'src/main/map/graphql/hydrateSearch.gql';

import type {
  HydrateSearchQuery,
  HydrateSearchQueryVariables,
  LayerState,
  ZoneCountriesQuery,
  ZoneCountriesQueryVariables,
} from '@kpler/terminal-graphql';
import type { URLMapping } from '@kpler/terminal-utils';
import type { RouteLocationNormalized } from '@kpler/vue2-utils';
import type { RootState } from 'src/store/types';
import { MapSearchCategory, ResourceType, VesselTypeClassification } from 'types/legacy-globals';
import type {
  MapMapping,
  MapSearch,
  MapFilters,
  MapFiltersPayload,
  MapSearchMapping,
  MapFiltersMapping,
  MapSelectableItem,
  MapVessel,
  MapProduct,
  MapPlayer,
  MapArea,
  MapSearchUpdatePayload,
  MapLayers,
} from 'types/map';
import { ZoneType } from 'types/zone';
import type { Module, GetterTree, MutationTree, ActionTree } from 'vuex-typescript-interface';

const patchId = <T extends { id: string }>(x: T) => ({ ...x, id: Number(x.id) });

const getSerializableMapProperties = (
  search: MapSearch,
  filters: MapFilters,
  layers: MapLayers,
): MapMapping => ({
  ...getSerializableSearch(search),
  ...getSerializableFilters(filters),
  ...getSerializableLayers(layers),
});

const mapMapping: URLMapping<MapMapping> = {
  ...searchMapping,
  ...filterMapping,
  ...layerMapping,
};

type MapState = {
  search: MapSearch;
  filters: MapFilters;
  cargoFilterItems: MapFiltersPayload | null;
  display: MapLayers;
  hoveredItem: MapSelectableItem | null;
  childZoneIds: number[];
};

const matchLocationsOnIds = (
  data: HydrateSearchQuery,
  zoneIds: string[],
  installationIds: string[],
): MapArea[] => {
  const zonesByIds = Object.fromEntries(data.zonesById.map(x => [x.id, x]));
  const installationsByIds = Object.fromEntries(data.installationsById.map(x => [x.id, x]));
  return [
    ...zoneIds.map(x => ({
      ...patchId(zonesByIds[x]),
      resourceType: ResourceType.ZONE as const,
    })),
    ...installationIds.map(x => ({
      ...patchId(installationsByIds[x]),
      resourceType: ResourceType.INSTALLATION as const,
    })),
  ];
};

export type MapModule = MapState & {
  // getters
  readonly mapSerializableUrlProperties: MapMapping;
  readonly mapSerializableSearch: MapSearchMapping;
  readonly mapSerializableFilters: MapFiltersMapping;
  readonly mapTitleParams: [
    MapSearch['locations'],
    readonly MapVessel[],
    readonly MapProduct[],
    readonly MapPlayer[],
  ];
  readonly hasMapSearch: boolean;
  readonly formatMapSavedSearch: (params: MapSearchMapping) => {
    [key in keyof MapSearchMapping]: string | undefined;
  };
  readonly mapQueryParams: {
    [key in keyof MapMapping]: string | undefined;
  };
  readonly zoneIdsInSearchLocation: number[];
  readonly zoneIdsInSearchLocationWithChildZones: number[];
  readonly hasStaticVesselFilters: boolean;
  readonly hasStaticInstallationFilters: boolean;
  readonly installationIdsInSearchLocation: number[];
  readonly hasStaticPolygonFilters: boolean;
  // mutations
  SET_MAP_SEARCH(search: MapSearch): void;
  SET_FILTERS(filters: MapFilters): void;
  SET_CARGO_FILTER_ITEMS(cargoFilterItems: MapFiltersPayload | null): void;
  SET_VESSELS_DISPLAY(display: LayerState): void;
  SET_INSTALLATIONS_DISPLAY(display: LayerState): void;
  SET_POLYGONS_DISPLAY(display: LayerState): void;
  SET_PIPELINES_DISPLAY(display: LayerState): void;
  SET_HOVERED_ITEM(item: MapSelectableItem | null): void;
  SET_CHILD_ZONE_IDS(childZoneIds: number[]): void;
  // actions
  updateMapUrlParams(): Promise<void>;
  updateMapUrlParamsAndPath(): Promise<void>;
  updateMapSearch(payload: MapSearchUpdatePayload): Promise<void>;
  invertMapSearchLoadsAndDischarges(): Promise<void>;
  updateMapSearchCategories(categories: readonly MapSearchCategory[]): Promise<void>;
  updateMapFilters<T extends keyof MapFilters>(payload: {
    key: T;
    value: MapFilters[T];
  }): Promise<void>;
  updateMapAllFilters(payload: MapFilters): Promise<void>;
  resetMapFilters(): Promise<void>;
  updateMapStateFromRoute(route: RouteLocationNormalized): Promise<void>;
  prepareCargoFilterMutation(): Promise<void>;
  updateMapDisplay(payload: { key: keyof MapLayers; value: LayerState }): Promise<void>;
  setVesselTypeClassificationFromMap(
    value: VesselTypeClassification.OIL | VesselTypeClassification.CPP,
  ): Promise<void>;
  updateChildZoneIds(zoneIds: number[]): Promise<void>;
};

const moduleState: MapState = {
  search: getDefaultSearch(),
  filters: getDefaultFilters(),
  cargoFilterItems: null,
  display: getDefaultLayers(),
  hoveredItem: null,
  childZoneIds: [],
};

const moduleGetters: GetterTree<MapModule, RootState> = {
  mapSerializableUrlProperties: state =>
    getSerializableMapProperties(state.search, state.filters, state.display),
  mapSerializableSearch: state => getSerializableSearch(state.search),
  mapSerializableFilters: state => getSerializableFilters(state.filters),

  mapTitleParams: state => [
    state.search.locations,
    state.search.vessels,
    state.search.products,
    state.search.players,
  ],
  hasMapSearch: state =>
    !(
      isMapSearchLocationEmpty(state.search.locations) &&
      state.search.vessels.length === 0 &&
      state.search.products.length === 0 &&
      state.search.players.length === 0
    ),
  formatMapSavedSearch: () => params =>
    formatToQueryParams(params, getSerializableSearch(getDefaultSearch()), searchMapping),
  mapQueryParams: (state, getters, rootState, rootGetters) =>
    formatToQueryParams(
      getters.mapSerializableUrlProperties,
      getSerializableMapProperties(
        getDefaultSearch(),
        getDefaultFilters(rootGetters.accessibleMarketsForMap),
        getDefaultLayers(),
      ),
      mapMapping,
    ),
  zoneIdsInSearchLocation: state => {
    const { locations } = state.search;
    const locationsFromSearch = isArray(locations)
      ? locations
      : [...locations.loads, ...locations.discharges];
    return locationsFromSearch
      .filter(item => item.resourceType === ResourceType.ZONE)
      .map(item => item.id);
  },
  zoneIdsInSearchLocationWithChildZones: state => {
    const zoneTypesWithChildZones = [ZoneType.SUBCONTINENT, ZoneType.CUSTOM, ZoneType.CONTINENT];
    const { locations } = state.search;
    const locationsFromSearch = isArray(locations)
      ? locations
      : [...locations.loads, ...locations.discharges];
    return locationsFromSearch
      .filter(
        item =>
          item.resourceType === ResourceType.ZONE &&
          zoneTypesWithChildZones.includes(item.type as ZoneType),
      )
      .map(item => item.id);
  },
  installationIdsInSearchLocation: state => {
    const { locations } = state.search;
    const locationsFromSearch = isArray(locations)
      ? locations
      : [...locations.loads, ...locations.discharges];
    return locationsFromSearch
      .filter(item => item.resourceType === ResourceType.INSTALLATION)
      .map(item => item.id);
  },
  hasStaticVesselFilters: state =>
    state.filters.cargoStatus.size +
      state.filters.vesselStates.size +
      state.filters.vesselTypes.size +
      state.filters.vesselTypesOil.size +
      state.filters.vesselTypesCpp.size +
      state.filters.speed.size +
      state.filters.engine.size +
      state.filters.carrierType.size +
      state.filters.betaVesselStatus.size +
      state.filters.asphaltBitumenCapable.size +
      state.filters.cargoTypes.size +
      state.filters.ethyleneCapable.size >
      0 ||
    state.filters.draught !== null ||
    state.filters.buildYear !== null ||
    state.filters.capacity !== null,
  hasStaticInstallationFilters: state =>
    state.filters.installationTypes.size +
      state.filters.installationStatus.size +
      state.filters.betaInstallationStatus.size >
    0,
  hasStaticPolygonFilters: state => state.filters.zoneTypes.size > 0,
};

const moduleMutations: MutationTree<MapModule> = {
  SET_MAP_SEARCH: (state, search) => {
    state.search = search;
  },
  SET_FILTERS: (state, filters) => {
    state.filters = filters;
  },
  SET_CARGO_FILTER_ITEMS: (state, cargoFilterItems) => {
    state.cargoFilterItems = cargoFilterItems;
  },
  SET_VESSELS_DISPLAY: (state, display) => {
    state.display.vesselLayer = display;
  },
  SET_INSTALLATIONS_DISPLAY: (state, display) => {
    state.display.installationLayer = display;
  },
  SET_POLYGONS_DISPLAY: (state, display) => {
    state.display.zoneLayer = display;
  },
  SET_PIPELINES_DISPLAY: (state, display) => {
    state.display.pipelineLayer = display;
  },
  SET_HOVERED_ITEM: (state, item) => {
    state.hoveredItem = item;
  },
  SET_CHILD_ZONE_IDS: (state, childZoneIds) => {
    state.childZoneIds = childZoneIds;
  },
};

const payloadKeyMap = {
  [MapSearchCategory.VESSEL]: 'vessels',
  [MapSearchCategory.PLAYER]: 'players',
  [MapSearchCategory.PRODUCT]: 'products',
};

const moduleActions: ActionTree<MapModule, RootState> = {
  async updateMapUrlParams({ getters }) {
    const router = useRouter();
    router.push({
      query: { ...router.currentRoute.value.query, ...getters.mapQueryParams },
    });
  },
  async updateMapUrlParamsAndPath({ getters, dispatch }) {
    const router = useRouter();
    const pageViewRouteNames = ['vessel', 'installation', 'zone', 'player', 'product'];
    const parentRouteNames = new Set(router.currentRoute.value.matched.map(x => x.name));
    const activePageViewRoute = pageViewRouteNames.find(route => parentRouteNames.has(route));
    const activePageViewId = router.currentRoute.value.params.id;

    const query = {
      ...router.currentRoute.value.query,
      ...getters.mapQueryParams,
      ...(activePageViewRoute
        ? {
            pageView: activePageViewRoute,
            pageViewId: activePageViewId,
          }
        : {}),
    };

    const { pageView: pageViewFromQueryParams, pageViewId: pageViewIdFromQueryParams } =
      router.currentRoute.value.query;

    let name: string | undefined;
    let params: { id: string } | undefined;
    if (activePageViewRoute !== undefined && getters.hasMapSearch) {
      name = 'dynamic';
    } else if (!getters.hasMapSearch && pageViewFromQueryParams) {
      name = pageViewFromQueryParams as string;
      params = { id: pageViewIdFromQueryParams as string };
    }

    router.push({
      name,
      params,
      query,
    });
    dispatch('updateChildZoneIds', getters.zoneIdsInSearchLocationWithChildZones);
  },
  async updateMapSearch({ state, commit, dispatch }, payload) {
    if (payload.category === MapSearchCategory.LOCATION) {
      const locations =
        payload.secondaryValues === undefined
          ? payload.values
          : {
              loads: payload.values,
              discharges: payload.secondaryValues,
            };

      commit('SET_MAP_SEARCH', {
        ...state.search,
        locations,
      });
    } else {
      commit('SET_MAP_SEARCH', {
        ...state.search,
        [payloadKeyMap[payload.category]]: payload.values,
      });
    }
    dispatch('updateMapUrlParamsAndPath');
    dispatch('prepareCargoFilterMutation');
  },
  async invertMapSearchLoadsAndDischarges({ state, commit, dispatch }) {
    if (isArray(state.search.locations)) {
      throw new Error('Cannot invert flat array of locations');
    }

    commit('SET_MAP_SEARCH', {
      ...state.search,
      locations: {
        loads: state.search.locations.discharges,
        discharges: state.search.locations.loads,
      },
    });
    dispatch('updateMapUrlParamsAndPath');
    dispatch('prepareCargoFilterMutation');
  },
  async updateMapSearchCategories({ state, commit, dispatch }, categories) {
    commit('SET_MAP_SEARCH', {
      ...state.search,
      categories,
    });
    dispatch('updateMapUrlParams');
  },
  async updateMapFilters({ state, commit, dispatch }, { key, value }) {
    const newFilters = {
      ...state.filters,
      [key]: value,
    };

    commit('SET_FILTERS', newFilters);
    dispatch('updateMapUrlParams');
  },
  async updateMapAllFilters({ commit }, newFilters) {
    commit('SET_FILTERS', { ...newFilters });
  },
  async resetMapFilters({ commit, rootGetters, rootState }) {
    commit(
      'SET_FILTERS',
      getDefaultFilters(
        rootGetters.accessibleMarketsForMap,
        rootState.settings.map.markets,
        rootState.settings.map.view,
      ),
    );
  },
  async updateMapStateFromRoute({ commit, getters, dispatch, rootGetters, rootState }, route) {
    if (areParamsEqual(mapMapping, getters.mapQueryParams, route)) {
      return;
    }

    const newFilters = readFromQueryParams(
      getSerializableMapProperties(
        getDefaultSearch(),
        getDefaultFilters(
          rootGetters.accessibleMarketsForMap,
          rootState.settings.map.markets,
          rootState.settings.map.view,
        ),
        getDefaultLayers(),
      ),
      mapMapping,
      route.query,
    );

    const vesselTypeFilters: Array<{
      platform?: VesselTypeClassification.OIL | VesselTypeClassification.CPP;
      size: number;
    }> = [
      {
        size: newFilters.vesselTypes.size,
      },
      { platform: VesselTypeClassification.OIL, size: newFilters.vesselTypesOil.size },
      { platform: VesselTypeClassification.CPP, size: newFilters.vesselTypesCpp.size },
    ];
    const presentVesselTypeFilters = vesselTypeFilters.filter(x => x.size !== 0);
    if (presentVesselTypeFilters.length > 1) {
      throw new Error('Filters can only have one kind of vessel type classification at a time.');
    }

    if (presentVesselTypeFilters.length === 1 && presentVesselTypeFilters[0].platform) {
      dispatch('setVesselTypeClassification', presentVesselTypeFilters[0].platform, { root: true });
    }

    const zoneLocationIds = extractZoneIds(newFilters.locations);
    const installationLocationIds = extractInstallationIds(newFilters.locations);
    const zoneLoadIds = extractZoneIds(newFilters.loads);
    const installationLoadIds = extractInstallationIds(newFilters.loads);
    const zoneDischargeIds = extractZoneIds(newFilters.discharges);
    const installationDischargeIds = extractInstallationIds(newFilters.discharges);

    const zoneIds = [...zoneLocationIds, ...zoneLoadIds, ...zoneDischargeIds];
    const installationIds = [
      ...installationLocationIds,
      ...installationLoadIds,
      ...installationDischargeIds,
    ];

    const vesselIds = newFilters.vessels.map(x => x.toString());
    const productIds = newFilters.products.map(x => x.toString());
    const playerIds = newFilters.players.map(x => x.toString());

    const { data } = await apolloClient.query<HydrateSearchQuery, HydrateSearchQueryVariables>({
      query: hydrateSearch,
      variables: {
        zoneIds,
        installationIds,
        vesselIds,
        productIds,
        playerIds,
      },
    });

    let searchLocations;
    if (
      zoneLoadIds.length +
        installationLoadIds.length +
        zoneDischargeIds.length +
        installationDischargeIds.length >
      0
    ) {
      searchLocations = {
        loads: matchLocationsOnIds(data, zoneLoadIds, installationLoadIds),
        discharges: matchLocationsOnIds(data, zoneDischargeIds, installationDischargeIds),
      };
    } else {
      searchLocations = matchLocationsOnIds(data, zoneLocationIds, installationLocationIds);
    }

    const t: MapFilters = {
      cargoStatus: new Set(cargoStatusFromRoute(newFilters.cargoStatus)),
      vesselStates: new Set(vesselStatesFromRoute(newFilters.vesselStates)),
      markets: newFilters.markets,
      view: newFilters.view,
      vesselTypes: newFilters.vesselTypes,
      vesselTypesOil: newFilters.vesselTypesOil,
      vesselTypesCpp: newFilters.vesselTypesCpp,
      carrierType: newFilters.carrierType,
      speed: new Set(speedFromRoute(newFilters.speed)),
      engine: newFilters.engine,
      ethyleneCapable: new Set(yesNoFromRoute(newFilters.ethyleneCapable)),
      asphaltBitumenCapable: new Set(yesNoFromRoute(newFilters.asphaltBitumenCapable)),
      betaVesselStatus: new Set(betaStatusFromRoute(newFilters.betaVesselStatus)),
      capacity: newFilters.capacity,
      vesselClassificationRangeType: newFilters.vesselClassificationRangeType,
      buildYear: newFilters.buildYear,
      draught: newFilters.draught,
      cargoTypes: newFilters.cargoTypes,

      installationTypes: new Set(installationTypesFromRoute(newFilters.installationTypes)),
      installationStatus: newFilters.installationStatus,
      betaInstallationStatus: new Set(betaStatusFromRoute(newFilters.betaInstallationStatus)),
      zoneTypes: new Set(zoneTypesFromRoute(newFilters.zoneTypes)),
    };

    commit('SET_VESSELS_DISPLAY', layerStateFromRoute(newFilters.vesselLayer));
    commit('SET_INSTALLATIONS_DISPLAY', layerStateFromRoute(newFilters.installationLayer));
    commit('SET_POLYGONS_DISPLAY', layerStateFromRoute(newFilters.zoneLayer));
    commit('SET_PIPELINES_DISPLAY', layerStateFromRoute(newFilters.pipelineLayer));

    commit('SET_FILTERS', t);
    commit('SET_MAP_SEARCH', {
      categories: newFilters.fields,
      locations: searchLocations,
      vessels: data.vesselsById.map(patchId),
      products: data.productsById.map(patchId),
      players: data.playersById.map(patchId),
    });

    dispatch('prepareCargoFilterMutation');
    dispatch('updateChildZoneIds', getters.zoneIdsInSearchLocationWithChildZones);
  },
  async prepareCargoFilterMutation({ state, commit }) {
    const { vessels, locations, products, players } = state.search;

    const { emptySearch, oneInstallation, onePlayer, oneVessel, oneZone, severalVesselsInSearch } =
      getMapSearchProperties(state.search);

    // Empty Search or one vessel or one installation,
    // show all installations and vessels.
    if (oneVessel || oneInstallation || emptySearch) {
      commit('SET_CARGO_FILTER_ITEMS', null);
      return;
    }

    if (severalVesselsInSearch) {
      commit('SET_CARGO_FILTER_ITEMS', {
        vesselIds: vessels.map(vessel => vessel.id),
        installationIds: [],
      });
      return;
    }

    // One Zone: Keep all the vessels and only installations that are part of the chosen zone.
    if (oneZone) {
      if (!isArray(locations)) {
        throw new Error(
          'If only one zone is specified, then state.search.locations must be an Array.',
        );
      }

      const installations = await ZoneService.getInstallations(locations[0].id);
      commit('SET_CARGO_FILTER_ITEMS', {
        vesselIds: 'all',
        installationIds: installations.map(installation => installation.id),
      });

      return;
    }

    // One Player: Keep only fleet and installations of the player.
    if (onePlayer) {
      const player = await PlayerService.getPlayer(players[0].id);
      const { getVesselsOwnedByPlayer, getVesselsControlledByPlayer } = SearchService;
      let vesselIds = [];

      const playerId = player.id.toString();
      const [controlledVessels, ownedVessels] = await Promise.all([
        getVesselsControlledByPlayer(playerId),
        getVesselsOwnedByPlayer(playerId),
      ]);
      vesselIds = [
        ...ownedVessels.vessels.map(vessel => Number(vessel.id)),
        ...controlledVessels.vessels.map(vessel => Number(vessel.id)),
      ];

      const installationIds = player.capacityHold.export.capacities
        .concat(player.capacityHold.import.capacities)
        .map(x => x.installation.id);

      commit('SET_CARGO_FILTER_ITEMS', {
        vesselIds,
        installationIds,
      });
      return;
    }

    // For all other cases,
    // Call service listing vessels and installations
    // involved in a current trade activity
    const filters = await MapFiltersService.cargoFilter(
      isArray(locations) ? [] : locations.loads,
      isArray(locations) ? [] : locations.discharges,
      isArray(locations) ? locations : [],
      products,
      players,
      vessels,
    );
    commit('SET_CARGO_FILTER_ITEMS', filters);
  },
  async updateMapDisplay({ commit, dispatch }, { key, value }) {
    if (key === 'vesselLayer') {
      commit('SET_VESSELS_DISPLAY', value);
    } else if (key === 'installationLayer') {
      commit('SET_INSTALLATIONS_DISPLAY', value);
    } else if (key === 'zoneLayer') {
      commit('SET_POLYGONS_DISPLAY', value);
    } else if (key === 'pipelineLayer') {
      commit('SET_PIPELINES_DISPLAY', value);
    } else {
      throw new Error(`Invalid display key: ${key}.`);
    }
    dispatch('updateMapUrlParams');
  },
  setVesselTypeClassificationFromMap({ state, commit, dispatch }, value) {
    const newFilters = {
      ...state.filters,
      vesselTypes: new Set<string>(),
      vesselTypesOil: new Set<string>(),
      vesselTypesCpp: new Set<string>(),
    };

    commit('SET_FILTERS', newFilters);
    dispatch('updateMapUrlParams');
    return dispatch('setVesselTypeClassification', value, { root: true });
  },
  async updateChildZoneIds({ commit }, zoneIds) {
    apolloClient
      .query<ZoneCountriesQuery, ZoneCountriesQueryVariables>({
        query: zoneCountries,
        variables: {
          ids: zoneIds.map(String),
        },
      })
      .then(({ data }) => data.zoneCountries.flatMap(x => Number(x.id)))
      .then(childZoneIds => {
        commit('SET_CHILD_ZONE_IDS', childZoneIds);
      });
  },
};

const mapModule: Module<MapModule, RootState> = {
  getters: moduleGetters,
  state: moduleState,
  mutations: moduleMutations,
  actions: moduleActions,
};

export default mapModule;
