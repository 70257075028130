import { isDefined, capitalizeFirst } from '@kpler/generic-utils';
import {
  PricesGranularity,
  PeriodType,
  ContractMarket,
  ContractType,
  PricesProvider,
} from '@kpler/terminal-graphql';
import {
  Granularity,
  DateTimeDisplay,
  getDateParseFormatFromGranularity,
} from '@kpler/terminal-utils';
import moment from 'moment';

import { CHART_LINK_COLUMN_INDEX } from 'src/main/analytics/prices/prices.constants';
import colors from 'src/scss/colors.constants';

import { getRowAs } from 'src/components/table/table.helper';
import { formatNumber } from 'src/helpers/formatNumber.helper';
import { convertTimeSeries } from 'src/helpers/series.helper';

import type { ObjectBase } from '@kpler/generic-utils';
import type {
  PricesContractFilter,
  ContractsForCurrentPlatformQuery,
  PricesContractFilterParamsInput,
} from '@kpler/terminal-graphql';
import type { DurationInputArg2 } from 'moment';
import type { BaseCheckButtonGroupOption } from 'src/components/BaseCheckboxButtonGroup.types';
import type { ConvertedTimeSeries, TimeSerieConfig, TimeSeriesConfig } from 'types/chart';
import { ChartGrouping, ChartType } from 'types/chart';
import { WidgetView } from 'types/dashboard';
import type { ExportableElement, TableConfig } from 'types/legacy-globals';
import type {
  AnalyticPricesFilterState,
  PeriodDateFormat,
  PricesContractOption,
  PricesDetails,
  PricesFilterState,
  PricesTableData,
  PricesValueObject,
} from 'types/prices';
import type { TimeSeries } from 'types/series';

// @TODO should replace PricesGranularity with Granularity
export const granularityKeyMap: { [key in PricesGranularity]: Granularity } = {
  [PricesGranularity.Days]: Granularity.DAYS,
  [PricesGranularity.Weeks]: Granularity.WEEKS,
  [PricesGranularity.MidWeeks]: Granularity.MID_WEEKS,
  [PricesGranularity.Eias]: Granularity.EIAS,
  [PricesGranularity.Months]: Granularity.MONTHS,
  [PricesGranularity.Quarters]: Granularity.QUARTERS,
  [PricesGranularity.Years]: Granularity.YEARS,
};

export const durationUnitMap: { [key in PricesGranularity]: DurationInputArg2 } = {
  [PricesGranularity.Days]: 'day',
  [PricesGranularity.Weeks]: 'week',
  [PricesGranularity.MidWeeks]: 'week',
  [PricesGranularity.Eias]: 'week',
  [PricesGranularity.Months]: 'month',
  [PricesGranularity.Quarters]: 'quarter',
  [PricesGranularity.Years]: 'year',
};

export const pricesGranularityKeyMap: { [key in Granularity]: PricesGranularity } = {
  [Granularity.DAYS]: PricesGranularity.Days,
  [Granularity.WEEKS]: PricesGranularity.Weeks,
  [Granularity.EIAS]: PricesGranularity.Eias,
  [Granularity.MID_WEEKS]: PricesGranularity.MidWeeks,
  [Granularity.MONTHS]: PricesGranularity.Months,
  [Granularity.QUARTERS]: PricesGranularity.Quarters,
  [Granularity.YEARS]: PricesGranularity.Years,
};

export function formatPrice(price: number | null | undefined) {
  if (!isDefined(price) || price === 0) {
    return '-';
  }

  return price;
}

export function formatUnit(unit: string | null | undefined) {
  if (!isDefined(unit) || unit === '') {
    return '-';
  }

  return unit;
}

export const pricesDetailsToExportableElements = (
  pricesDetails: PricesDetails[],
): ExportableElement[] =>
  pricesDetails.map(x => ({
    Date: x.date,
    Contract: x.contract,
    'Price USD Per Day': formatPrice(x.pricePerDay?.close),
    'Price USD Per Day (High)': formatPrice(x.pricePerDay?.high),
    'Price USD Per Day (Low)': formatPrice(x.pricePerDay?.low),
    'Price USD Per Unit': formatPrice(x.pricePerUnit?.close),
    'Price USD Per Unit (High)': formatPrice(x.pricePerUnit?.high),
    'Price USD Per Unit (Low)': formatPrice(x.pricePerUnit?.low),
    Unit: formatUnit(x.unit),
  }));

export const getPricesValueObject = (
  series: ReadonlyArray<TimeSeries<PricesValueObject>>,
  selectedSerie: string,
  date: string,
): PricesValueObject => {
  let serieValues: PricesValueObject = {
    pricePerDay: {
      close: 0,
    },
    pricePerUnit: {
      close: 0,
    },
  };
  const serie = series.find(period => period.date === date);
  if (serie) {
    const dataset = serie.datasets.find(ds => ds.datasetName === selectedSerie);
    if (dataset) {
      serieValues = dataset.values;
    }
  }
  return serieValues;
};

export const getTableData = (
  chartData: readonly ConvertedTimeSeries[],
): readonly PricesTableData[] =>
  chartData.map(x => {
    let tableRow = {
      date: x.date,
    };
    x.series.forEach(({ key, value }) => {
      tableRow = { ...tableRow, [key]: formatNumber(value, 2) };
    });
    return tableRow;
  });

const getRow = getRowAs<PricesTableData>;

export const getTableColumns = (tableData: readonly PricesTableData[]): readonly TableConfig[] => {
  const tableColumnsAsSet = new Set<string>();
  tableData.forEach(data => {
    Object.keys(data).forEach(key => {
      tableColumnsAsSet.add(key);
    });
  });
  const tableColumns = Array.from(tableColumnsAsSet.values());

  return tableColumns.map(tableColumn => ({
    key: tableColumn,
    label: capitalizeFirst(tableColumn),
    accessor: x => getRow(x)[tableColumn] ?? 'N/A',
  }));
};

export const getFuturesTableData = (
  series: ReadonlyArray<TimeSeries<PricesValueObject>>,
): readonly PricesTableData[] => {
  if (series[0].datasets.length !== 1) {
    throw new Error('There should be exactly one dataset');
  }

  return series.map(x => ({
    date: x.date,
    open: x.datasets[0].values.pricePerUnit.open,
    high: x.datasets[0].values.pricePerUnit.high,
    low: x.datasets[0].values.pricePerUnit.low,
    close: x.datasets[0].values.pricePerUnit.close,
  }));
};

export const getFuturesTableColumns = (
  futuresTableData: readonly PricesTableData[],
): readonly TableConfig[] => {
  const futuresTableColumns = [...getTableColumns(futuresTableData)];
  const chartLinkColumn: TableConfig = {
    key: 'chartLink',
    label: 'Chart',
    type: 'slot',
  };

  futuresTableColumns.splice(CHART_LINK_COLUMN_INDEX, 0, chartLinkColumn);

  return Object.freeze(futuresTableColumns);
};

export const isSparkDataset = (datasetName: string): boolean =>
  datasetName.toLowerCase().includes('spark') ?? false;
export const getSerieColor = (index: number): string => {
  const colorIndex = colors.graphColors.length - 1 - (index % colors.graphColors.length);
  return colors.graphColors[colorIndex];
};

export const getPricesTimeSeriesConfig = (
  datasetNames: readonly string[],
  useSecondaryYAxis = false,
  isWidget = false, // @TODO can be deleted?
): readonly TimeSerieConfig[] => {
  // @TODO find the root cause for undefined at run time
  if (datasetNames === undefined) {
    return [];
  }

  return datasetNames.map((dataset, index) => ({
    useSecondaryYAxis,
    type: isWidget ? WidgetView.CHART_LINE : ChartType.LINE,
    key: dataset,
    color: isSparkDataset(dataset) ? colors.spark[index] : getSerieColor(index),
  }));
};

export const getTimeSeriesConfig = (
  datasetNames: readonly string[],
  hidePointerOnChart: boolean,
): TimeSeriesConfig => {
  const series = getPricesTimeSeriesConfig(datasetNames);
  const grouping = ChartGrouping.BASELINE;
  const average = null;
  return {
    facets: [
      {
        series,
        grouping,
        average,
      },
    ],
    hidePointerOnChart,
  };
};

export const PRICES_MIN_DATE = moment.utc('2017-01-01');

/*
 * Given spark seriesId e.g. Spark25S
 * Return the base contractId i.e. spark25
 */
export const convertSeriesIdToSparkContractId = (seriesId: string) => {
  if (!isSparkDataset(seriesId)) {
    throw new Error('seriesId is not a valid spark dataset');
  }
  return seriesId.toLowerCase().substring(0, 'sparkXX'.length);
};

export const getSource = (seriesId: string) => {
  const contractId = convertSeriesIdToSparkContractId(seriesId);
  return `https://app.sparkcommodities.com/freight/discover/?contractId=${contractId}`;
};

export const getCleanedContractFilters = (
  pricesContractFilters: readonly PricesContractFilterParamsInput[],
  pricesContracts: ContractsForCurrentPlatformQuery['contractsForCurrentPlatform'],
): PricesContractFilter[] =>
  pricesContractFilters.map(pricesContractFilter => {
    let contractInfo = pricesContracts.find(
      contract =>
        contract.name === pricesContractFilter.name &&
        contract.periodType === pricesContractFilter.periodType,
    );

    // @TODO: This is the case for spot contratcs.
    // May be we should pass contractType as query param to clearly identify spot vs futures.
    // Drawback is query string will be longer
    if (!contractInfo) {
      contractInfo = pricesContracts.find(contract => contract.name === pricesContractFilter.name);
    }

    return {
      name: pricesContractFilter.name,
      shortName: contractInfo?.shortName,
      periodType: pricesContractFilter.periodType,
      market: contractInfo?.market || ContractMarket.Freight,
      type: contractInfo?.type || ContractType.Spot,
      provider: contractInfo?.provider,
      dates:
        pricesContractFilter.dates && pricesContractFilter.dates.length > 0
          ? pricesContractFilter.dates
          : undefined,
      continuousPeriods:
        pricesContractFilter.continuousPeriods && pricesContractFilter.continuousPeriods.length > 0
          ? pricesContractFilter.continuousPeriods
          : undefined,
    };
  });

export const PERIOD_DATE_TYPE_FORMAT_MAP: { readonly [key in PeriodType]: PeriodDateFormat } =
  Object.freeze({
    [PeriodType.Week]: { format: DateTimeDisplay.SHORT_WEEKLY, unit: 'w' },
    [PeriodType.Month]: { format: DateTimeDisplay.EXPORT_MONTHLY, unit: 'M' },
    [PeriodType.Quarter]: { format: DateTimeDisplay.EXPORT_QUARTERLY, unit: 'Q' },
    [PeriodType.Year]: { format: DateTimeDisplay.ANNUAL, unit: 'y' },
    [PeriodType.FutureOrientedExpiry]: { format: DateTimeDisplay.EXPORT_MONTHLY, unit: 'M' },
    [PeriodType.TimeSpread]: { format: DateTimeDisplay.EXPORT_MONTHLY, unit: 'M' },
  });

export const getPeriodTypeForDates = (contractPeriodType: PeriodType): PeriodType => {
  if (
    contractPeriodType === PeriodType.FutureOrientedExpiry ||
    contractPeriodType === PeriodType.TimeSpread
  ) {
    return PeriodType.Month;
  }

  return contractPeriodType;
};

export const getDateOptions = (contractOption: PricesContractOption) => {
  const startDate = moment(contractOption.minDate);
  const endDate = moment(contractOption.maxDate);

  let date = startDate;
  const dateOptions = [];
  let dateFormat = '';
  const dateMapping =
    PERIOD_DATE_TYPE_FORMAT_MAP[
      getPeriodTypeForDates(contractOption.periodType || PeriodType.Month)
    ];
  while (date < endDate) {
    dateFormat = date.format(dateMapping.format);
    dateOptions.push({ id: dateFormat, name: dateFormat });
    date = date.add(1, dateMapping.unit);
  }

  return dateOptions.reverse();
};

export const getContinuousPeriodsOptions = (
  contractOption: PricesContractOption,
): Array<ObjectBase<number>> => {
  const dateMapping =
    PERIOD_DATE_TYPE_FORMAT_MAP[
      getPeriodTypeForDates(contractOption.periodType || PeriodType.Month)
    ];
  const periodUnit = dateMapping.unit.toUpperCase();

  return contractOption.continuousPeriods.map(period => ({
    id: period,
    name: `${periodUnit}+${period}`,
  }));
};

export const serializeContractObjects = (
  pricesContractFilters: readonly PricesContractFilterParamsInput[],
): string => {
  const queryStrings = pricesContractFilters.map(pricesContractFilter => {
    const { name, dates, continuousPeriods, periodType } = pricesContractFilter;

    const contractString = `name--${name}`;
    const periodTypeString = periodType ? `~periodType--${periodType}` : '';
    const datesString = dates && dates.length > 0 ? `~dates--${dates.join(',')}` : '';
    const continuousperiodsString =
      continuousPeriods && continuousPeriods.length > 0
        ? `~continuousPeriods--${continuousPeriods.join(',')}`
        : '';

    return `${contractString}${periodTypeString}${datesString}${continuousperiodsString}`;
  });

  return queryStrings.join('__');
};

export const deserializeContractObjects = (queryString: string): PricesContractFilter[] => {
  if (!queryString) {
    return [];
  }

  const pricesContractFiltersString = queryString.split('__');

  const pricesContractFilters: PricesContractFilter[] = pricesContractFiltersString.map(
    pricesContractFilterString => {
      const stringArray = pricesContractFilterString.split('~');

      const contractStringArray = stringArray.find(x => x.includes('name'))?.split('--');
      const periodTypeStringArray = stringArray.find(x => x.includes('periodType'))?.split('--');
      const datesStringArray = stringArray
        .find(x => x.includes('dates'))
        ?.split('--')
        .map((x, i) => {
          if (i === 0) {
            return x;
          }
          return x.split(',');
        });
      const continuousperiodsNumberArray = stringArray
        .find(x => x.includes('continuousPeriods'))
        ?.split('--')
        .map((x, i) => {
          if (i === 0) {
            return x;
          }
          return x.split(',').map(val => Number(val));
        });

      const keyValuesArray = [
        contractStringArray,
        periodTypeStringArray,
        datesStringArray,
        continuousperiodsNumberArray,
      ].filter((x): x is NonNullable<typeof x> => x !== undefined) as string[][];

      return Object.fromEntries(keyValuesArray);
    },
  );

  return pricesContractFilters;
};

export const convertPricesSeries = (
  series: ReadonlyArray<TimeSeries<PricesValueObject>>,
): readonly ConvertedTimeSeries[] =>
  convertTimeSeries(
    series,
    qty => (qty.pricePerUnit.close ? Math.round(qty.pricePerUnit.close * 100) / 100 : 0),
    values => ({ unit: values.unit, type: 'prices' }),
  );

export const timeSeriesDataWithPrices = (
  seriesData: ReadonlyArray<ConvertedTimeSeries<string>>,
  pricesData: ReadonlyArray<ConvertedTimeSeries<string>>,
): ReadonlyArray<ConvertedTimeSeries<string>> =>
  seriesData.map(item => {
    const pricesRecord = pricesData.find(pricesItem => pricesItem.date === item.date);
    if (pricesRecord) {
      const series = [...item.series, ...pricesRecord.series];
      return { ...item, series };
    }

    return item;
  });

export const getCleanedContract = (
  contract: PricesContractFilterParamsInput,
): PricesContractFilterParamsInput => {
  const { dates, continuousPeriods, name, periodType } = contract;

  return {
    name,
    ...(periodType && { periodType }),
    ...(dates && { dates }),
    ...(continuousPeriods && { continuousPeriods }),
  };
};
export const getCleanedContracts = (
  contracts: readonly PricesContractFilterParamsInput[],
): PricesContractFilterParamsInput[] => {
  if (!contracts) {
    return [];
  }
  return contracts.map(getCleanedContract);
};

export const isWeekend = (date: moment.Moment): boolean => {
  const weekDay = date.isoWeekday();
  return weekDay === 6 || weekDay === 7;
};

export const addMissingGranularityRecords = (
  pricesGranularity: PricesGranularity,
  series: ReadonlyArray<ConvertedTimeSeries<string>>,
): ReadonlyArray<ConvertedTimeSeries<string>> => {
  const granularity = granularityKeyMap[pricesGranularity];
  const granularityFormat = getDateParseFormatFromGranularity(granularity);
  const durationUnit = durationUnitMap[pricesGranularity];

  const filledSeries = [];
  for (let i = 0; i < series.length - 1; i++) {
    let date = moment(series[i].date).add(1, durationUnit);
    filledSeries.push(series[i]);

    if (date.format(granularityFormat) !== series[i + 1].date) {
      const nextDate = moment(series[i + 1].date);
      while (date < nextDate) {
        if (!(pricesGranularity === PricesGranularity.Days && isWeekend(date))) {
          filledSeries.push({ date: date.format(granularityFormat), series: [] });
        }

        date = moment(date).add(1, durationUnit);
      }
    }
  }

  filledSeries.push(series[series.length - 1]);
  return filledSeries;
};

export const getPricesFilterState = (
  analyticFilters: AnalyticPricesFilterState,
): PricesFilterState => ({
  contracts: analyticFilters.pricesFilters.contracts,
  dateRange: analyticFilters.dateRange,
  granularity: pricesGranularityKeyMap[analyticFilters.granularity],
  seasonal: false,
});

export const PROVIDER_NAME_MAP: { readonly [key in PricesProvider]: string } = Object.freeze({
  [PricesProvider.Baltic]: 'Baltic',
  [PricesProvider.Cme]: 'CME',
  [PricesProvider.Parameta]: 'Parameta',
  [PricesProvider.Spark]: 'Spark',
});

export const getProvidersForCurrentPlatform = (): BaseCheckButtonGroupOption[] => {
  const pricesProviders = [PricesProvider.Baltic, PricesProvider.Spark];

  return pricesProviders.map(provider => ({ id: provider, name: PROVIDER_NAME_MAP[provider] }));
};
