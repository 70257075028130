import { FeatureFlagService } from '@kpler/feature-flag';

const createService = (): FeatureFlagService | undefined => {
  const clientKey = process.env.NX_PUBLIC_GROWTHBOOK_CLIENT_KEY;
  if (!clientKey) {
    console.warn(new Error('Missing GrowthBook client key'));
    console.warn('Feature flag service is disabled');
    return;
  }

  return FeatureFlagService.createService(clientKey);
};

export const featureFlagService = createService();
