<script setup lang="ts">
import { ButtonSmall } from '@kpler/web-ui';
import { v4 as uuid } from 'uuid';
import { ref } from 'vue';

import ButtonAction from 'src/components/ButtonAction.vue';
import TheTermsOfUse from 'src/main/settings/legal-pages/TheTermsOfUse.vue';

const inputId = uuid();
const userHasReadAndAgree = ref(false);

const emit = defineEmits<{
  (e: 'accept'): void;
  (e: 'cancel'): void;
}>();
</script>

<template>
  <div class="wrapper" theme="dark">
    <div class="block-center" theme="light">
      <TheTermsOfUse class="content" />

      <hr />

      <div class="actions">
        <div class="d-flex justify center gap-xs">
          <input :id="inputId" v-model="userHasReadAndAgree" type="checkbox" />
          <label :for="inputId">I have read and agree to the above Kpler Terms of Use</label>
        </div>

        <div class="button-footer mt">
          <ButtonSmall class="button button--cancel" @click="emit('cancel')">Cancel</ButtonSmall>

          <ButtonAction
            v-tooltip="{
              text: 'You must agree to the terms of use to continue',
              disabled: userHasReadAndAgree,
            }"
            :disabled="!userHasReadAndAgree"
            class="button"
            @click="emit('accept')"
            >Continue</ButtonAction
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'src/scss/variables';
@use 'src/scss/theme';

// Matches padding on Auth0 box
$box-padding: 40px;

.wrapper {
  display: grid;
  place-items: center;
  height: 100vh;
  background-color: theme.$color-bg-tertiary;
  color: theme.$color-text-primary;

  .block-center {
    max-width: 100%;
    max-height: 75vh;
    min-height: 400px;
    background-color: theme.$color-bg-primary;
    color: theme.$color-text-primary;
    border-radius: variables.$border-radius;
    display: grid;
    grid-template-rows: 1fr min-content;
  }

  .content {
    overflow-y: scroll;
    padding: $box-padding $box-padding 0 $box-padding;
  }

  .actions {
    padding: 0 $box-padding $box-padding $box-padding;
    margin-top: variables.$spacing;

    input[type='checkbox'] {
      margin: 3px 3px 3px 4px;
    }
  }

  label {
    font-size: variables.$font-size-almost-large;
  }

  .button {
    height: 40px;
    padding-left: variables.$spacing;
    padding-right: variables.$spacing;
    text-transform: none;
    font-size: variables.$font-size-almost-large;
    font-weight: variables.$font-regular;

    &--cancel {
      color: theme.$color-text-secondary;

      &:hover {
        background-color: theme.$color-bg-secondary;
      }
    }
  }
}
</style>
