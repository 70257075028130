import { AppRouter, useRouter } from '@kpler/terminal-utils';
import Table from 'buefy/dist/components/table';
import PortalVue from 'portal-vue';
import Vue from 'vue';
import Fragment from 'vue-fragment';

import { App } from 'src/app/App';
import Tooltip from 'src/directives/Tooltip.directive';
import truncate from 'src/directives/truncate.directive';
import setupAxiosInterceptors from 'src/services/setupAxiosInterceptors';
import store from 'src/store';

import { lazyInit } from './app/lazyInit';
import { authConfigService } from './app/providers/singletons/authConfigService';
import { authService } from './app/providers/singletons/authService';
import { AuthService } from './core/auth';
import AuthClient from './core/auth/AuthClient';
import { routes } from './routes';
import { axiosApi } from './services/axios.service';

import TitleService from 'src/helpers/title.service';
import TrackingService from 'src/services/tracking.service';

import { subscribeToRouteChanges } from 'src/helpers/router.helper';

import type { PluginObject } from 'vue';

Vue.use(Table);
Vue.use(Fragment.Plugin);

lazyInit();

setupAxiosInterceptors();
const auth0SpaClient = new AuthClient(authConfigService.moduleConfig);
const currentAuthService = new AuthService(auth0SpaClient, () => store, axiosApi);
authService.current = currentAuthService;

Vue.config.productionTip = false;

new Vue({
  router: AppRouter.getRouter('/', routes),
  render: h => h(App),
}).$mount('#app');

subscribeToRouteChanges({
  route$: useRouter().route$,
  subscriptions: [{ triggerSideEffect: newRoute => TitleService.updateFromRoute(newRoute) }],
  delayedSubscriptions: [{ triggerSideEffect: () => TrackingService.trackEvent() }],
});

const commonProperties: PluginObject<void> = {
  install(vueInstance) {
    /* eslint-disable no-param-reassign */
    vueInstance.prototype.$hasPermission = (permission: string): boolean =>
      store.getters.userHasPermission(permission);
    vueInstance.prototype.$hasOnePermission = (permissions: string[]): boolean =>
      store.getters.userHasOnePermission(permissions);
    vueInstance.prototype.$hasAccessToFreightFeature = (): boolean =>
      store.getters.userHasAccessToFreightFeature;
    vueInstance.prototype.$hasOnlyAccessToFreightFeature = (): boolean =>
      store.getters.userHasOnlyAccessToFreightFeature;
    vueInstance.prototype.$hasAccessToFreightTrial = (): boolean =>
      store.getters.userHasAccessToFreightTrial;
    vueInstance.prototype.$hasAnAccessToMarketData = (): boolean =>
      store.getters.userHasAnAccessToMarketData;
    vueInstance.prototype.$trackDatesFilterUsage = (value: string | Record<string, string>) => {
      TrackingService.trackFilterUsage('dates', typeof value === 'string' ? 'preset' : 'custom');
    };
    vueInstance.prototype.$trackFilterUsage = (
      filter: string,
      value: unknown | Array<{ id: string | number }>,
    ) => {
      const isArrayOfObjects = Array.isArray(value) && value.every(x => x && x.id);
      TrackingService.trackFilterUsage(filter, isArrayOfObjects ? value.map(x => x.id) : value);
    };
    vueInstance.prototype.$trackEvent = (
      eventString?: string,
      customOptions?: Record<string, unknown>,
    ) => {
      TrackingService.trackEvent(eventString, customOptions);
    };
    /* eslint-enable no-param-reassign */
  },
};
Vue.use(commonProperties);

Vue.use(PortalVue);
Vue.directive('truncate', truncate);
Vue.directive('tooltip', Tooltip);
