import { Market } from '@kpler/web-ui';

import {
  CRUDE_OIL_INVENTORIES_PERMISSIONS,
  LNG_INVENTORIES_PERMISSIONS,
} from 'src/domains/onshore-asset-monitoring/constants/permissions';
import { APP_COMMO } from 'src/domains/userManagement/constants';

import type { RootState } from 'src/store/types';
import { CppMarket, CrudeMarket, LngMarket, OilMarket } from 'types/legacy-globals';
import type { CommercialProduct, Service, User } from 'types/user';
import type { GetterTree, Module, MutationTree } from 'vuex-typescript-interface';

type UserState = {
  user: User | null;
  permissions: Set<string>;
};

export type UserModule = UserState & {
  // mutations
  SET_USER(user: User): void;
  RESET_USER(): void;

  // getters
  readonly userId: string | undefined;
  readonly userHasPermission: (permission: string) => boolean;
  readonly userHasOnePermission: (permissions: readonly string[]) => boolean;
  readonly userHasAllPermissions: (permissions: readonly string[]) => boolean;
  readonly accessibleMarketsForMap: Set<Market>;
  readonly accessibleMarketsForDashboard: Set<Market>;
  readonly accessibleMarketsForFreight: Set<Market>;
  readonly accessibleMarketsForInventory: Set<Market>;
  readonly accessibleMarketsForEmissions: Set<Market>;
  readonly accessibleMarketsForFlows: Set<Market>;
  readonly accessibleMarketsForRefineries: Set<Market>;
  readonly userHasRootProduct: boolean;
  readonly userHasOilProducts: boolean;
  readonly userHasCppProducts: boolean;
  readonly userHasCrudeProducts: boolean;
  readonly userIsEligibleForLngInventoriesFeature: boolean;
  readonly userIsEligibleForOilInventoriesFeature: boolean;
  readonly userHasAccessToLngInventories: boolean;
  readonly userHasAccessToOilInventories: boolean;
  readonly allProducts: CommercialProduct[];
  readonly availableProducts: CommercialProduct[];
  readonly accessibleOilSupplyDemandProducts: number[];
  readonly userHasAccessToFreightFeature: boolean;
  readonly userHasOnlyAccessToFreightFeature: boolean;
  readonly userHasAccessToFreightTrial: boolean;
  readonly userHasAccessToTonnageSupplyFeature: boolean;
  readonly userHasAccessToBallastCapacityFeature: boolean;
  readonly userHasAccessToCongestionFeature: boolean;
  readonly userHasAccessToFleetDevelopmentFeature: boolean;
  readonly userHasAccessToFleetUtilizationFeature: boolean;
  readonly userHasAccessToFreightMetricsFeature: boolean;
  readonly userHasAccessToOpenVessels: boolean;
  readonly userHasAnAccessToMarketData: boolean;
  readonly userHasAccessToOilSupplyDemand: boolean;
  readonly userHasAccessToGrainsSupplyDemand: boolean;
  readonly userHasAccessToRefineries: boolean;
  readonly userHasAccessToRefineriesFull: boolean;
  readonly userIsInternalUser: boolean;
};

const moduleState: UserState = {
  permissions: new Set(),
  user: null,
};

const rootProductIds = ['1482', '1746', '1414'];

const moduleMutations: MutationTree<UserModule> = {
  SET_USER: (state, user) => {
    state.user = user;
    state.permissions = new Set(user.permissions);
  },
  RESET_USER: state => {
    state.user = null;
  },
};

const hasAccessToCommodityMarket = (
  state: UserState,
  markets: { [key: string]: string | number },
): boolean => {
  if (!state.user) {
    return false;
  }

  const marketIds: Set<string> = new Set(
    Object.values(markets).map(marketId => marketId.toString()),
  );

  return state.user.services
    .flatMap(service => service.products)
    .some(product => marketIds.has(product.id));
};

export const getMarketsFromService = (services: Service[], service: string): Set<Market> => {
  const mapAppCommoIdToMarket = new Map<APP_COMMO, Market>([
    [APP_COMMO.LNG, Market.LNG],
    [APP_COMMO.LPG, Market.LPG],
    [APP_COMMO.DRY, Market.DRY],
    [APP_COMMO.LIQUIDS, Market.LIQUIDS],
  ]);
  const markets = services
    .filter(s => s.name === service)
    .flatMap(s =>
      s.commodityTypes.map(({ id }: { id: APP_COMMO }) => {
        const market = mapAppCommoIdToMarket.get(id);
        if (market === undefined) {
          throw new Error(`Unable to map commodity type id to market. commodity type id : ${id}`);
        }
        return market;
      }),
    );

  return new Set(markets);
};

const moduleGetters: GetterTree<UserModule, RootState> = {
  userId: state => state.user?.id,
  userHasPermission: state => permission => state.permissions.has(permission),
  userHasOnePermission: state => arr => arr.some((p): boolean => state.permissions.has(p)),
  userHasAllPermissions: state => arr => arr.every((p): boolean => state.permissions.has(p)),
  accessibleMarketsForMap: state => getMarketsFromService(state.user?.services ?? [], 'map'),
  accessibleMarketsForDashboard: state =>
    getMarketsFromService(state.user?.services ?? [], 'dashboard'),
  accessibleMarketsForFreight: state =>
    getMarketsFromService(state.user?.services ?? [], 'dashboard'),
  accessibleMarketsForInventory: state =>
    getMarketsFromService(state.user?.services ?? [], 'inventory'),
  accessibleMarketsForEmissions: state =>
    getMarketsFromService(state.user?.services ?? [], 'emissions'),
  accessibleMarketsForFlows: state => getMarketsFromService(state.user?.services ?? [], 'flows'),
  accessibleMarketsForRefineries: state =>
    getMarketsFromService(state.user?.services ?? [], 'refineries'),
  userHasOilProducts: state => hasAccessToCommodityMarket(state, OilMarket),
  userHasCppProducts: state => hasAccessToCommodityMarket(state, CppMarket),
  userHasCrudeProducts: state => hasAccessToCommodityMarket(state, CrudeMarket),
  userHasRootProduct: state => {
    if (!state.user) {
      return false;
    }
    return rootProductIds.some(productId =>
      state.user?.services
        .flatMap(service => service.products.map(product => product.id))
        .includes(productId),
    );
  },
  userIsEligibleForOilInventoriesFeature: state => {
    if (!state.user) {
      return false;
    }

    const marketIds = state.user.services.flatMap(service =>
      service.products.map(product => product.id),
    );
    return (
      marketIds.includes(OilMarket.CRUDE_CO.toString()) ||
      marketIds.includes(OilMarket.LIQUIDS.toString())
    );
  },
  userIsEligibleForLngInventoriesFeature: state => {
    if (!state.user) {
      return false;
    }

    const marketIds = state.user.services.flatMap(service =>
      service.products.map(product => product.id),
    );

    return marketIds.includes(LngMarket.LNG.toString());
  },
  userHasAccessToLngInventories: (state, getters) => {
    if (!state.user) {
      return false;
    }
    return (
      getters.userHasOnePermission(LNG_INVENTORIES_PERMISSIONS) &&
      getters.userIsEligibleForLngInventoriesFeature
    );
  },
  userHasAccessToOilInventories: (state, getters) => {
    if (!state.user) {
      return false;
    }
    return (
      getters.userHasOnePermission(CRUDE_OIL_INVENTORIES_PERMISSIONS) &&
      getters.userIsEligibleForOilInventoriesFeature
    );
  },
  userHasAnAccessToMarketData: (state, getters) => {
    if (!state.user) {
      return false;
    }
    return getters.userHasOnePermission(
      [
        'ballast_capacity:read',
        'commodity_prices:read',
        'congestion:read',
        'dashboard:read',
        'display_map',
        'fixture_data:read',
        'fleet_development:read',
        'fleet_metrics',
        'fleet_metrics:read',
        'fleet_utilization:read',
        'flows:read',
        'flows_navigator',
        'freight_metrics:read',
        'freight_prices:read',
      ].concat(CRUDE_OIL_INVENTORIES_PERMISSIONS),
    );
  },
  allProducts: state => state.user?.services.flatMap(({ products }) => products) ?? [],
  availableProducts: (state, getters) =>
    getters.allProducts.filter(x => rootProductIds.includes(x.id)),
  userHasAccessToFreightFeature: state =>
    state.user?.services.some(service => service.name === 'freight') ?? false,
  userHasOnlyAccessToFreightFeature: (_, getters) =>
    getters.userHasAccessToFreightFeature && getters.allProducts.length === 0,
  userHasAccessToFreightTrial: (state, getters) =>
    !getters.userHasPermission('full_access:read') &&
    !!state.user?.services.some(
      service =>
        service.name === 'freight' && service.commodityTypes.some(({ isTrial }) => isTrial),
    ),
  userHasAccessToTonnageSupplyFeature: (_, getters) =>
    getters.userHasAccessToFreightFeature && getters.userHasPermission('tonnage_supply:read'),
  userHasAccessToBallastCapacityFeature: (_, getters) =>
    getters.userHasAccessToFreightFeature && getters.userHasPermission('ballast_capacity:read'),
  userHasAccessToCongestionFeature: (_, getters) =>
    getters.userHasAccessToFreightFeature && getters.userHasPermission('congestion:read'),
  userHasAccessToFleetDevelopmentFeature: (_, getters) =>
    getters.userHasAccessToFreightFeature && getters.userHasPermission('fleet_development:read'),
  userHasAccessToFleetUtilizationFeature: (_, getters) =>
    getters.userHasAccessToFreightFeature && getters.userHasPermission('fleet_utilization:read'),
  userHasAccessToFreightMetricsFeature: (_, getters) =>
    getters.userHasAccessToFreightFeature && getters.userHasPermission('freight_metrics:read'),
  userHasAccessToOpenVessels: (_, getters) =>
    getters.userHasAccessToFreightFeature && getters.userHasPermission('open_vessels:read'),
  accessibleOilSupplyDemandProducts: state =>
    state.user?.permissions
      .filter(permission => permission.startsWith('supply_demand:basic:ui_access'))
      .map(permission => permission.split(':').at(-1) as string)
      .map(Number) ?? [],
  userHasAccessToOilSupplyDemand: (state, getters) =>
    getters.accessibleOilSupplyDemandProducts.length > 0 &&
    (state.user?.services.some(service => service.name === 'supply_and_demand') ?? false),
  userHasAccessToGrainsSupplyDemand: (_, getters) =>
    getters.userHasPermission('supply_demand:grains'),
  userHasAccessToRefineries: state =>
    state.user?.services.some(service => service.name === 'refineries') ?? false,
  userHasAccessToRefineriesFull: (_, getters) =>
    getters.userHasAccessToRefineries && getters.userHasPermission('refineries:full_access:read'),
  userIsInternalUser: state => state.user?.email.endsWith('kpler.com') ?? false,
};

const userModule: Module<UserModule, RootState> = {
  state: moduleState,
  mutations: moduleMutations,
  getters: moduleGetters,
};

export default userModule;
