import type { RouteRecordRaw } from '@kpler/vue2-utils';

const AddTonnageList = () =>
  import(
    /* webpackChunkName: "tonnage-list-workspace" */ 'src/domains/chartering/TheTonnageListWorkspace/CreateTonnageList/CreateTonnageList.vue'
  );

const EditTonnageList = () =>
  import(
    /* webpackChunkName: "tonnage-list-workspace" */ 'src/domains/chartering/TheTonnageListWorkspace/EditTonnageList/EditTonnageList.vue'
  );

const TonnageListTable = () =>
  import(
    /* webpackChunkName: "tonnage-list-workspace" */ 'src/domains/chartering/TheTonnageListWorkspace/DisplayTonnageList/components/TonnageListTable/TonnageListTable.vue'
  );

const TonnageListMap = () =>
  import(
    /* webpackChunkName: "tonnage-list-workspace" */ 'src/domains/chartering/TheTonnageListWorkspace/DisplayTonnageList/components/TonnageListMap/TonnageListMap.vue'
  );

const DisplayTonnageList = () =>
  import(
    /* webpackChunkName: "tonnage-list-workspace" */ 'src/domains/chartering/TheTonnageListWorkspace/DisplayTonnageList/DisplayTonnageList.vue'
  );

const TonnageListWrapper = () =>
  import(
    /* webpackChunkName: "tonnage-list-workspace" */ 'src/domains/chartering/TheTonnageListWorkspace/TonnageListWrapper/TonnageListWrapper.vue'
  );

const OpenPositions = () =>
  import(
    /* webpackChunkName: "tonnage-list-workspace" */ 'src/domains/chartering/TheTonnageListWorkspace/OpenPositions/OpenPositions.vue'
  );

const routes: RouteRecordRaw[] = [
  {
    name: 'open-positions',
    path: '/workflows/open-positions',
    component: OpenPositions,
    meta: { title: 'Open Positions', hideNavMenu: true },
  },
  {
    name: 'tonnage-list',
    path: '/workflows/tonnage-list',
    meta: { title: 'Tonnage List' },
    component: TonnageListWrapper,
    children: [
      {
        name: 'add-tonnage-list',
        path: 'add',
        component: AddTonnageList,
        meta: { title: 'Add New Tonnage List' },
      },
      {
        name: 'view-tonnage-list',
        path: ':id',
        props: true,
        redirect: { name: 'table-tonnage-list' },
        component: DisplayTonnageList,
        children: [
          {
            name: 'table-tonnage-list',
            path: 'table',
            component: TonnageListTable,
            meta: { title: 'Table View Tonnage List' },
          },
          {
            name: 'map-tonnage-list',
            path: 'map/:vesselId?',
            component: TonnageListMap,
            meta: { title: 'Map View Tonnage List' },
            props: true,
          },
        ],
      },
      {
        name: 'edit-tonnage-list',
        path: ':id/edit',
        component: EditTonnageList,
        meta: { title: 'Edit Tonnage List' },
      },
    ],
  },
];

export default routes;
