import { capitalizeFirst } from '@kpler/generic-utils';

import type { SectionContext } from 'src/domains/insight/common/components/navigationMenu/types';

const contextToLabel: Record<SectionContext, string> = {
  'market-pulse': 'market pulse',
  news: 'news',
  webinars: 'webinars',
  reports: 'reports',
};

export const getPageTitle = (context: SectionContext, commodityTitle: string): string =>
  `Insight ${capitalizeFirst(commodityTitle)} ${contextToLabel[context]}`;
