import { debounceTime } from 'rxjs';

import { forceRefreshGuard } from 'src/services/appUpdate.service';

import type {
  Dictionary,
  NavigationGuard,
  Next,
  NextRoute,
  RouteLocationNormalized,
} from '@kpler/vue2-utils';
import type { ReplaySubject } from 'rxjs';
import type { Route } from 'vue-router'; // eslint-disable-line no-restricted-imports

interface SubscriptionSideEffect {
  triggerSideEffect: (newRoute: Route) => void;
}

type RouteChangeParams = {
  route$: ReplaySubject<Route>;
  delayedSubscriptions: SubscriptionSideEffect[];
  subscriptions: SubscriptionSideEffect[];
};

export const subscribeToRouteChanges = ({
  route$,
  subscriptions,
  delayedSubscriptions,
}: RouteChangeParams) => {
  route$.subscribe(to => {
    subscriptions.forEach(subscription => subscription.triggerSideEffect(to));
  });

  route$.pipe(debounceTime(1000)).subscribe(to => {
    delayedSubscriptions.forEach(delayedSubscription => delayedSubscription.triggerSideEffect(to));
  });
};

export const normalizeRouteForNext = (route: RouteLocationNormalized): NextRoute => {
  const name = typeof route.name === 'string' ? route.name : undefined;
  const params = route.params as Dictionary<string>;
  return {
    ...route,
    name,
    params,
  };
};

const checkHasProperties = (o: Record<string, unknown>): boolean => Object.keys(o).length > 0;

const checkHasQueryParams = (route: RouteLocationNormalized): boolean =>
  checkHasProperties(route.query);

const checkMatchRootPath = (route: RouteLocationNormalized, rootPath: string): boolean =>
  route.path.startsWith(rootPath);

export const createKeepQueryParamsHook = (rootPath: string): NavigationGuard => {
  const keepQueryParamsHook: NavigationGuard = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: Next,
  ) => {
    const shouldCopyQueryParamsToNewRoute =
      checkHasQueryParams(from) &&
      !checkHasQueryParams(to) &&
      checkMatchRootPath(from, rootPath) &&
      checkMatchRootPath(to, rootPath);

    if (!shouldCopyQueryParamsToNewRoute) {
      next();
      return;
    }

    const newRoute: NextRoute = normalizeRouteForNext({
      ...to,
      query: from.query,
    });

    next(newRoute);
  };

  return keepQueryParamsHook;
};

export const createAuthorizationHook = (
  checkHasAuthorization: () => boolean,
  fallbackRouteName: string,
): NavigationGuard => {
  const authorizationHook: NavigationGuard = (
    to: RouteLocationNormalized,
    _from: RouteLocationNormalized,
    next: Next,
  ) => {
    const nextWithRefresh = forceRefreshGuard(to, next);
    if (!checkHasAuthorization()) {
      return nextWithRefresh({ name: fallbackRouteName });
    }
    return nextWithRefresh();
  };

  return authorizationHook;
};

export const createSetDefaultsParamsHook =
  (defaultParams: Dictionary<string>): NavigationGuard =>
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: Next): void => {
    const hasDefaultParams = checkHasProperties(defaultParams);
    const shouldSetDefaultParams =
      hasDefaultParams && !checkHasQueryParams(from) && !checkHasQueryParams(to);
    if (!shouldSetDefaultParams) {
      next();
      return;
    }

    const newRoute: NextRoute = normalizeRouteForNext({
      ...to,
      query: {
        ...defaultParams,
      },
    });

    next(newRoute);
  };

const evaluateGuards = (
  navigationguards: NavigationGuard[],
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: Next,
) => {
  const [currentGuard, ...nextGuards] = [...navigationguards];

  if (currentGuard === undefined) {
    next();
    return;
  }

  currentGuard(to, from, nextArg => {
    if (nextArg === undefined) {
      evaluateGuards(nextGuards, to, from, next);
      return;
    }

    next(nextArg);
  });
};

export const multiGuards =
  (navigationguards: NavigationGuard[]) =>
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: Next): void =>
    evaluateGuards(navigationguards, to, from, next);
