import {
  userHasAccessToRefineriesFactory,
  userHasAccessToInternalRefineriesMarginsFactory,
} from 'src/domains/refineries/hooks/usePermissions';
import store from 'src/store';

import type { RouteRecordRaw } from '@kpler/vue2-utils';

const TheRefineries = () =>
  import(/* webpackChunkName: "refineries" */ 'src/domains/refineries/TheRefineries.vue');

const TheRefineriesOverview = () =>
  userHasAccessToRefineriesFactory(store)()
    ? import(
        /* webpackChunkName: "refineries" */ 'src/domains/refineries/Tabs/Overview/TheRefineriesOverview.vue'
      )
    : import(
        /* webpackChunkName: "refineries" */ 'src/domains/refineries/Tabs/Overview/TheRefineriesOverviewPreview.vue'
      );

const TheRefineriesRuns = () =>
  userHasAccessToRefineriesFactory(store)()
    ? import(
        /* webpackChunkName: "refineries" */ 'src/domains/refineries/Tabs/Runs/TheRefineriesRuns.vue'
      )
    : import(
        /* webpackChunkName: "refineries" */ 'src/domains/refineries/Tabs/Runs/TheRefineriesRunsPreview.vue'
      );
const TheRefineriesFeedstock = () =>
  userHasAccessToRefineriesFactory(store)()
    ? import(
        /* webpackChunkName: "refineries" */ 'src/domains/refineries/Tabs/Feedstock/TheRefineriesFeedstock.vue'
      )
    : import(
        /* webpackChunkName: "refineries" */ 'src/domains/refineries/Tabs/Runs/TheRefineriesRunsPreview.vue'
      );
const TheRefineriesRefinedProducts = () =>
  userHasAccessToRefineriesFactory(store)()
    ? import(
        /* webpackChunkName: "refineries" */ 'src/domains/refineries/Tabs/RefinedProducts/TheRefineriesRefinedProduct.vue'
      )
    : import(
        /* webpackChunkName: "refineries" */ 'src/domains/refineries/Tabs/RefinedProducts/TheRefineriesRefinedProductPreview.vue'
      );

const TheRefineriesSecondaryUnitFeedInput = () =>
  userHasAccessToRefineriesFactory(store)()
    ? import(
        /* webpackChunkName: "refineries" */ 'src/domains/refineries/Tabs/SecondaryUnitFeedInput/TheRefineriesSecondaryUnitFeedInput.vue'
      )
    : import(
        /* webpackChunkName: "refineries" */ 'src/domains/refineries/Tabs/SecondaryUnitFeedInput/TheRefineriesSecondaryUnitFeedInputPreview.vue'
      );

const TheRefineriesMargins = () =>
  userHasAccessToInternalRefineriesMarginsFactory(store)()
    ? import(
        /* webpackChunkName: "refineries" */ 'src/domains/refineries/Tabs/Margins/TheRefineriesMargins.vue'
      )
    : import(
        /* webpackChunkName: "refineries" */ 'src/domains/refineries/Tabs/Margins/TheRefineriesMarginsPreview.vue'
      );

const TheRefineryCapacities = () =>
  store.getters.userHasOnePermission(['refineries_internal', 'refineries:read'])
    ? import(
        /* webpackChunkName: "refineries" */ 'src/domains/refineries/Tabs/Capacities/TheRefineryCapacities.vue'
      )
    : import(
        /* webpackChunkName: "refineries" */ 'src/domains/refineries/Tabs/Capacities/TheRefineriesCapacitiesPreview.vue'
      );

const TheRefineryCapacitiesRightPanel = () =>
  import(
    /* webpackChunkName: "refineries" */ 'src/domains/refineries/Tabs/Capacities/TheRefineryCapacitiesRightPanel.vue'
  );

const TheRefineriesEventsData = () =>
  store.getters.userHasOnePermission(['refineries_internal', 'refineries:read'])
    ? import(
        /* webpackChunkName: "refineries" */ 'src/domains/refineries/Tabs/Events/TheRefineriesEventsData.vue'
      )
    : import(
        /* webpackChunkName: "refineries" */ 'src/domains/refineries/Tabs/Events/TheRefineriesEventsPreview.vue'
      );

const refineriesRoutes: RouteRecordRaw = {
  name: 'refineries',
  path: 'refineries',
  component: TheRefineries,
  redirect: 'refineries/overview',
  meta: { title: 'Refineries' },
  children: [
    {
      path: 'overview',
      name: 'refineries-overview',
      component: TheRefineriesOverview,
    },
    {
      path: 'runs',
      name: 'refineries-runs',
      component: TheRefineriesRuns,
    },
    {
      path: 'feedstock',
      name: 'refineries-feedstock',
      component: TheRefineriesFeedstock,
    },
    {
      path: 'refined-products',
      name: 'refineries-refined-products',
      component: TheRefineriesRefinedProducts,
    },
    {
      path: 'secondary-unit-feed-input',
      name: 'refineries-secondary-unit-feed-input',
      component: TheRefineriesSecondaryUnitFeedInput,
    },
    {
      path: 'margins',
      name: 'refineries-margins',
      component: TheRefineriesMargins,
    },
    {
      name: 'refineries-workspace-capacities',
      path: 'capacities',
      component: TheRefineryCapacities,
      children: [
        {
          name: 'refineries-workspace-capacities-details',
          path: ':facetId/details/:date',
          component: TheRefineryCapacitiesRightPanel,
        },
      ],
    },
    {
      name: 'refineries-workspace-events',
      path: 'events',
      component: TheRefineriesEventsData,
    },
  ],
};

export default refineriesRoutes;
