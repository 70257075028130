import Vue from 'vue';
import Vuex from 'vuex-typescript-interface';

// eslint-disable-next-line import/no-cycle
import auth from 'src/store/modules/auth';
import map from 'src/store/modules/map';
// eslint-disable-next-line import/no-cycle
import mapItems from 'src/store/modules/mapItems';
import settings from 'src/store/modules/settings';
import user from 'src/store/modules/user';

import type { RootState } from './types';

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth,
    map,
    mapItems,
    settings,
    user,
  },
});
