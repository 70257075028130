import { UnitName, UnmanagedUnitName, TemporalUnitName } from 'types/unit';

const isNotNumeric = (stringOrNumber: string | number): boolean => {
  const n = Number(stringOrNumber);
  return Number.isNaN(n) || !Number.isFinite(n);
};

const UNITS_TO_DECIMALS: {
  readonly [key in UnitName | TemporalUnitName | UnmanagedUnitName]: number;
} = Object.freeze({
  [UnitName.KG]: 0,
  [UnitName.QUINTAL]: 0,
  [UnitName.TON]: 0,
  [UnitName.TONS]: 0,
  [UnitName.KTONS]: 0,
  [UnitName.MTONS]: 2,
  [UnitName.CM]: 0,
  [UnitName.CF]: 0,
  [UnitName.MCF]: 0,
  [UnitName.NCM]: 0,
  [UnitName.SCF]: 0,
  [UnitName.SMCF]: 0,
  [UnitName.BARREL]: 0,
  [UnitName.KB]: 1,
  [UnitName.MMBBL]: 2,
  [UnitName.TON_MILE]: 0,
  [UnitName.KTON_MILE]: 0,
  [UnitName.MTON_MILE]: 0,
  [UnitName.TON_DAY]: 0,
  [UnitName.KTON_DAY]: 0,
  [UnitName.MTON_DAY]: 2,
  [UnitName.KNOT]: 1,
  [UnitName.NAUTICAL_MILE]: 0,
  [TemporalUnitName.BD]: 0,
  [TemporalUnitName.KBD]: 0,
  [TemporalUnitName.KBPA]: 0,
  [TemporalUnitName.MTPA]: 2,
  [UnmanagedUnitName.PERCENTAGE]: 0,
  [UnmanagedUnitName.PERCENTAGE_DAY]: 0,
  [UnmanagedUnitName.DAYS]: 2,
  [UnmanagedUnitName.ITEMS]: 0,
  [UnitName.GWH]: 0,
  [UnitName.METER]: 0,
  [UnitName.KG_CM]: 0,
  [UnmanagedUnitName.DEGREE_API]: 0,
  [UnmanagedUnitName.DOLLAR_DAY]: 0,
  [UnmanagedUnitName.DOLLAR_TON]: 0,
  [UnmanagedUnitName.DOLLAR_MTON]: 0,
  [UnmanagedUnitName.DOLLAR_MMBTU]: 0,
  [UnmanagedUnitName.MMBTU]: 0,
  [UnmanagedUnitName.PERCENTAGE_PER_DAY]: 0,
  [UnmanagedUnitName.TON_DAY]: 0,
  [UnmanagedUnitName.TON_HA]: 2,
  [UnmanagedUnitName.TON_M3]: 0,
  [UnmanagedUnitName.M3]: 0,
  [UnmanagedUnitName.TON_BBL]: 0,
  [UnmanagedUnitName.BBL]: 0,
  [UnmanagedUnitName.MW]: 0,
  [UnmanagedUnitName.KHA]: 0,
});

/**
 * Format thousands with a comma or a space if spaceSeparator=true
 */
export const formatThousands = (n: number | string, spaceSeparator = false): string => {
  if (isNotNumeric(n)) {
    return n as string;
  }

  const separator = spaceSeparator ? ' ' : ',';
  const isNegative = Number(n) < 0;

  const [integerPart, decimalPart] = n.toString().split('.');

  const reversedCharsWithSeparator: string[] = integerPart
    .split('')
    .filter(c => !['+', '-'].includes(c))
    .reverse()
    .reduce(
      (str: string, char: string, i: number): string =>
        (i + 1) % 3 ? `${str}${char}` : `${str}${char}${separator}`,
      '',
    )
    .split('');

  // remove trailing separator if present
  const lastChar = reversedCharsWithSeparator[reversedCharsWithSeparator.length - 1];
  if (lastChar === separator) {
    reversedCharsWithSeparator.pop();
  }

  const intergerPartWithSeparators: string = reversedCharsWithSeparator.reverse().join('');
  const sign = isNegative ? '-' : '';
  return [sign + intergerPartWithSeparators, decimalPart].filter(x => !!x).join('.');
};

/**
 * Transform a number to a string with a decimal precision
 * By default, decimalPrecision is computed following the input number value and the following rules:
 * - if n = 0, decimalPrecision = 0
 * - if 0 < n < 10, decimalPrecision = 2
 * - if 10 <= n < 100, decimalPrecision = 1
 * - if n >= 100, decimalPrecision = 0
 */
export const formatDecimalPlaces = (n: number | string, decimalPrecision?: number): string => {
  if (isNotNumeric(n)) {
    return String(n);
  }

  const asNumber = Number(n);

  if (asNumber === 0) {
    return '0';
  }

  let fractionDigits: number;

  if (decimalPrecision === undefined) {
    const abs = Math.abs(asNumber);
    if (abs < 10) {
      fractionDigits = 2;
    } else if (abs < 100) {
      fractionDigits = 1;
    } else {
      fractionDigits = 0;
    }
  } else {
    fractionDigits = decimalPrecision;
  }

  return asNumber.toFixed(fractionDigits);
};

/**
 * If decimalPrecision is omitted, it takes the same default value as in formatDecimalPlaces()
 */
export const formatNumber = (
  n: number | string | null | undefined,
  decimalPrecision?: number,
): string => {
  if (n === null || n === undefined || n === '') {
    return '';
  }
  return formatThousands(formatDecimalPlaces(n, decimalPrecision));
};

export const formatNumberFromUnit = (
  value: number,
  unitName?: UnitName | TemporalUnitName | UnmanagedUnitName,
): string => (unitName ? formatNumber(value, UNITS_TO_DECIMALS[unitName]) : formatNumber(value));

export const getLowerRangeBound = (value: number, bucketSize: number): number =>
  Math.floor(value / bucketSize) * bucketSize;

export const getUpperRangeBound = (value: number, bucketSize: number): number =>
  Math.ceil(value / bucketSize) * bucketSize;

export const formatNumberRangeFromUnit = (
  value: number,
  smallestRangeBoundValue: number,
  rangeBucketSize: number,
  unitName?: UnitName | TemporalUnitName | UnmanagedUnitName,
): string => {
  const smallestValueLowerRangeBound = getLowerRangeBound(smallestRangeBoundValue, rangeBucketSize);
  const firstRangeBound =
    smallestValueLowerRangeBound > 0
      ? smallestValueLowerRangeBound
      : getUpperRangeBound(smallestRangeBoundValue, rangeBucketSize);
  const lowerRangeBound = getLowerRangeBound(value, rangeBucketSize);

  if (lowerRangeBound === 0 || lowerRangeBound < firstRangeBound) {
    if (firstRangeBound < rangeBucketSize) {
      return `<${formatNumberFromUnit(rangeBucketSize, unitName)}`;
    }
    return `<${formatNumberFromUnit(firstRangeBound, unitName)}`;
  }

  const lowerRange = formatNumberFromUnit(lowerRangeBound, unitName);
  const upperRange = formatNumberFromUnit(getUpperRangeBound(value, rangeBucketSize), unitName);

  return `${lowerRange}-${upperRange}`;
};

export const formatNumberFromUnitWithSign = (
  value: number,
  unitName?: UnitName | TemporalUnitName | UnmanagedUnitName,
): string => {
  const sign = value > 0 ? '+' : '';
  return `${sign}${formatNumberFromUnit(value, unitName)}`;
};

export const formatNumberWithSign = (value: number, dp?: number): string => {
  const sign = value > 0 ? '+' : '';
  return `${sign}${formatNumber(value, dp)}`;
};

export const formatNumberForCSV = (value: number | undefined, dp?: number): string | number =>
  value === undefined ? '' : parseFloat(value.toFixed(dp ?? 2));

export const formatFloat = (num: number, dp: number): number => parseFloat(num.toFixed(dp));

export const formatPercent = (value: number): string => `${formatNumber(value)}%`;

const FALLBACK_VALUE = 'N/A';
export const formatValueOrFallback = (
  formatFn: null | ((x: number, dp?: number) => string),
  value: number | undefined,
  dp?: number,
  fallback = FALLBACK_VALUE,
) => {
  if (value === undefined) {
    return fallback;
  }
  return formatFn ? formatFn(value, dp) : value;
};

export const formatNumberWithMilleniumPrefixes = (
  n: number | string | null | undefined,
  decimalPrecision?: number,
  formatFn: (x: number, dp?: number) => string = formatNumber,
): string => {
  if (n === null || n === undefined || n === '' || isNotNumeric(n)) {
    return '';
  }

  const asNumber = Number(n);
  const milleniumPrefixes = [
    { threshold: 1e9, symbol: 'G' },
    { threshold: 1e6, symbol: 'M' },
    { threshold: 1e3, symbol: 'k' },
  ];

  const absoluteNumber = Math.abs(asNumber);
  const milleniumPrefix = milleniumPrefixes.find(prefix => absoluteNumber >= prefix.threshold);

  if (milleniumPrefix) {
    const roundedNumber = Math.round(asNumber / milleniumPrefix.threshold);
    return `${formatFn(roundedNumber, 0)}${milleniumPrefix.symbol}`;
  }
  return formatFn(asNumber, decimalPrecision);
};

export const formatNumberWithSignAndMilleniumPrefixes = (
  n: number | string | null | undefined,
  decimalPrecision?: number,
): string => formatNumberWithMilleniumPrefixes(n, decimalPrecision, formatNumberWithSign);
