import { LocationType } from '@kpler/terminal-graphql';

import { apolloClient } from 'src/services/apollo.service';

import { mapFilters } from 'src/main/map/graphql/mapFilters.gql';

import type { MapFiltersQuery, MapFiltersQueryVariables } from '@kpler/terminal-graphql';
import type { Location } from 'types/legacy-globals';
import { ResourceType } from 'types/legacy-globals';
import type { MapFiltersPayload } from 'types/map';

const typeMap: { [key in Location['resourceType']]: LocationType } = {
  [ResourceType.INSTALLATION]: LocationType.Installation,
  [ResourceType.ZONE]: LocationType.Zone,
};

const locationToInput = ({ id, resourceType }: Location) => ({
  id: id.toString(),
  type: typeMap[resourceType],
});

const cargoFilter = async (
  loads: readonly Location[],
  discharges: readonly Location[],
  locations: readonly Location[],
  products: ReadonlyArray<{ id: string }>,
  players: ReadonlyArray<{ id: number }>,
  vessels: ReadonlyArray<{ id: number }>,
): Promise<MapFiltersPayload> => {
  const res = await apolloClient.query<MapFiltersQuery, MapFiltersQueryVariables>({
    query: mapFilters,
    variables: {
      where: {
        locations: locations.map(locationToInput),
        fromLocations: loads.map(locationToInput),
        toLocations: discharges.map(locationToInput),
        vesselIds: vessels.map(x => x.id.toString()),
        productIds: products.map(x => x.id.toString()),
        playerIds: players.map(x => x.id.toString()),
      },
    },
    fetchPolicy: 'no-cache',
  });

  const { vesselIds, installationIds } = res.data.tradeActivity;

  return {
    vesselIds: vesselIds.map(x => Number(x)),
    installationIds: installationIds.map(x => Number(x)),
  };
};

export default {
  cargoFilter,
};
