export const navigationMenuEn = {
  insight: 'Insight',
  commodityTitles: {
    oil: 'Oil',
    gasLNG: 'Gas & LNG',
    dry: 'Dry',
    crossCommodities: 'Cross Commodities',
    riskAndCompliance: 'Risk and Compliance',
    power: 'Power',
  },
  weeklyReports: {
    crudeOil: 'Crude Oil',
    tankers: 'Tankers Weekly',
    refinedProducts: 'Refined Products',
    refineryMargins: 'Refinery Margins',
    naturalGasLNG: 'Natural Gas and LNG Weekly',
    dryBulks: 'Dry Bulks Weekly',
    crossCommodity: 'Cross Commodity Weekly',
    macro: 'Macro Weekly',
  },
  monthlyReports: {
    bottomBarrel: 'Bottom of the Barrel Monthly',
    middleBarrel: 'Middle of the Barrel Monthly',
    topBarrel: 'Top of the Barrel Monthly',
    refinedProductsView: 'The Refined Products View',
    tankers: 'Tankers Monthly',
    crudeFlows: 'Crude Aggregated Flows and Inventories',
    crudeView: 'The Crude View',
    crudeOilBarrel: 'Crude Oil Barrel',
    lngSpotlight: 'LNG Spotlight',
    lng: 'LNG Monthly',
    europeanGas: 'European Gas',
    thermalMetCoal: 'Thermal & Met Coal Monthly',
    commodityProspects: 'Commodity Prospects Monthly',
    commodityGeopolitics: 'Commodity Geopolitics',
    biofuels: 'Biofuels Monthly',
    ironOre: 'Iron Ore Monthly',
    grains: 'Grains Monthly',
    riskAndCompliance: 'Risk & Compliance Monthly',
    opec: 'OPEC+ in Focus',
  },
  navigationMenu: {
    weeklyReports: 'WEEKLY REPORTS',
    monthlyReports: 'MONTHLY REPORTS',
  },
  commonNavigation: {
    news: 'News',
    marketPulse: 'Market Pulse',
    webinars: 'Webinars',
  },
};
