fragment vessel on Vessel {
  id
  name
  status
  imo
  hasLastPosition
  currentCommodityType
}

fragment vesselSearch on VesselSearch {
  score
  highlight {
    field
    value
  }
  vessel {
    ...vessel
  }
}

fragment vesselWithCapacity on Vessel {
  ...vessel
  capacity {
    energy
    mass
    volume
    volume_gas: volumeGas
  }
}

fragment vesselWithOwnersAncestors on Vessel {
  ...vesselWithCapacity
  ownersAncestors {
    id
    share
  }
}
