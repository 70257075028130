import type { SectionContext } from 'src/domains/insight/common/components/navigationMenu/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
const InsightShortContentPage = () =>
  import(
    /* webpackChunkName: "insight" */ 'src/domains/insight/shortContentPage/InsightShortContentPage.vue'
  );

// eslint-disable-next-line @typescript-eslint/naming-convention
const InsightReportsPage = () =>
  import(/* webpackChunkName: "insight" */ 'src/domains/insight/reportPage/InsightReportsPage.vue');
// eslint-disable-next-line @typescript-eslint/naming-convention
const InsightWebinarsPage = () =>
  import(
    /* webpackChunkName: "insight" */ 'src/domains/insight/webinarPage/InsightWebinarsPage.vue'
  );

export const getComponentFromContext = (context: SectionContext) => {
  switch (context) {
    case 'market-pulse':
    case 'news':
      return InsightShortContentPage;
    case 'webinars':
      return InsightWebinarsPage;
    case 'reports':
      return InsightReportsPage;
    default:
      throw new Error(`Unknown context: ${context}`);
  }
};
