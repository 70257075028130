import { FeatureFlagService } from '@kpler/feature-flag';
import { useRouter } from '@kpler/terminal-utils';

import { IntercomService } from 'src/services/IntercomService/IntercomService';
import { UnitService } from 'src/services/UnitService/UnitService';
import store from 'src/store';

import { appConfigService } from './singletons/appConfigService';
import { getAuthService } from './singletons/authService';
import { conversionService } from './singletons/conversionService';
import { featureFlagService } from './singletons/featureFlagService';
import { intercomService } from './singletons/intercomService';
import { unitService } from './singletons/unitService';

import type { ApplicationProviders } from './types';

const hasPermission = (permission: string) => store.state.user.permissions.has(permission);

export const applicationProviders: ApplicationProviders = {
  hasPermission,
  conversionService,
  store,
  [UnitService.INJECT_KEY]: unitService,
  [IntercomService.INJECT_KEY]: intercomService,
  [FeatureFlagService.INJECT_KEY]: featureFlagService,
  appConfigService,
  get authService() {
    return getAuthService();
  },
  get router() {
    return useRouter();
  },
};
