import { axiosComplianceApi } from '@kpler/compliance-common';
import { ForbiddenError } from '@kpler/generic-utils';

import { getAuthService } from 'src/app/providers/singletons/authService';
import { setupAxiosArbitrageApiInterceptors } from 'src/domains/arbitrage/api/service';

import {
  axiosApi,
  axiosIdp,
  axiosPdfReport,
  axiosRestApi,
  axiosStaticV2,
  createAuthInterceptor,
  createCleanupRetryCountOnResponse,
  createHandleRetryOnUnauthenticatedError,
  unauthenticatedAxiosApi,
} from './axios.service';
import ToastService from './toast.service';

import { axiosCharteringApi } from 'src/domains/chartering/TheTonnageListWorkspace/axios.service';
import { setupAxiosInsightInterceptors } from 'src/domains/insight/insight.axios.service';
import { setupAxiosNotificationsApiInterceptors } from 'src/domains/notifications/axios.service';
import { setupAxiosApiInterceptors as setupAxiosSupplyDemandGrainsApiInterceptors } from 'src/domains/supplyDemand/grains/connectors/axios.service';
import { setupAxiosApiInterceptors as setupAxiosSupplyDemandOilApiInterceptors } from 'src/domains/supplyDemand/oil/connectors/axios.service';

import { AnalystEditError } from 'src/helpers/error.helper';

import type { AxiosErrorWithMessage } from './axios.service';

const toastErrorMaintenance = () => {
  ToastService.toastError(
    'Functionality currently under maintenance, please retry in a few minutes.',
  );
};

const handleStaticAPIError = async (err: AxiosErrorWithMessage) => {
  const statusCode = err.response?.status;

  if ([500, 502].includes(statusCode)) {
    const message = err.response.config.baseURL?.includes('staticdata') ? 'static' : 'tank';
    const errorMessage = `Sorry, an error occurred while connecting to ${message} API.`;
    ToastService.toastError(errorMessage);
    throw new Error(errorMessage);
  } else if ([400, 409].includes(statusCode)) {
    const { message } = err.response.data;
    if (Array.isArray(message)) {
      message.forEach(m => {
        ToastService.toastError(`Bad request: ${m.message}`);
      });
    } else {
      ToastService.toastError(`Bad request: ${message}`);
    }
    throw err.response;
  } else if (statusCode === 403) {
    const requestInfo = err.response.config;
    const message = err.response.data?.message;

    if (message) {
      const errorMessagePrefix = 'Sorry, you cannot perform this action because:';
      if (Array.isArray(message)) {
        message.forEach(m => {
          ToastService.toastError(`${errorMessagePrefix} ${m.message}`);
        });
      } else {
        ToastService.toastError(`${errorMessagePrefix} ${message}`);
      }
    } else {
      ToastService.toastError(
        `Sorry, you don't have the necessary permissions to ${requestInfo.method} ${requestInfo.url}.`,
      );
    }
  } else if (statusCode === 404) {
    const requestInfo = err.response.config;
    ToastService.toastError(
      `Sorry, the requested url ${requestInfo.baseURL}/${requestInfo.url} was not found.`,
    );
  }
};

const setupAxiosApiInterceptors = () => {
  axiosApi.interceptors.request.use(createAuthInterceptor());
  axiosApi.interceptors.response.use(
    createCleanupRetryCountOnResponse(),
    createHandleRetryOnUnauthenticatedError(axiosApi),
  );
  axiosApi.interceptors.response.use(
    res => res.data,
    async (err: AxiosErrorWithMessage) => {
      const url = err.config?.url;

      if (err.response?.status === 403) {
        throw new ForbiddenError(err.response?.data.message ?? `Error on ${url}.` ?? 'No message.');
      }

      if (url?.split('/').includes('flask') ?? false) {
        if (err.response?.status === 502) {
          toastErrorMaintenance();
        } else {
          throw new AnalystEditError(err);
        }
      } else {
        throw new Error(err.response?.data.message ?? `Error on ${url}.` ?? 'No message.');
      }
    },
  );
};

const setupUnauthenticatedAxiosInterceptors = () => {
  unauthenticatedAxiosApi.interceptors.response.use(
    res => res.data,
    async (err: AxiosErrorWithMessage) => {
      const url = err.config?.url;
      if (err.response?.status === 401 && window.location.pathname !== '/oauth/callback') {
        await getAuthService().logout(window.location.pathname);
      }

      if (err.response?.status === 403) {
        throw new ForbiddenError(err.response?.data.message ?? `Error on ${url}.` ?? 'No message.');
      }

      if (url?.split('/').includes('flask') ?? false) {
        if (err.response?.status === 502) {
          toastErrorMaintenance();
        } else {
          throw new AnalystEditError(err);
        }
      } else {
        throw new Error(err.response?.data.message ?? `Error on ${url}.` ?? 'No message.');
      }
    },
  );
};

const setupAxiosRestApiInterceptors = () => {
  axiosRestApi.interceptors.request.use(createAuthInterceptor());
  axiosRestApi.interceptors.response.use(
    createCleanupRetryCountOnResponse(),
    createHandleRetryOnUnauthenticatedError(axiosRestApi),
  );
  axiosRestApi.interceptors.response.use(
    res => res.data,
    async (err: AxiosErrorWithMessage) => {
      const url = err.config?.url;
      if (err.response?.status === 400) {
        ToastService.toastError('An error occured.');
        throw new Error(err.response?.data.reason ?? `Error on ${url}.`);
      } else if (err.response?.status === 403) {
        throw new ForbiddenError(err.response?.data.reason ?? `Error on ${url}.` ?? 'No message.');
      } else {
        throw new Error(err.response?.data.reason ?? `Error on ${url}.`);
      }
    },
  );
};
const setupAxiosStaticV2Interceptors = () => {
  axiosStaticV2.interceptors.request.use(createAuthInterceptor());
  axiosStaticV2.interceptors.response.use(
    createCleanupRetryCountOnResponse(),
    createHandleRetryOnUnauthenticatedError(axiosStaticV2),
  );
  axiosStaticV2.interceptors.response.use(
    res => res,
    (err: AxiosErrorWithMessage) => handleStaticAPIError(err),
  );
};

const setupAxiosComplianceApiInterceptors = () => {
  axiosComplianceApi.interceptors.request.use(createAuthInterceptor());
  axiosComplianceApi.interceptors.response.use(res => res.data);
};

const setupAxiosCharteringApiInterceptors = () => {
  axiosCharteringApi.interceptors.request.use(createAuthInterceptor());
  axiosCharteringApi.interceptors.response.use(res => res.data);
};

const setupAxiosIdpInterceptors = () => {
  axiosIdp.interceptors.request.use(createAuthInterceptor());
};

const setupAxiosPdfReportInterceptors = () => {
  axiosPdfReport.interceptors.request.use(
    createAuthInterceptor({ includeUseAccessTokenHeader: false }),
  );
  axiosPdfReport.interceptors.response.use(res => res.data);
};

const setupAxiosInterceptors = () => {
  setupAxiosApiInterceptors();
  setupUnauthenticatedAxiosInterceptors();
  setupAxiosRestApiInterceptors();
  setupAxiosStaticV2Interceptors();
  setupAxiosComplianceApiInterceptors();
  setupAxiosInsightInterceptors();
  setupAxiosNotificationsApiInterceptors();
  setupAxiosSupplyDemandOilApiInterceptors();
  setupAxiosSupplyDemandGrainsApiInterceptors();
  setupAxiosIdpInterceptors();
  setupAxiosCharteringApiInterceptors();
  setupAxiosPdfReportInterceptors();
  setupAxiosArbitrageApiInterceptors();
};

export default setupAxiosInterceptors;
