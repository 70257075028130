#import 'src/main/graphql/search/SearchInstallation.fragment.gql'

query searchInstallations($text: String!, $options: SearchOptionsInput) {
  completionSearch(text: $text, category: [INSTALLATION], options: $options) {
    ...installationSearch
  }
}

query searchInstallationsByCapacityHolder($capacityHolderId: ID!) {
  playerHoldingCapacities(id: $capacityHolderId) {
    export {
      ...installationWithCapacities
    }
    import {
      ...installationWithCapacities
    }
  }
}
