import { INSIGHT_NAVIGATION_SECTIONS } from 'src/domains/insight/common/components/navigationMenu/navigationMenu.constants';

import {
  buildPropsWithReportType,
  buildPropsWithTags,
  buildPropsWithTagsAndContentOwnership,
} from './buildProps';
import { checkUserAccess } from './checkUserAccess';
import { getComponentFromContext } from './getComponentFromContext';
import { getPageTitle } from './getPageTitle';
import {
  commodityTitleToNewsPermissions,
  commodityTitleToResearchUpdatesPermissions,
  commodityTitleToWebinarPermissions,
  getPermissionsFromCommodity,
  monthlyReportToPermission,
  weeklyReportToPermission,
} from './getPermissionsFromCommodity';
import { getRouteName } from './getRouteName';
import { getRoutePath } from './getRoutePath';

import type {
  CommoditySlug,
  PropsPageWithReportType,
  PropsPageWithTags,
  PropsPageWithTagsAndContentOwnership,
} from 'src/domains/insight/common/components/navigationMenu/types';
// eslint-disable-next-line no-restricted-imports
import type { RouteConfig } from 'vue-router';

const insightCommodityPage = () =>
  import(
    /* webpackChunkName: "insight" */ 'src/domains/insight/homepage/components/InsightHomepage.vue'
  );

type PropsAndContext =
  | {
      props: PropsPageWithTagsAndContentOwnership;
      context: 'news';
    }
  | {
      props: PropsPageWithTagsAndContentOwnership;
      context: 'market-pulse';
    }
  | {
      props: PropsPageWithTags;
      context: 'webinars';
    }
  | {
      props: PropsPageWithReportType;
      context: 'reports';
    };

const buildRouteConfig = (args: {
  title: string;
  name: string;
  path: string;
  propsAndContext: PropsAndContext;
  permissions: string[];
}): RouteConfig => {
  const { title, name, path, propsAndContext, permissions } = args;
  return {
    name,
    path,
    components: {
      default: getComponentFromContext(propsAndContext.context),
    },
    props: { default: { ...propsAndContext.props, context: propsAndContext.context } },
    meta: {
      title,
    },
    beforeEnter: checkUserAccess(permissions),
  };
};

const buildCommodityHomepageRouteConfig = (commoditySlug: CommoditySlug): RouteConfig => {
  const title = INSIGHT_NAVIGATION_SECTIONS[commoditySlug].title;
  const section = INSIGHT_NAVIGATION_SECTIONS[commoditySlug];
  return {
    name: `${commoditySlug}-latest`,
    path: `${commoditySlug}/latest`,
    components: {
      default: insightCommodityPage,
    },
    props: { default: { commoditySlug } },
    meta: {
      title,
    },
    beforeEnter: checkUserAccess(getPermissionsFromCommodity(section)),
  };
};

export const getRouteConfigFromNavigationMenu = (): RouteConfig[] => {
  const routes: RouteConfig[] = [];
  Object.entries(INSIGHT_NAVIGATION_SECTIONS).forEach(([sectionKey, section]) => {
    const castedSectionKey = sectionKey as CommoditySlug;
    routes.push(buildCommodityHomepageRouteConfig(castedSectionKey));
    if (section.hasNews) {
      routes.push(
        buildRouteConfig({
          title: getPageTitle('news', sectionKey),
          name: getRouteName({ commoditySlug: castedSectionKey, context: 'news' }),
          path: getRoutePath({ commoditySlug: castedSectionKey, context: 'news' }),
          propsAndContext: {
            context: 'news',
            props: buildPropsWithTagsAndContentOwnership(section, 'News'),
          },
          permissions: commodityTitleToNewsPermissions[section.slug],
        }),
      );
    }
    if (section.hasResearchUpdates) {
      routes.push(
        buildRouteConfig({
          title: getPageTitle('market-pulse', sectionKey),
          name: getRouteName({ commoditySlug: castedSectionKey, context: 'market-pulse' }),
          path: getRoutePath({ commoditySlug: castedSectionKey, context: 'market-pulse' }),
          propsAndContext: {
            context: 'market-pulse',
            props: buildPropsWithTagsAndContentOwnership(section, 'Research'),
          },
          permissions: commodityTitleToResearchUpdatesPermissions[section.slug],
        }),
      );
    }
    if (section.hasWebinars) {
      routes.push(
        buildRouteConfig({
          title: getPageTitle('webinars', sectionKey),
          name: getRouteName({ commoditySlug: castedSectionKey, context: 'webinars' }),
          path: getRoutePath({ commoditySlug: castedSectionKey, context: 'webinars' }),
          propsAndContext: {
            context: 'webinars',
            props: buildPropsWithTags(section),
          },
          permissions: commodityTitleToWebinarPermissions[section.slug],
        }),
      );
    }
    if (section.monthlyReport.length > 0) {
      section.monthlyReport.forEach(report => {
        routes.push(
          buildRouteConfig({
            title: getPageTitle('reports', sectionKey),
            name: getRouteName({
              commoditySlug: castedSectionKey,
              context: 'reports',
              reportType: report,
            }),
            path: getRoutePath({
              commoditySlug: castedSectionKey,
              context: 'reports',
              reportType: report,
            }),
            propsAndContext: {
              context: 'reports',
              props: buildPropsWithReportType(report, section.title, 'Monthly'),
            },
            permissions: [monthlyReportToPermission[report]],
          }),
        );
      });
    }
    if (section.weeklyReport.length > 0) {
      section.weeklyReport.forEach(report => {
        routes.push(
          buildRouteConfig({
            title: getPageTitle('reports', sectionKey),
            name: getRouteName({
              commoditySlug: castedSectionKey,
              context: 'reports',
              reportType: report,
            }),
            path: getRoutePath({
              commoditySlug: castedSectionKey,
              context: 'reports',
              reportType: report,
            }),
            propsAndContext: {
              context: 'reports',
              props: buildPropsWithReportType(report, section.title, 'Weekly'),
            },
            permissions: [weeklyReportToPermission[report]],
          }),
        );
      });
    }
  });
  return routes;
};
