import {
  INSTALLATION_METRICS_CAPACITY_FULL,
  INSTALLATION_METRICS_CAPACITY_TRIAL,
  INSTALLATION_METRICS_OUTAGES_FULL,
  INSTALLATION_METRICS_UTILIZATION_FULL,
} from 'src/domains/onshore-asset-monitoring/lngInstallationMetrics/installationMetrics.constants';
import store from 'src/store';
import { useInjectStore } from 'src/store/useInjectStore';

import type { AppStore } from 'src/store/types';

const userHasAccessToInstallationMetrics = (currentStore: AppStore) =>
  currentStore.getters.userHasOnePermission([
    INSTALLATION_METRICS_CAPACITY_FULL,
    INSTALLATION_METRICS_UTILIZATION_FULL,
    INSTALLATION_METRICS_OUTAGES_FULL,
    INSTALLATION_METRICS_CAPACITY_TRIAL,
  ]);

const userHasAccessToInstallationMetricsCapacity = (currentStore: AppStore) =>
  currentStore.getters.userHasPermission(INSTALLATION_METRICS_CAPACITY_FULL);

const userHasAccessToInstallationMetricsUtilization = (currentStore: AppStore) =>
  currentStore.getters.userHasPermission(INSTALLATION_METRICS_UTILIZATION_FULL);

const userHasAccessToInstallationMetricsOutages = (currentStore: AppStore) =>
  currentStore.getters.userHasPermission(INSTALLATION_METRICS_OUTAGES_FULL);

const userHasAccessToInstallationMetricsCapacityTrial = (currentStore: AppStore) =>
  currentStore.getters.userHasPermission(INSTALLATION_METRICS_CAPACITY_TRIAL);

export const usePermissions = (useDirectStore?: boolean) => {
  const currentStore = useDirectStore ? store : useInjectStore();

  return {
    userHasAccessToInstallationMetrics: userHasAccessToInstallationMetrics(currentStore),
    userHasAccessToInstallationMetricsCapacity:
      userHasAccessToInstallationMetricsCapacity(currentStore),
    userHasAccessToInstallationMetricsUtilization:
      userHasAccessToInstallationMetricsUtilization(currentStore),
    userHasAccessToInstallationMetricsOutages:
      userHasAccessToInstallationMetricsOutages(currentStore),
    userHasAccessToInstallationMetricsCapacityTrial:
      userHasAccessToInstallationMetricsCapacityTrial(currentStore),
  };
};

export const useTrialPermissions = (useDirectStore?: boolean) => {
  const currentStore = useDirectStore ? store : useInjectStore();

  return userHasAccessToInstallationMetricsCapacityTrial(currentStore);
};
