import { AppRoutes } from 'src/app/routes';
import { SupplyDemandRoutes } from 'src/domains/supplyDemand/types';
import store from 'src/store';

import grainsRoutes from './grains/routes';

import { forceRefreshGuard } from 'src/services/appUpdate.service';

import type { RouteRecordRaw } from '@kpler/vue2-utils';

const TheSupplyDemandOilWorkspace = () =>
  import(
    /* webpackChunkName: "supplyDemand" */ 'src/domains/supplyDemand/oil/TheSupplyDemandWorkspace.vue'
  );

const OverviewTab = () =>
  store.getters.userHasAccessToOilSupplyDemand
    ? import(/* webpackChunkName: "supplyDemand" */ 'src/domains/supplyDemand/oil/OverviewTab.vue')
    : import(
        /* webpackChunkName: "supplyDemand" */ 'src/domains/supplyDemand/WorkspacePreview/OverviewTabPreview.vue'
      );

const MetricsTab = () =>
  store.getters.userHasAccessToOilSupplyDemand
    ? import(/* webpackChunkName: "supplyDemand" */ 'src/domains/supplyDemand/oil/MetricsTab.vue')
    : import(
        /* webpackChunkName: "supplyDemand" */ 'src/domains/supplyDemand/WorkspacePreview/MetricsTabPreview.vue'
      );

const DatasetsTab = () =>
  import(/* webpackChunkName: "supplyDemand" */ 'src/domains/supplyDemand/oil/DatasetsTab.vue');

const oilRoutes: RouteRecordRaw = {
  name: SupplyDemandRoutes.OIL,
  path: 'oil',
  component: TheSupplyDemandOilWorkspace,
  redirect: { name: SupplyDemandRoutes.OIL_OVERVIEW },
  beforeEnter: (to, _from, next) => {
    const nextWithRefresh = forceRefreshGuard(to, next);
    if (!store.getters.userHasAccessToOilSupplyDemand) {
      return nextWithRefresh();
    }
    if (typeof to.query.products === 'string') {
      const productIds = to.query.products.split(',');
      const userHasAllPermissions = productIds.every(product =>
        store.getters.userHasPermission(`supply_demand:basic:ui_access:${product}`),
      );
      if (userHasAllPermissions) {
        return nextWithRefresh();
      }
    }
    const sortedProducts = store.getters.accessibleOilSupplyDemandProducts.sort();
    return nextWithRefresh({
      path: to.path,
      query: {
        ...to.query,
        products: sortedProducts[0].toString(),
      },
    });
  },
  children: [
    {
      path: 'overview',
      name: SupplyDemandRoutes.OIL_OVERVIEW,
      component: OverviewTab,
    },
    {
      path: 'metrics',
      name: SupplyDemandRoutes.OIL_METRICS,
      component: MetricsTab,
    },
  ],
};

const datasetsRoutes: RouteRecordRaw = {
  path: 'datasets',
  component: TheSupplyDemandOilWorkspace,
  beforeEnter: (to, _from, next) => {
    const nextWithRefresh = forceRefreshGuard(to, next);
    if (!store.getters.userHasPermission('supply_demand:datasets')) {
      return nextWithRefresh({ name: AppRoutes.FORBIDDEN });
    }
    return nextWithRefresh();
  },
  children: [
    {
      path: '',
      name: SupplyDemandRoutes.DATASETS,
      component: DatasetsTab,
    },
  ],
};

// TODO: after some time, check the number of direct accesses to those old routes, and remove accordingly
const redirectOldOilRoutes: RouteRecordRaw[] = [
  {
    name: 'supply-demand-old-overview',
    path: 'overview',
    redirect: { name: SupplyDemandRoutes.OIL_OVERVIEW },
  },
  {
    name: 'supply-demand-old-metrics',
    path: 'metrics',
    redirect: { name: SupplyDemandRoutes.OIL_METRICS },
  },
];

const supplyDemandRoutes: RouteRecordRaw = {
  name: SupplyDemandRoutes.BASE,
  path: 'supply-demand',
  beforeEnter: (to, _from, next) => {
    const nextWithRefresh = forceRefreshGuard(to, next);

    const routePermissionMap = [
      {
        route: SupplyDemandRoutes.OIL,
        hasAccess: store.getters.userHasAccessToOilSupplyDemand,
      },
      {
        route: SupplyDemandRoutes.GRAINS,
        hasAccess: store.getters.userHasAccessToGrainsSupplyDemand,
      },
      {
        route: SupplyDemandRoutes.DATASETS,
        hasAccess: store.getters.userHasPermission('supply_demand:datasets'),
      },
    ];

    const hasUserAccessToRequestedRoute = routePermissionMap.find(
      ({ route, hasAccess }) => to.matched.some(r => r.name === route) && hasAccess,
    );
    if (hasUserAccessToRequestedRoute) {
      return nextWithRefresh();
    }

    const accessibleRoutesForUser = routePermissionMap
      .filter(({ hasAccess }) => hasAccess)
      .map(({ route }) => route);
    if (accessibleRoutesForUser.length > 0) {
      return nextWithRefresh({ name: accessibleRoutesForUser[0] });
    }

    // If the user doesn't have access to any market, fallback on Oil S&D, which would display the WorkspacePreview
    if (to.matched.some(route => route.name === SupplyDemandRoutes.OIL)) {
      return nextWithRefresh();
    }
    return nextWithRefresh({ name: SupplyDemandRoutes.OIL });
  },
  // TODO: remove the passthrough component once we switch to Vue Router >= 4.1
  component: {
    // Inline declaration of a component that renders our <router-view>
    // Taken from: https://github.com/vuejs/vue-router/issues/2105#issuecomment-678980641
    render: (c: any) => c('router-view'),
  },
  children: [oilRoutes, grainsRoutes, datasetsRoutes, ...redirectOldOilRoutes],
};

export default supplyDemandRoutes;
