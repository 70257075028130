import { AppRoutes } from 'src/app/routes';
import { useUserManagementPermissions } from 'src/domains/userManagement/utils/permissions.hook';

import { UserManagementRoutesTab } from './constants';

import { createAuthorizationHook } from 'src/helpers/router.helper';

import type { RouteRecordRaw } from '@kpler/vue2-utils';

const TheLicensesWrapper = () =>
  import(/* webpackChunkName: "licenses" */ './TheUserManagementWrapper.vue');
const TheUserListPage = () =>
  import(/* webpackChunkName: "licenses" */ './pages/user/TheUserListPage.vue');
const ThePackageListPage = () =>
  import(/* webpackChunkName: "licenses" */ './pages/package/ThePackageListPage.vue');
const TheLicenseListPage = () =>
  import(/* webpackChunkName: "licenses" */ './pages/license/TheLicenseListPage.vue');
const ThePermissionListPage = () =>
  import(/* webpackChunkName: "licenses" */ './pages/permissions/ThePermissionListPage.vue');

const userManagementRoutes: RouteRecordRaw = {
  name: 'user-management',
  path: 'user-management',
  component: TheLicensesWrapper,
  redirect: 'user-management/users',
  beforeEnter: createAuthorizationHook(
    () => useUserManagementPermissions(true).hasAccess,
    AppRoutes.FORBIDDEN,
  ),
  meta: { title: 'User Management Access' },
  children: [
    {
      name: UserManagementRoutesTab.USERS,
      path: 'users',
      component: TheUserListPage,
    },
    {
      name: UserManagementRoutesTab.USER_DETAILS,
      path: 'users/:id',
      component: TheUserListPage,
    },
    {
      name: UserManagementRoutesTab.PACKAGES,
      path: 'packages',
      component: ThePackageListPage,
      beforeEnter: createAuthorizationHook(
        () => useUserManagementPermissions(true).hasAdvanceAccess,
        UserManagementRoutesTab.USERS,
      ),
    },
    {
      name: UserManagementRoutesTab.PACKAGE_DETAILS,
      path: 'packages/:id',
      component: ThePackageListPage,
      beforeEnter: createAuthorizationHook(
        () => useUserManagementPermissions(true).hasAdvanceAccess,
        UserManagementRoutesTab.USERS,
      ),
    },
    {
      name: UserManagementRoutesTab.FEATURES,
      path: 'features',
      component: TheLicenseListPage,
      beforeEnter: createAuthorizationHook(
        () => useUserManagementPermissions(true).hasAdvanceAccess,
        UserManagementRoutesTab.USERS,
      ),
    },
    {
      name: UserManagementRoutesTab.FEATURE_DETAILS,
      path: 'features/:id',
      component: TheLicenseListPage,
      beforeEnter: createAuthorizationHook(
        () => useUserManagementPermissions(true).hasAdvanceAccess,
        UserManagementRoutesTab.USERS,
      ),
    },
    {
      name: UserManagementRoutesTab.PERMISSIONS,
      path: 'permissions',
      component: ThePermissionListPage,
      beforeEnter: createAuthorizationHook(
        () => useUserManagementPermissions(true).hasAdvanceAccess,
        UserManagementRoutesTab.USERS,
      ),
    },
    {
      name: UserManagementRoutesTab.PERMISSION_DETAILS,
      path: 'permissions/:id',
      component: ThePermissionListPage,
      beforeEnter: createAuthorizationHook(
        () => useUserManagementPermissions(true).hasAdvanceAccess,
        UserManagementRoutesTab.USERS,
      ),
    },
  ],
};

export default userManagementRoutes;
