import { toMoment } from '@kpler/terminal-utils';

import installationMetricsColors from './colors';
import {
  CapacityTableColumns,
  InstallationMetricSplits,
  LngInstallationMetricsRouteName,
  UtilizationTableColumns,
} from './enums';

import type {
  ChartColors,
  ChartLabels,
  HasColorMap,
  InstallationMetricsDateMap,
  TrendColorMap,
  UtilizationTitleMap,
} from './types';
import type { ObjectBase } from '@kpler/generic-utils';
import type { SerializedDateRange } from '@kpler/terminal-utils';
import { BoxDirection } from 'types/legacy-globals';
import { TemporalUnitName } from 'types/unit';

// @todo: reincorporate unit options once api can handle multiple units
export const UNIT_OPTIONS_IM: ReadonlyArray<ObjectBase<string>> = [
  { id: TemporalUnitName.MTPA, name: 'mtpa' },
];

export const INSTALLATION_DEVELOPMENT_PAGE_SIZE = 30;
export const TRAILING_UTILIZATION_PAGE_SIZE = 10;
const HISTORICAL_LIMIT = '2009-01-01';

export const MIN_DATE = toMoment(HISTORICAL_LIMIT);
export const MAX_DATE = toMoment().add(10, 'years');
export const UTILIZATION_MAX_DATE = toMoment();

export const DEFAULT_CAPACITY_DATE_RANGE: SerializedDateRange = {
  startDate: toMoment().subtract(2, 'years').format('YYYY-MM-DD'),
  endDate: toMoment().add(2, 'years').format('YYYY-MM-DD'),
};

export const installationMetricsDates: InstallationMetricsDateMap = {
  [LngInstallationMetricsRouteName.LNG_INSTALLATION_METRICS_CAPACITY]: {
    trialMinDate: toMoment().subtract(2, 'years'),
    trialMaxDate: toMoment().add(2, 'years'),
    minDate: toMoment(HISTORICAL_LIMIT),
    maxDate: toMoment().add(10, 'years'),
  },
  [LngInstallationMetricsRouteName.LNG_INSTALLATION_METRICS_UTILIZATION]: {
    trialMinDate: toMoment().subtract(2, 'years'),
    trialMaxDate: toMoment(),
    minDate: toMoment(HISTORICAL_LIMIT),
    maxDate: toMoment(),
  },
};

export const TRIAL_MIN_DATE = toMoment().subtract(2, 'years');
export const TRIAL_MAX_DATE = toMoment().add(2, 'years');

export const titleMap: Readonly<UtilizationTitleMap> = Object.freeze<UtilizationTitleMap>({
  export_volume: 'YTD export volume',
  import_volume: 'YTD import volume',
  utilization_rate: 'YTD utilization rate',
  trend_indicator: 'Trend indicator',
  yoy_change: 'YoY volume change',
});

export const trendColorMap: Readonly<TrendColorMap> = Object.freeze<TrendColorMap>({
  'downtrend (short run)': BoxDirection.NEGATIVE,
  'uptrend (short run)': BoxDirection.POSITIVE,
  'downtrend (long run)': BoxDirection.NEGATIVE,
  'uptrend (long run)': BoxDirection.POSITIVE,
  flat: BoxDirection.NEUTRAL,
});

export const hasColorMap: Readonly<HasColorMap> = Object.freeze<HasColorMap>({
  export_volume: false,
  utilization_rate: false,
  trend_indicator: true,
  yoy_change: true,
});

export const CHART_LABELS: Readonly<ChartLabels> = {
  utilizationRate: 'Utilization rate (%)',
  nominalCapacity: 'Nominal capacity (mt)',
};

export const CHART_COLORS: Readonly<ChartColors> = {
  utilizationRateArea: installationMetricsColors.lightBlue,
  utilizationRateLine: installationMetricsColors.blue,
  utilizationRateGradient: installationMetricsColors.whiteGradient,
  tradedVolume: installationMetricsColors.darkBlue,
  nominalCapacity: installationMetricsColors.orange,
};

export const INSTALLATION_METRICS_CAPACITY_FULL =
  'lng_installation_metrics:capacity:ui_access:full';
export const INSTALLATION_METRICS_UTILIZATION_FULL =
  'lng_installation_metrics:utilization:ui_access:full';
export const INSTALLATION_METRICS_OUTAGES_FULL = 'lng_installation_metrics:outages:ui_access:full';
export const INSTALLATION_METRICS_CAPACITY_TRIAL =
  'lng_installation_metrics:capacity:ui_access:trial';

export const CURRENT_SPLITS_LABELS = Object.freeze([
  { id: InstallationMetricSplits.STATUS, label: 'Status' },
  { id: InstallationMetricSplits.TRAIN, label: 'Train' },
]);

export const TOP_N_TRAINS = 10;

export const allCapacityColumnLabels = Object.freeze<CapacityTableColumns[]>([
  CapacityTableColumns.INSTALLATION,
  CapacityTableColumns.TYPE,
  CapacityTableColumns.CONTINENT,
  CapacityTableColumns.SUBCONTINENT,
  CapacityTableColumns.COUNTRY,
  CapacityTableColumns.TRAIN_PHASE,
  CapacityTableColumns.STATUS,
  CapacityTableColumns.STARTUP_DATE,
  CapacityTableColumns.END_DATE,
  CapacityTableColumns.NOMINAL_CAPACITY,
]);

export const allUtilizationColumnLabels = Object.freeze([
  UtilizationTableColumns.INSTALLATION,
  UtilizationTableColumns.CONTINENT,
  UtilizationTableColumns.COUNTRY,
  UtilizationTableColumns.NOMINAL_CAPACITY,
  UtilizationTableColumns.YTD_VOLUME,
  UtilizationTableColumns.YTD_UTILIZATION_RATE,
  UtilizationTableColumns.YOY_CHANGE,
  UtilizationTableColumns.SIX_MONTH_RATE,
  UtilizationTableColumns.THREE_MONTH_RATE,
  UtilizationTableColumns.ONE_MONTH_RATE,
  UtilizationTableColumns.TREND_INDICATOR,
]);

export const defaultRouteName = LngInstallationMetricsRouteName.LNG_INSTALLATION_METRICS_CAPACITY;

export const trainStatusHighchartsOptions = {
  yAxis: {
    min: 0,
    reversedStacks: false,
  },
  legend: {
    enabled: true,
    verticalAlign: 'top',
  },
  tooltip: {
    shared: true,
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      reversedStacks: true,
    },
  },
  responsive: {
    rules: [
      {
        condition: {
          minWidth: 1080,
        },
      },
    ],
  },
};

export const trainPhaseHighchartsOptions = {
  yAxis: {
    min: 0,
    reversedStacks: false,
  },
  legend: {
    enabled: true,
    verticalAlign: 'top',
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      reversedStacks: true,
    },
  },
  responsive: {
    rules: [
      {
        condition: {
          minWidth: 1080,
        },
      },
    ],
  },
};
