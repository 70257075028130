import { assertDefined } from '@kpler/generic-utils';
import moment from 'moment';

import { UnitService } from 'src/services/UnitService/UnitService';

import {
  convertToUnit,
  convertToTemporalUnit,
  getTemporalUnitDecomposition,
  getComputedValue,
} from 'src/helpers/conversion.helper';

import type { AppStore } from 'src/store/types';
import type { QuantityObject } from 'types/quantity';
import type { UnitName, ConvertToTemporalUnitOptions, TemporalUnitName } from 'types/unit';

type StoreGetter = () => AppStore; // used to instanciate conversionServiceLegacy

function isStoreGetter(storeInjection: AppStore | StoreGetter): storeInjection is StoreGetter {
  return typeof storeInjection === 'function';
}

export class ConversionService {
  #storeGetter: StoreGetter;

  constructor(storeInstance: AppStore);
  constructor(storeGetter: StoreGetter);
  constructor(storeInjection: AppStore | StoreGetter) {
    assertDefined(
      storeInjection,
      'storeInstance or storeGetter must be defined to be injected in ConversionService.',
    );

    this.#storeGetter = isStoreGetter(storeInjection) ? storeInjection : () => storeInjection;
  }

  private get store() {
    return this.#storeGetter();
  }

  convertTo(qtyObj: number, toUnit: UnitName): number;
  convertTo(qtyObj: QuantityObject, toUnit: UnitName): number;
  convertTo(
    qtyObj: QuantityObject,
    toUnit: UnitName | TemporalUnitName,
    options: ConvertToTemporalUnitOptions,
  ): number;

  convertTo(
    qtyObj: QuantityObject | number,
    toUnit: UnitName | TemporalUnitName,
    options?: ConvertToTemporalUnitOptions,
  ): number {
    if (UnitService.isUnitName(toUnit)) {
      const unit = this.store.getters.getUnit(toUnit);

      return convertToUnit(qtyObj, unit);
    }

    if (options === undefined) {
      throw new Error('Missing mandatory `options` attribute in convertTo().');
    }

    const { qtyGranularity, qtyPeriod, onlyRealized = false, roundToEndOfDay } = options;
    const { unitName, granularity } = getTemporalUnitDecomposition(toUnit);
    const unit = this.store.getters.getUnit(unitName);
    return convertToTemporalUnit(qtyObj, unit, {
      unitGranularity: granularity,
      qtyPeriod,
      qtyGranularity,
      onlyRealized,
      now: moment.utc(),
      roundToEndOfDay,
    });
  }

  // NB: to be used only when dimension (mass/volume/volume_gas) isn't relevant.
  // We don't currently need TemporalUnits here, but we may need to manage them in the future
  convertValueTo(value: number, toUnit: UnitName): number {
    const unit = this.store.getters.getUnit(toUnit);
    return getComputedValue(value, unit);
  }

  getSourceValue(value: number, sourceUnit: UnitName): number {
    const unit = this.store.getters.getUnit(sourceUnit);
    return getComputedValue(value, unit, true);
  }
}
