import type {
  SupplyDemandExtraMetric,
  SupplyDemandMetric,
} from 'src/domains/supplyDemand/constants';
// @TODO feature: grains-dashboard
// Restructure the code, to avoid importing from grains.
import type { GrainsSupplyDemandMetric } from 'src/domains/supplyDemand/grains/constants';
import type { RequestStatus } from 'types/legacy-globals';

export enum SupplyDemandRoutes {
  BASE = 'supply-demand',
  OIL = 'supply-demand-oil',
  OIL_OVERVIEW = 'supply-demand-oil-overview',
  OIL_METRICS = 'supply-demand-oil-metrics',
  DATASETS = 'supply-demand-datasets',
  GRAINS = 'supply-demand-grains',
  GRAINS_OVERVIEW = 'supply-demand-grains-overview',
}

export type RequestResponseWithStatus<T> = [T, RequestStatus];

export type SupplyDemandZone = {
  id: number;
  name: string;
  type: string;
};

export type AllSupplyDemandMetrics =
  | SupplyDemandMetric
  | SupplyDemandExtraMetric
  | GrainsSupplyDemandMetric;

export type TableRowsConfig = {
  title: string;
  key: AllSupplyDemandMetrics;
  tooltip?: {
    text: string;
    link: string;
  };
};
