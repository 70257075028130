import type { ObjectBase } from '@kpler/generic-utils';
import type { ProductFragment } from '@kpler/terminal-graphql';
import type {
  DateRange,
  DateRangePreset,
  Granularity,
  SerializedDateRange,
  RangeNumber,
} from '@kpler/terminal-utils';
import type { AnalyticsView } from 'types/analytics';
import type {
  CapacityObject,
  DeadWeightObject,
  VesselTypeClassificationSplit,
  FlowQuantity,
  PaginationParams,
  ResourceType,
  VesselTypeClassification,
  SpeedRangeObject,
} from 'types/legacy-globals';
import type { PricesBaseFiltersMapping, PricesSidebarFilterState } from 'types/prices';
import type { UnitName } from 'types/unit';
import type { Vessel } from 'types/vessel';
import type { Zone } from 'types/zone';

export enum FreightMetricsSplit {
  TOTAL = 'Total',
  COMMODITY = 'Product',
  DESTINATION_COUNTRY = 'DestinationCountry',
  DESTINATION_INSTALLATION = 'DestinationInstallation',
  DESTINATION_TRADING_REGION = 'DestinationTradingRegion',
  DESTINATION_PADD = 'DestinationPadd',
  ORIGIN_COUNTRY = 'OriginCountry',
  ORIGIN_INSTALLATION = 'OriginInstallation',
  ORIGIN_TRADING_REGION = 'OriginTradingRegion',
  ORIGIN_PADD = 'OriginPadd',
  VESSEL_TYPE = 'VesselType',
  VESSEL_STATE = 'VesselState',
  ORIGIN_SOURCE_ETA = 'OriginSourceEta',
  DESTINATION_SOURCE_ETA = 'DestinationSourceEta',
  ENGINE_TYPE = 'EngineType',
}

export enum FreightMetricsVesselState {
  LOADED = 'Loaded',
  BALLAST = 'Ballast',
}

export enum FreightMetricsMetric {
  TON_MILES = 'TonMiles',
  TON_DAYS = 'TonDays',
  AVG_SPEED = 'AvgSpeed',
  AVG_DISTANCE = 'AvgDistance',
}
export type FreightMetricsVesselOwner = {
  readonly id: number;
  readonly name: string;
};

export type FreightMetricsStateHydrated = {
  mainZones: readonly Zone[];
  secondaryZones: readonly Zone[];
  metric: FreightMetricsMetric;
  products: readonly ProductFragment[];
  includeIntra: boolean;
  includeBetaVessels: boolean;
  split: FreightMetricsSplit;
  deadweight: RangeNumber | null;
  capacity: RangeNumber | null;
  vesselClassifications: readonly string[];
  vessels: ReadonlyArray<ObjectBase<number>>;
  pricesFilters: PricesSidebarFilterState;
  vesselStates: readonly FreightMetricsVesselState[] | null;
  dateRange: DateRangePreset | DateRange;
  granularity: Granularity;
  seasonal: boolean;
  view: AnalyticsView;
  unit: UnitName;
  movingAverage: boolean;
  truncateY: boolean;
  engineTypes: readonly string[];
  speedRange: [string, string] | null;
  owners: readonly FreightMetricsVesselOwner[];
};

export type FreightMetricsState = {
  mZones: readonly number[];
  sZones: readonly number[];
  metric: FreightMetricsMetric;
  products: readonly string[];
  intra: boolean;
  granularity: Granularity;
  seasonal: boolean;
  beta: boolean;
  splitOn: FreightMetricsSplit;
  deadweight: RangeNumber | null;
  capacity: RangeNumber | null;
  vesselClassifications: readonly string[];
  dates: DateRangePreset | DateRange;
  vessels: readonly number[];
  vesselStates: readonly FreightMetricsVesselState[] | null;
  pricesFilters: PricesBaseFiltersMapping;
  view: AnalyticsView;
  unit: UnitName;
  movingAverage: boolean;
  truncateY: boolean;
  engineTypes: readonly string[] | null;
  speedRange: [string, string] | null;
  owners: readonly number[] | null;
};

type FreightMetricsParamsSplit =
  | Exclude<FreightMetricsSplit, FreightMetricsSplit.TOTAL>
  | VesselTypeClassificationSplit;

export type FreightMetricsParams = SerializedDateRange & {
  metric: FreightMetricsMetric;
  numberOfSplits?: number;
  fromLocations: Array<{ resourceType: string; id: number }>;
  toLocations: Array<{ resourceType: string; id: number }>;
  periodicity: Granularity;
  withBetaVessels: boolean;
  withIntraCountry: boolean;
  vesselClassifications: readonly string[];
  product?: readonly number[];
  notProduct?: readonly number[];
  splitOn?: FreightMetricsParamsSplit;
  deadWeight?: DeadWeightObject;
  capacity?: CapacityObject;
  vesselIds?: readonly number[];
  vesselTypeClassification?: VesselTypeClassification;
  status?: readonly string[];
  engineTypes: readonly string[];
  speedRange: SpeedRangeObject;
  ownerIds: readonly number[];
};

export type FreightMetricsVesselsParams = PaginationParams &
  SerializedDateRange & {
    date: string;
    metric: FreightMetricsMetric;
    fromLocations: ReadonlyArray<{ resourceType: ResourceType; id: number }>;
    toLocations: ReadonlyArray<{ resourceType: ResourceType; id: number }>;
    periodicity: Granularity;
    withBetaVessels: boolean;
    withIntraCountry: boolean;
    vesselClassifications: readonly string[];
    product?: readonly number[];
    notProduct?: readonly number[];
    splitOn?: FreightMetricsParamsSplit;
    deadWeight?: DeadWeightObject;
    capacity?: CapacityObject;
    splitValues?: readonly string[];
    splitValuesToExclude?: readonly string[];
    vesselIds?: readonly number[];
    vesselTypeClassification?: VesselTypeClassification;
    status?: readonly string[];
    engineTypes: readonly string[];
    speedRange: SpeedRangeObject;
    ownerIds: readonly number[];
  };

export type FreightMetricsVesselPayload = {
  id: string;
  vessel: Vessel;
  flowQuantities?: FlowQuantity[];
  tonMeters?: number;
  tonSeconds?: number;
  start: string;
  speed: number;
  distance: number;

  avgSpeedBallast?: number;
  avgSpeedLoaded?: number;
  avgSpeedTotal?: number;

  avgDistanceBallast?: number;
  avgDistanceLoaded?: number;
  avgDistanceTotal?: number;

  ballastDuration?: number;
  duration?: number;
};

export type FreightMetricsTooltipProps = {
  unitName: UnitName;
  granularity: Granularity;
};
