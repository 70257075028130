import { installationIds } from 'src/domains/onshore-asset-monitoring/constants/static';

export const LNG_REGIONS = Object.freeze([
  {
    id: 'uk',
    name: 'UK - Benelux',
    installationIds: [
      installationIds.SOUTH_HOOK,
      installationIds.GRAIN,
      installationIds.DRAGON,
      installationIds.GATE,
      installationIds.ZEEBRUGGE,
      installationIds.EEMSENERGY,
    ],
  },
  {
    id: 'france',
    name: 'France',
    installationIds: [
      installationIds.MONTOIR,
      installationIds.DUNKERQUE,
      installationIds.FOS_CAVAOU,
      installationIds.FOS_TONKIN,
      installationIds.LE_HAVRE,
    ],
  },
  {
    id: 'spain',
    name: 'Spain',
    installationIds: [
      installationIds.BILBAO,
      installationIds.CARTAGENA,
      installationIds.SAGUNTO,
      installationIds.BARCELONA,
      installationIds.HUELVA,
      installationIds.MUGARDOS,
      installationIds.EL_MUSEL,
    ],
  },
  {
    id: 'italy',
    name: 'Italy',
    installationIds: [
      installationIds.TOSCANA,
      installationIds.LA_SPEZIA,
      installationIds.ROVIGO,
      installationIds.PIOMBINO,
    ],
  },
  {
    id: 'germany',
    name: 'Germany',
    installationIds: [
      installationIds.WILHELMSHAVEN,
      installationIds.BRUNSBUTTEL,
      installationIds.LUBMIN,
      installationIds.MUKRAN,
    ],
  },
  {
    id: 'restOfEurope',
    name: 'Rest of Europe',
    installationIds: [
      installationIds.REVITHOUSSA,
      installationIds.KRK_LNG_FSRU,
      installationIds.SINES,
      installationIds.KLAIPEDA,
      installationIds.SWINOUJSCIE,
      installationIds.ALEXANDROUPOLIS,
    ],
  },
  {
    id: 'us',
    name: 'US',
    installationIds: [
      installationIds.SABINE_PASS_LNG_TERMINAL_LIQUEFACTION,
      installationIds.CAMERON,
      installationIds.COVE_POINT,
      installationIds.ELBA_ISLAND,
      installationIds.CORPUS_CHRISTI,
      installationIds.FREEPORT,
      installationIds.CALCASIEU_PASS,
      installationIds.PLAQUEMINES,
    ],
  },
  {
    id: 'australia',
    name: 'Australia',
    installationIds: [installationIds.QCLNG, installationIds.APLNG, installationIds.GLNG],
  },
  {
    id: 'russia',
    name: 'Russia - FSU',
    installationIds: [installationIds.MURMANSK, installationIds.KAMCHATKA],
  },
]);

export const internationalWatersSplitValue = 'International Water';
export const floatingStorageTankSplitValue = '4';
export const oilInTransitTankSplitValue = '5';
