import { platformsToMarkets, marketsToPlatforms } from 'src/adapters/platform.adapter';
import { ZONE_TYPES_BY_CATEGORY } from 'src/constants/zone.constants';

import ToastService from 'src/services/toast.service';

import { ResourceType } from 'types/legacy-globals';
import type {
  Unlocode,
  ZoneFromStaticData,
  ZoneEdit,
  ZoneToStaticData,
  ZoneParent,
  ZoneParentFromStaticData,
} from 'types/zone';

const generateZoneParentErrorMessage = (
  zoneParentType: ZoneParentFromStaticData[],
  zone: ZoneFromStaticData,
): void => {
  const parentsListMessage = zoneParentType.map(x => `${x.name} (id: ${x.id})`).join(',');
  const errorMessage = `There are currently ${zoneParentType.length} parents
  attached to zone ${zone.id}: ${parentsListMessage}. There should be only one.
  Please contact support to fix the issue.`;
  ToastService.toastError(errorMessage);
  throw new Error(errorMessage);
};

export const formatFromStaticData = (zone: ZoneFromStaticData): ZoneEdit => {
  const { directParents, platforms, unlocodes, portCallMinDuration, ...other } = zone;

  const allLandParents = directParents.filter(z => ZONE_TYPES_BY_CATEGORY.land.includes(z.type));
  const allSeaParents = directParents.filter(z => ZONE_TYPES_BY_CATEGORY.sea.includes(z.type));

  [allLandParents, allSeaParents].forEach(zoneParentType => {
    if (zoneParentType.length > 1) {
      generateZoneParentErrorMessage(zoneParentType, zone);
    }
  });

  const parentCustom = {
    parentCustom: directParents.filter(z => ZONE_TYPES_BY_CATEGORY.other.includes(z.type)),
  };

  return {
    unlocodes: unlocodes.map(u => ({ name: u })),
    platforms: platformsToMarkets(platforms),
    ...other,
    parentLand: allLandParents[0] ?? null,
    parentSea: allSeaParents[0] ?? null,
    ...parentCustom,
    resourceType: ResourceType.STATIC_ZONE_EDIT,
    portCallMinDuration: portCallMinDuration ? portCallMinDuration / 60 : null,
  };
};

export const formatToStaticData = (zone: ZoneEdit): ZoneToStaticData => {
  const {
    unlocodes,
    parentLand,
    parentSea,
    parentCustom,
    type,
    platforms,
    portCallMinDuration,
    resourceType,
    ...other
  } = zone;
  const parentCustomZone: ZoneParent[] = parentCustom.filter((z): z is ZoneParent => z !== null);
  const parentCustomZoneIds: number[] = parentCustomZone
    .map(x => x.id)
    .filter((y): y is number => y !== null);

  const landParentId = parentLand === null ? null : (parentLand.id ?? null);
  const seaParentId = parentSea === null ? null : (parentSea.id ?? null);
  const parents = {
    landId: landParentId,
    seaId: seaParentId,
    customIds: parentCustomZoneIds,
  };

  return {
    parents,
    ...other,
    type,
    platforms: marketsToPlatforms(platforms),
    unlocodes: unlocodes.filter((u): u is Unlocode => u !== null).map(u => u.name),
    portCallMinDuration: portCallMinDuration ? portCallMinDuration * 60 : null,
  };
};
