import { AppRoutes } from 'src/app/routes';
import { SupplyDemandRoutes } from 'src/domains/supplyDemand/types';
import store from 'src/store';

import { Products } from './constants';

import { forceRefreshGuard } from 'src/services/appUpdate.service';

import type { RouteRecordRaw } from '@kpler/vue2-utils';

const TheWorkspace = () =>
  import(/* webpackChunkName: "supplyDemand" */ 'src/domains/supplyDemand/grains/TheWorkspace.vue');
const OverviewTab = () =>
  import(/* webpackChunkName: "supplyDemand" */ 'src/domains/supplyDemand/grains/OverviewTab.vue');

const routes: RouteRecordRaw = {
  name: SupplyDemandRoutes.GRAINS,
  path: 'grains',
  component: TheWorkspace,
  redirect: { name: SupplyDemandRoutes.GRAINS_OVERVIEW },
  beforeEnter: (to, _from, next) => {
    const nextWithRefresh = forceRefreshGuard(to, next);
    if (!store.getters.userHasAccessToGrainsSupplyDemand) {
      return nextWithRefresh({ name: AppRoutes.FORBIDDEN });
    }
    const productId = to.query.products;
    if (typeof productId === 'string') {
      if (Object.values(Products).includes(Number(productId))) {
        return nextWithRefresh();
      }
    }
    return nextWithRefresh({
      path: to.path,
      query: {
        ...to.query,
        products: Products.CORN.toString(),
      },
    });
  },
  meta: { title: 'Grains Supply & Demand' },
  children: [
    {
      path: 'overview',
      name: SupplyDemandRoutes.GRAINS_OVERVIEW,
      component: OverviewTab,
    },
  ],
};

export default routes;
