import type {
  CellContent,
  TableContent,
  TableContentWithRowspan,
  ValueWithRowspan,
} from 'src/components/table/table.types';
import type { TableConfig } from 'types/legacy-globals';

const getColumnNameAndValuePair = (
  column: string,
  value: CellContent,
  rowspan: number,
): [string, ValueWithRowspan] => [column, { value, rowspan }];
const getColumnNameAndValuePairRowspanNormal = (
  column: string,
  value: CellContent,
): [string, ValueWithRowspan] => getColumnNameAndValuePair(column, value, 1);
const getColumnNameAndValuePairRowspanHidden = (
  column: string,
  value: CellContent,
): [string, ValueWithRowspan] => getColumnNameAndValuePair(column, value, 0);

const getColumnSupportsRowspan = (columnSet: Set<string>, column: string): boolean =>
  columnSet.has(column);

const getPreviousValueInColumnIsTheSame = (
  dataRows: TableContent,
  dataRowIndex: number,
  dataKey: string,
  currentDataValue: CellContent,
): boolean => dataRowIndex > 0 && dataRows[dataRowIndex - 1][dataKey] === currentDataValue;

const getColumnNameAndValuePairWithRowspan = (
  dataRows: TableContent,
  dataRowIndex: number,
  dataKey: string,
  currentDataValue: CellContent,
) => {
  const furtherValues = dataRows.slice(dataRowIndex + 1);
  const stopIndex = furtherValues.findIndex(x => x[dataKey] !== currentDataValue);
  const rowspan = stopIndex === -1 ? dataRows.length - dataRowIndex : stopIndex + 1;
  return getColumnNameAndValuePair(dataKey, currentDataValue, rowspan);
};

const getRowDataColumnValue = (
  columnConfigs: readonly TableConfig[] | undefined,
  rowDataColumn: string,
  rowData: Record<string, CellContent>,
) => {
  const rowDataColumnValue = rowData[rowDataColumn];
  const matchingColumnConfig = columnConfigs?.find(c => c.key === rowDataColumn);

  return matchingColumnConfig && (matchingColumnConfig as any).accessor
    ? (matchingColumnConfig as any).accessor(rowData)
    : rowDataColumnValue;
};

const addRowspanToDataColumns = (
  data: TableContent,
  columnSet: Set<string>,
  columnConfigs?: readonly TableConfig[],
): TableContentWithRowspan =>
  data.map((rowData: Record<string, CellContent>, dataRowIndex, dataRows) => {
    const rowDataColumns = Object.keys(rowData);

    const columnNameAndValuePairs: Array<[string, ValueWithRowspan]> = rowDataColumns.map(
      (rowDataColumn): [string, ValueWithRowspan] => {
        const rowDataColumnValue = getRowDataColumnValue(columnConfigs, rowDataColumn, rowData);

        if (!getColumnSupportsRowspan(columnSet, rowDataColumn)) {
          return getColumnNameAndValuePairRowspanNormal(rowDataColumn, rowDataColumnValue);
        }

        if (
          getPreviousValueInColumnIsTheSame(
            dataRows,
            dataRowIndex,
            rowDataColumn,
            rowDataColumnValue,
          )
        ) {
          return getColumnNameAndValuePairRowspanHidden(rowDataColumn, rowDataColumnValue);
        }

        return getColumnNameAndValuePairWithRowspan(
          dataRows,
          dataRowIndex,
          rowDataColumn,
          rowDataColumnValue,
        );
      },
    );

    return Object.fromEntries(columnNameAndValuePairs);
  });

const addIsEvenToDataColumns = (dataWithRowspan: TableContentWithRowspan) => {
  const evenHistoryPerColumn: Record<string, boolean> = {};

  return dataWithRowspan.map((dataRow: Record<string, ValueWithRowspan>) => {
    const columnKeyNameAndValuePairs = Object.entries(dataRow);

    const dataWithRowspanAndEven = columnKeyNameAndValuePairs.map(
      ([dataKey, dataValue]: [string, ValueWithRowspan]) => {
        const isEven = evenHistoryPerColumn[dataKey] ?? true;
        if (dataValue.rowspan !== 0) {
          evenHistoryPerColumn[dataKey] = !isEven;
        }
        return [dataKey, { ...dataValue, isEven: evenHistoryPerColumn[dataKey] }];
      },
    );

    return Object.fromEntries(dataWithRowspanAndEven);
  });
};

export const transformDataToRowspanData = (
  data: TableContent,
  columnNamesToAddRowspan: readonly string[],
  columnConfigs?: readonly TableConfig[],
): TableContentWithRowspan => {
  const columnSet = new Set(columnNamesToAddRowspan);

  const dataWithRowspan = addRowspanToDataColumns(data, columnSet, columnConfigs);
  return addIsEvenToDataColumns(dataWithRowspan);
};

export function getRowAs<T>(row: unknown): T {
  return row as T;
}
