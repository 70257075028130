import { AppRouter } from '@kpler/terminal-utils';

/**
 * Lazy initialization of modules
 */
export const lazyInit = () => {
  setTimeout(() => {
    import('@kpler/highcharts/highcharts.css');

    /** Fetch Highcharts chunk lazily and run initializeHighcharts() */
    import(/* webpackChunkName: "highcharts" */ '@kpler/highcharts').then(chunk =>
      chunk.initializeHighcharts(),
    );

    if (process.env.NX_PUBLIC_SENTRY === 'true') {
      /** Fetch Sentry chunk lazily and run initializeSentry() */
      import(/* webpackChunkName: "sentry" */ 'src/sentry').then(chunk =>
        chunk.initializeSentry(AppRouter.getRouter()),
      );
    }
  }, 0);
};
