query slots($installationIds: [ID!]!) {
  slots(installationIds: $installationIds) {
    onOffer
    installation {
      id
      name
    }
    seller {
      name
      id
    }
    date
  }
}
