import { MyAccessRoutes } from 'src/domains/myAccess/constants';

import type { RouteRecordRaw } from '@kpler/vue2-utils';

const TheMyAccessWrapper = () =>
  import(/* webpackChunkName: "myAccess" */ './TheMyAccessWrapper.vue');
const TheOverviewPage = () =>
  import(/* webpackChunkName: "myAccess" */ './pages/overview/TheOverviewPage.vue');

const myAccessRoutes: RouteRecordRaw = {
  name: 'my-access',
  path: 'my-access',
  component: TheMyAccessWrapper,
  redirect: 'my-access/overview',
  meta: { title: 'My access' },
  children: [
    {
      name: MyAccessRoutes.OVERVIEW,
      path: 'overview',
      component: TheOverviewPage,
    },
  ],
};

export default myAccessRoutes;
