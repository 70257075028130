import type { ObjectBase } from '@kpler/generic-utils';
import type { ZoneFragment } from '@kpler/terminal-graphql';
import type { Market } from '@kpler/web-ui';
import type { MultiPolygon } from 'geojson';
import type { Installation } from 'types/installation';
import type { LatLon, ResourceType } from 'types/legacy-globals';
import type { Player } from 'types/player';
import type { QuantityObject, EmptyQuantityObject } from 'types/quantity';
import type { PlatformStaticData } from 'types/staticData';

export enum ZoneType {
  ANCHORAGE = 'anchorage',
  BAY = 'bay',
  CANAL = 'canal',
  CAPE = 'cape',
  CHECKPOINT = 'checkpoint',
  CONTINENT = 'continent',
  COUNTRY = 'country',
  COUNTRY_CHECKPOINT = 'country_checkpoint',
  CUSTOM = 'custom',
  DUMMY = 'dummy',
  GULF = 'gulf',
  GULF_CHECKPOINT = 'gulf_checkpoint',
  ISLAND = 'island',
  METRICS = 'metrics',
  OCEAN = 'ocean',
  PENINSULA = 'peninsula',
  PORT = 'port',
  REGION = 'region',
  SEA = 'sea',
  STORAGE = 'storage',
  STRAIT = 'strait',
  SUBCONTINENT = 'subcontinent',
  SUBREGION = 'subregion',
  WAITING = 'waiting',
  WORLD_METRICS = 'world_metrics',
  WORLD_FRONT_ONLY = 'world',
}

export type ZoneWithResourceType = ZoneFragment & {
  resourceType: ResourceType.ZONE;
  isExcluded?: boolean;
};

export type ZoneParent = {
  id: number;
  name: string;
};
export type ZoneParentFromStaticData = ZoneParent & {
  type: ZoneType;
  distance: number;
  platforms: PlatformStaticData[];
};

export type ZoneParentsToStaticData = {
  landId: number | null;
  seaId: number | null;
  customIds: number[];
};

export type ZoneCountry = ZoneBase & {
  type: ZoneType.COUNTRY;
};

export type ZoneContinent = ZoneBase & {
  type: ZoneType.CONTINENT;
};

export type ZoneSubcontinent = ZoneBase & {
  type: ZoneType.SUBCONTINENT;
};

export type ZoneWithCountry = {
  id: number;
  country: { id: number; name: string };
};

export type ParentZone = {
  id: number;
  name: string;
  resourceType: ResourceType.ZONE;
  type: ZoneType;
  distance: number;
  longitude?: number;
  latitude?: number;
};

export type ZoneBase = ObjectBase<number> & {
  type?: string;
  resourceType: ResourceType.ZONE;
};

export type PriceZone = ZoneBase & {
  country?: ZoneCountry;
  parentZones?: ParentZone[];
  subcontinent?: ZoneSubcontinent;
  continent?: ZoneContinent;
  type: ZoneType;
};

type MetricsZoneType = ZoneType.METRICS | ZoneType.WORLD_METRICS;

type CommonZoneProperties = ZoneBase & {
  isInUnitedStates: boolean;
  isStorageSelected: boolean; // @TODO ew, rename on webserver?
  isFleetMetrics: boolean;
  geo: LatLon | null;
  import: {
    installations: Installation[];
    countries: Zone[];
  };
  export: {
    installations: Installation[];
    countries: Zone[];
  };
  range: number | null;
  shape: MultiPolygon | null;
};

export type Zone = CommonZoneProperties & {
  type: Exclude<ZoneType, MetricsZoneType>;
  // @TODO only certain types of zones have a country
  country?: ZoneCountry;
  parentZones: ParentZone[];
  continent?: ZoneContinent;
};

export type MetricsZone = CommonZoneProperties & {
  type: MetricsZoneType;
};

// Move to a dedicated file if we need it in other types than zones
export type Shareholder = {
  id: number;
  player: Player;
  holdingCapacity: QuantityObject | EmptyQuantityObject;
  resourceType: ResourceType.CAPACITY_HOLDER;
  numberOfInstallations: number;
};

export type NestedZoneInZoneDetails = Zone & {
  nominalAnnualCapacity: QuantityObject;
  numberOfInstallations: number;
};

export type NestedInstallationInZoneDetails = Installation & {
  technicalDetails: {
    nominalAnnualCapacity: QuantityObject;
  };
};

export type ZoneDetails = ZoneBase & {
  export: {
    capacityHolders: Shareholder[];
    installations: NestedInstallationInZoneDetails[];
    countries: NestedZoneInZoneDetails[];
  };
  import: {
    capacityHolders: Shareholder[];
    installations: NestedInstallationInZoneDetails[];
    countries: NestedZoneInZoneDetails[];
  };
};

export const enum ZoneTypeCategory {
  // Seas
  SEA = 'sea',
  // Lands
  COUNTRY = 'country',
  CONTINENT = 'continent',
  SUBCONTINENT = 'subcontinent',
  SUBREGION = 'subregion',
}

export type ZoneEditBase = {
  id: number | null;
  name: string | null;
  type: ZoneType;
  latitude: number | null;
  longitude: number | null;
  range: number | null;
  polygon: MultiPolygon | null;
  description: string | null;
  portCallMaxOpeningSpeed: number | null;
  portCallMinDuration: number | null;
  isSts: boolean;
  isForecastCheckpoint: boolean;
  isFloatingArea: boolean;
  isPort: boolean;
  aliases: ZoneAlias[];
};
export type ZoneFromStaticData = ZoneEditBase & {
  platforms: PlatformStaticData[];
  directParents: ZoneParentFromStaticData[];
  unlocodes: string[];
};

export type ZoneToStaticData = ZoneEditBase & {
  platforms: PlatformStaticData[];
  parents: ZoneParentsToStaticData;
  unlocodes: string[];
};

export type ZoneEdit = ZoneEditBase & {
  platforms: Market[];
  parentLand: ZoneParent | null;
  parentSea: ZoneParent | null;
  parentCustom: Array<ZoneParent | null>;
  unlocodes: Array<Unlocode | null>;
  resourceType: ResourceType.STATIC_ZONE_EDIT | ResourceType.STATIC_ZONE_CREATE;
};

type ZoneAliasBase = {
  alias: string;
};

export type ZoneAlias = ZoneAliasBase & {
  id: number | null;
  isFormerName: boolean;
};

export type Unlocode = {
  name: string;
};

export type ZoneAliasEdit = {
  id: number | null;
  alias: string | null;
  isFormerName: boolean;
  readonly: boolean;
};

export type ZoneLinkedItems = {
  berthNames: string[];
  installationNames: string[];
};
