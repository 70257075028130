import { isDefined } from '@kpler/generic-utils';

import type { CommercialProduct, RawUserFromApi, Service, User } from 'types/user';

export const enforceProductIdsAsStringAndCleanupNullProducts = (user: RawUserFromApi): User => {
  const servicesWithProductIdsAsString: Service[] = user.services.map(service => ({
    ...service,
    products: service.products
      .map(product => ({
        ...product,
        id: product.id?.toString() ?? null,
      }))
      .filter((product): product is CommercialProduct => isDefined(product.id)),
  }));
  return {
    ...user,
    services: servicesWithProductIdsAsString,
  };
};
