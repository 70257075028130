fragment basePart on OfflineEvent {
  installationId
  installationName
  eventId
  unitName
  unitTypeShortName
  utypeDesc
  eStatus
  eCause
  uCapacity
  capOffline
  startDate
  endDate
}

query offlineEvents($input: OfflineEventInput!) {
  offlineEvents(input: $input) {
    items {
      ...basePart
      plantName
      unitId
      ownerName
      plantId
      physCity
      pCounty
      plantState
      eventType
      country
      marketReg
      worldReg
      uStatus
      primFuel
      secndFuel
      fuelGroup
      capUom
      powerUsag
      eDuration
      eventKind
      precision
      eStatus
      prevStart
      prevEnd
      heatrate
      elecondesc
      paddReg
      isortoregion
      pTradeRegion
      mmcfDemand
      gasRegion
      comments
      confirm
      parentname
      latitude
      longitude
      liveDate
      releaseDt
    }
    total
  }
}

query rspOfflineEvents($input: OfflineEventInput!) {
  offlineEvents(input: $input) {
    items {
      ...basePart
    }
    total
  }
}

query installationOfflineEvents {
  plants {
    id
    hasPlanned
    hasUnplanned
  }
}

query unitTypeSubFamilies {
  unitTypeSubFamilies {
    name
    family
  }
}

query offlineEventTimeSeries($input: OfflineEventsAnalyticsParams!) {
  offlineEventAnalytics(input: $input) {
    metadata {
      datasetNames
      splitValueNames
    }
    series {
      date
      datasets {
        datasetName
        values {
          volume
        }
        splitValues {
          splitId
          name
          values {
            volume
          }
        }
      }
    }
  }
}

query availableCapacityTimeSeries($input: AvailableCapacityAnalyticsParams!) {
  availableCapacitiesAnalytics(input: $input) {
    metadata {
      datasetNames
    }
    series {
      date
      datasets {
        datasetName
        values {
          volume
        }
        splitValues {
          splitId
          name
          values {
            volume
          }
        }
      }
    }
  }
}

query unitCapacities($input: UnitCapacityInput!) {
  unitCapacities(input: $input) {
    installationId
    installationName
    name
    unitType
    maxCapacity
    availableCapacity
  }
}

query refineriesInstallations {
  refineriesInstallations {
    id
    name
  }
}
