import { toMoment } from '@kpler/terminal-utils';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';

import {
  validateRequired,
  validateEmailFormat,
  VALIDATION_SUCCESS,
} from 'src/main/map/panels/staticData.helper';

import type { Moment } from 'moment';
import type { ParentPlayer, ParentPlayerEdit, PlayerEdit } from 'types/edits/player';
import type { ValidationResult } from 'types/validation';
import { ValidationStatus } from 'types/validation';

export const getMinSince = () => toMoment('1900-01-01');
export const getMaxUntil = () => toMoment('2100-01-01');

export type ValidatePlayerEdit = {
  name: ValidationResult;
  dateRangesGaps: ValidationResult;
  dateRangesOverlaps: ValidationResult;
  email: ValidationResult;
};

export type ValidateParentPlayerForm = {
  share: ValidationResult;
  controls: ValidationResult;
};

export type ParentPlayerDateRange = {
  since: Moment;
  until: Moment;
  status: ValidationStatus;
  parents: ParentPlayerEdit[];
};

export const isActiveRange = (since: Moment, until: Moment): boolean =>
  since.isSameOrBefore(moment.utc(), 'day') && until.isSameOrAfter(moment.utc(), 'day');

const getRanges = (parents: ParentPlayer[]) => {
  const ranges = parents.map(({ since, until }) => ({
    since: since ?? getMinSince(),
    until: until ?? getMaxUntil(),
  }));

  return ranges.filter(
    (range, i) =>
      ranges.findIndex(
        otherRange => range.since.isSame(otherRange.since) && range.until.isSame(otherRange.until),
      ) === i,
  );
};

export const getParentPlayersByRange = (
  players: ParentPlayer[],
  { since, until }: { since: Moment; until: Moment },
) =>
  players
    .filter(p => {
      const sinceDate = p.since ?? getMinSince();
      const untilDate = p.until ?? getMaxUntil();
      return sinceDate.isSame(since, 'days') && untilDate.isSame(until, 'days');
    })
    .map(({ since: unusedSince, until: unusedUntil, ...other }) => other);

export const initParentPlayersByDateRanges = (parents: ParentPlayer[]): ParentPlayerDateRange[] =>
  getRanges(parents).map(({ since, until }) => ({
    since,
    until,
    parents: getParentPlayersByRange(parents, { since, until }),
    status: ValidationStatus.ERROR,
  }));

export const validateShare = (parentPlayers: ParentPlayerEdit[]) => {
  const errors = [];
  const sumShare = parentPlayers.reduce((acc, player) => acc + (player.share ?? 0), 0);

  if (sumShare > 100) {
    errors.push(`Total shares must not exceed 100%. Current total ${sumShare}`);
  }
  if (parentPlayers.some(({ share }) => !share)) {
    errors.push(`Parent shares cannot be null or equal to zero.`);
  }
  if (errors.length) {
    return { status: ValidationStatus.ERROR, message: errors.join(', ') };
  }
  return VALIDATION_SUCCESS;
};

export const validateControls = (parentPlayers: ParentPlayerEdit[]) => {
  const numberOfControllers = parentPlayers.filter(p => p.controls).length;
  if (numberOfControllers > 1) {
    return {
      status: ValidationStatus.ERROR,
      message: `Only one player can control another player. Current values ${numberOfControllers}`,
    };
  }
  return VALIDATION_SUCCESS;
};

export const validateParentPlayer = (parentPlayers: ParentPlayerEdit[]) => ({
  share: validateShare(parentPlayers),
  controls: validateControls(parentPlayers),
});

export const isParentPlayerValid = (
  parentPlayerForm: ValidateParentPlayerForm,
): ValidationStatus =>
  Object.values(parentPlayerForm).every(
    validation => validation.status === ValidationStatus.SUCCESS,
  )
    ? ValidationStatus.SUCCESS
    : ValidationStatus.ERROR;

const checkOverlaps = (dateRanges: ParentPlayerDateRange[]) => {
  const rangeErrors: string[] = [];
  dateRanges.forEach(({ since, until }, index) => {
    const overlappingDateRanges = dateRanges.find(
      (comparison, indexComparison) =>
        (comparison.since.isBetween(since, until, null, '[]') ||
          comparison.until.isBetween(since, until, null, '[]')) &&
        index !== indexComparison,
    );
    if (overlappingDateRanges) {
      const formattedSince = since.isSame(getMinSince()) ? 'Since creation' : since.format('LL');
      const formattedUntil = until.isSame(getMaxUntil()) ? 'until unknown' : until.format('LL');

      rangeErrors.push(`${formattedSince} - ${formattedUntil}`);
    }
  });

  if (rangeErrors.length) {
    return {
      status: ValidationStatus.ERROR,
      message: `There are overlaps to fix regarding the following ranges: ${rangeErrors.join(
        ', ',
      )}`,
    };
  }
  return VALIDATION_SUCCESS;
};

const checkGaps = (dateRanges: ParentPlayerDateRange[]) => {
  const gapMessage: string[] = [];
  if (dateRanges.length > 1) {
    const sortedDateRange = cloneDeep(dateRanges).sort((a, b) => a.since.diff(b.since));
    sortedDateRange.forEach(({ since, until }, i) => {
      const nextDateRangeIndex = i + 1;
      const nextSinceDate =
        nextDateRangeIndex < dateRanges.length ? sortedDateRange[nextDateRangeIndex].since : null;
      if (
        !until.isSame(getMaxUntil()) &&
        !since.isSame(getMinSince()) &&
        nextSinceDate &&
        !until.add(1, 'days').isSame(nextSinceDate, 'days')
      ) {
        gapMessage.push(
          `${since.format('LL')}-${until.format('LL')}, ${nextSinceDate.format(
            'LL',
          )}-${sortedDateRange[nextDateRangeIndex].until.format('LL')}`,
        );
      }
    });
  }
  if (gapMessage.length) {
    return {
      status: ValidationStatus.WARNING,
      message: `WARNING: there are gaps between the following ranges: ${gapMessage.join(', ')}`,
    };
  }
  return VALIDATION_SUCCESS;
};

export const validateDateRanges = (dateRanges: ParentPlayerDateRange[]) => {
  const dateRangesOverlaps = checkOverlaps(dateRanges);
  // if there is an overlap error we don't want to check gaps in the same time
  // because if there is an overlap there is always a gap.
  const dateRangesGaps =
    dateRangesOverlaps.status === ValidationStatus.SUCCESS
      ? checkGaps(dateRanges)
      : VALIDATION_SUCCESS;
  return { dateRangesOverlaps, dateRangesGaps };
};

export const validatePlayerEdit = (
  player: PlayerEdit,
  parentPlayerDateRanges: ParentPlayerDateRange[],
): ValidatePlayerEdit => {
  const name = validateRequired(player.name);
  const email = validateEmailFormat(player.email);
  const { dateRangesOverlaps, dateRangesGaps } = validateDateRanges(parentPlayerDateRanges);
  return { name, email, dateRangesOverlaps, dateRangesGaps };
};

export const isFormValid = (
  playerForm: ValidatePlayerEdit,
  ranges: ParentPlayerDateRange[],
): boolean => {
  const validationStatuses = [...Object.values(playerForm), ...ranges];
  return validationStatuses.every(({ status }) => status === ValidationStatus.SUCCESS);
};

export const formatPostPutPlayer = (parentsPlayerByDateRange: ParentPlayerDateRange[]) =>
  parentsPlayerByDateRange.flatMap(({ parents, since, until }) =>
    parents.map(parent => ({ ...parent, since, until })),
  );

export const getNextSinceDate = (parentPlayersByDateRanges: ParentPlayerDateRange[]) => {
  const maxUntilDate = moment.max(
    parentPlayersByDateRanges.map(({ until }) => until ?? getMaxUntil()),
  );

  if (parentPlayersByDateRanges.length) {
    if (maxUntilDate.isSame(getMaxUntil())) {
      const index = parentPlayersByDateRanges.findIndex(x => x.until.isSame(getMaxUntil()));

      const newUntil = moment.utc();
      return {
        nextSinceDate: newUntil.clone().add(1, 'days'),
        parentPlayersDateRangeToUpdate: {
          index,
          parentPlayersDateRange: parentPlayersByDateRanges[index],
          until: newUntil,
        },
      };
    }
    return {
      nextSinceDate: maxUntilDate.clone().add(1, 'days'),
      parentPlayersDateRangeToUpdate: null,
    };
  }

  return { nextSinceDate: getMinSince(), parentPlayersDateRangeToUpdate: null };
};
