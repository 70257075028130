import { assertNever, isArray, capitalizeFirst } from '@kpler/generic-utils';
import { BetaStatus, CargoState, Speed, VesselStatus, YesNo } from '@kpler/terminal-graphql';
import {
  Granularity,
  CURRENT_PRESET_LABELS,
  formatDateRange,
  PRESET_LABELS,
} from '@kpler/terminal-utils';
import { Market } from '@kpler/web-ui';

import { WORLD_ZONE, WORLD_ZONE_BASE } from 'src/constants/zone.constants';
import { SLIDER_INFINITE_BOUND } from 'src/domains/cargo/fleetMetrics/fleetMetrics.constants';
import {
  FleetMetricsAlgo,
  FleetMetricsSplit,
} from 'src/domains/cargo/fleetMetrics/fleetMetrics.types';
import { FlowsDirection, FlowsProjection, FlowsSplit } from 'src/domains/cargo/flows/flows.types';

import { granularityKeyMap } from 'src/main/analytics/prices/prices.helper';

import type {
  MapInstallationFiltersInput,
  MapVesselFiltersInput,
  PricesContractFilter,
} from '@kpler/terminal-graphql';
import type { FleetMetricsStateHydrated } from 'src/domains/cargo/fleetMetrics/fleetMetrics.types';
import type { FlowsStateHydrated } from 'src/domains/cargo/flows/flows.types';
import type { InventoriesMarket } from 'src/domains/onshore-asset-monitoring/inventories/useInventoriesContext';
import type { BallastCapacityStateHydrated } from 'types/ballastCapacity';
import { BallastCapacitySplit, BallastCapacityAggMetric } from 'types/ballastCapacity';
import type { CongestionVesselOperation, CongestionStateHydrated } from 'types/congestion';
import { CongestionSplit, CongestionMetric } from 'types/congestion';
import type { FleetDevelopmentStateHydrated } from 'types/fleetDevelopment';
import {
  FleetDevelopmentSplit,
  FleetDevelopmentMetric,
  FleetDevelopmentAggMetric,
} from 'types/fleetDevelopment';
import type {
  FleetUtilizationVesselState,
  FleetUtilizationStateHydrated,
} from 'types/fleetUtilization';
import { FleetUtilizationSplit, FleetUtilizationAggMetric } from 'types/fleetUtilization';
import type { FreightMetricsStateHydrated } from 'types/freightMetrics';
import {
  FreightMetricsSplit,
  FreightMetricsMetric,
  FreightMetricsVesselState,
} from 'types/freightMetrics';
import type { InstallationWithResourceType } from 'types/installation';
import type { InventoriesStateHydrated } from 'types/inventories';
import { InventoriesSplit } from 'types/inventories';
import type { PricesBookmarkFilterState } from 'types/prices';
import { RefineryCapacitiesMetric, RefineryCapacitiesSplit } from 'types/refineries';
import type { ZoneWithResourceType } from 'types/zone';

type LocationName = { name: string };

type LoadsAndDischarges = {
  loads: readonly LocationName[];
  discharges: readonly LocationName[];
};

export const BOOKMARK_TITLE_VAR_CHAR_LIMIT = 250;

export const TRUNCATE_AFTER = 3;

export const createTitleFragment = (items: ReadonlyArray<{ name: string }>): string => {
  const shouldTruncate = items.length > TRUNCATE_AFTER;
  const itemsTruncated = shouldTruncate ? items.slice(0, TRUNCATE_AFTER) : items;
  let str = itemsTruncated.map(x => x.name).join(', ');
  if (shouldTruncate) {
    str = `${str} (& ${items.length - TRUNCATE_AFTER} others)`;
  }
  return str;
};

export const createFlowsTitleFragmentWithExclusions = (
  items: ReadonlyArray<{ name: string; isExcluded?: boolean }>,
): string => {
  const includedItems = items.filter(item => !item.isExcluded);
  const excludedItems = items.filter(item => item.isExcluded);

  if (excludedItems.length === 0) {
    return createTitleFragment(includedItems);
  }

  const includedItemsStr = includedItems.length > 0 ? createTitleFragment(includedItems) : 'World';
  const excludedItemsStr = createTitleFragment(excludedItems);

  return `${includedItemsStr} (excl. ${excludedItemsStr})`;
};

const getLocationFragment = (locations: readonly LocationName[] | LoadsAndDischarges) => {
  if (isArray(locations)) {
    return createTitleFragment(locations);
  }

  const loadNames = createTitleFragment(locations.loads);
  const dischargeNames = createTitleFragment(locations.discharges);

  if (loadNames.length && !dischargeNames.length) {
    return `${loadNames} exports`;
  }
  if (!loadNames.length && dischargeNames.length) {
    return `${dischargeNames} imports`;
  }
  return `${loadNames} to ${dischargeNames}`;
};

const getOtherSearchFragments = (
  vessels: ReadonlyArray<{ name: string }>,
  products: ReadonlyArray<{ name: string }>,
  players: ReadonlyArray<{ name: string }>,
) => {
  const productNames = createTitleFragment(products);
  const playerNames = createTitleFragment(players);
  const vesselNames = createTitleFragment(vessels);

  return [productNames, playerNames, vesselNames].filter(x => x).join(' | ');
};

export const computeMapSearchTitle = (
  locations: readonly LocationName[] | LoadsAndDischarges,
  vessels: ReadonlyArray<{ name: string }> = [],
  products: ReadonlyArray<{ name: string }> = [],
  players: ReadonlyArray<{ name: string }> = [],
): string => {
  const locationFragment = getLocationFragment(locations);
  const otherSearchFragments = getOtherSearchFragments(vessels, products, players);

  if (locationFragment.length) {
    return otherSearchFragments.length === 0
      ? locationFragment
      : `${locationFragment}: ${otherSearchFragments}`;
  }
  return otherSearchFragments;
};

const getSpeedFragment = (speed?: readonly Speed[] | null) => {
  if (speed?.length !== 1) {
    return null;
  }
  const speedMap = {
    [Speed.Moving]: 'moving vessels',
    [Speed.Stopped]: 'stopped vessels',
  };
  return speedMap[speed[0]];
};

const getCargoStateFragment = (cargoState?: readonly CargoState[] | null) => {
  if (cargoState?.length !== 1) {
    return null;
  }
  const cargoStateMap = {
    [CargoState.Loaded]: 'loaded',
    [CargoState.Ballast]: 'ballast',
  };
  return cargoStateMap[cargoState[0]];
};

const getCapableFragment = (description: string, capable?: readonly YesNo[] | null) => {
  if (capable?.length !== 1) {
    return null;
  }
  const capableMap = {
    [YesNo.Yes]: `${description} capable`,
    [YesNo.No]: `excluding ${description} capable`,
  };
  return capableMap[capable[0]];
};

const getClassificationFragment = (
  classification?: readonly string[] | null,
  classificationOil?: readonly string[] | null,
  classificationCpp?: readonly string[] | null,
) =>
  [...(classification ?? []), ...(classificationOil ?? []), ...(classificationCpp ?? [])].join(
    ', ',
  );

const getBetaFragment = (beta?: readonly BetaStatus[] | null) => {
  if (beta?.length !== 1) {
    return null;
  }
  const betaMap = {
    [BetaStatus.Beta]: 'beta',
    [BetaStatus.Regular]: 'excluding beta',
  };
  return betaMap[beta[0]];
};

const getStringFragment = (stringList?: readonly string[] | null) => {
  if (stringList == null || stringList.length === 0) {
    return null;
  }
  return stringList.join(', ');
};

const getCapacityFragment = (capacity?: readonly number[] | null) =>
  capacity ? `${capacity[0]}-${capacity[1]} DWT` : null;

const getVesselStatusFragment = (status?: readonly VesselStatus[] | null) => {
  if (status == null || status.length === 0) {
    return null;
  }
  const statusMap = {
    [VesselStatus.InService]: 'in service',
    [VesselStatus.FloatingStorage]: 'floating storage',
    [VesselStatus.Inactive]: 'inactive',
    [VesselStatus.LaidUp]: 'laid up',
    [VesselStatus.Open]: 'open',
    [VesselStatus.UnderConstruction]: 'construction',
  };
  return status.map(x => statusMap[x]).join(', ');
};

const getVesselFilterFragments = (filters: MapVesselFiltersInput) => {
  const asphaltBitumenCapable = getCapableFragment(
    'asphalt/bitumen',
    filters.asphaltBitumenCapable,
  );
  const beta = getBetaFragment(filters.betaStatus);
  const buildYear = filters.buildYear
    ? `built ${filters.buildYear[0]}-${filters.buildYear[1]}`
    : null;
  const carrierTypeFragment = getStringFragment(filters.carrierType);
  const capacityFragment = getCapacityFragment(filters.capacity);
  const cargoTypeFragment = getStringFragment(filters.cargoType);
  const cargoStateFragment = getCargoStateFragment(filters.cargoState);
  const classification = getClassificationFragment(
    filters.classification,
    filters.classificationOil,
    filters.classificationCpp,
  );
  const draught = filters.draught ? `${filters.draught[0]}-${filters.draught[1]}m draught` : null;
  const engineFragment = getStringFragment(filters.engine);
  const ethyleneCapable = getCapableFragment('ethylene', filters.ethyleneCapable);
  const speed = getSpeedFragment(filters.speed);
  const statusFragment = getVesselStatusFragment(filters.status);

  return [
    speed,
    statusFragment,
    cargoStateFragment,
    classification,
    ethyleneCapable,
    asphaltBitumenCapable,
    beta,
    draught,
    capacityFragment,
    buildYear,
    carrierTypeFragment,
    engineFragment,
    cargoTypeFragment,
  ]
    .filter(x => x)
    .join(' | ');
};

const getInstallationFilterFragments = (filters: MapInstallationFiltersInput) => {
  const typeFragment = getStringFragment(filters.type);
  const statusFragment = getStringFragment(filters.status);

  const statusAndType = [statusFragment, typeFragment]
    .filter(x => x)
    .join(' ')
    .toLowerCase();

  let betaStatusFragment: string | null = null;
  if (filters.betaStatus && filters.betaStatus.length === 1) {
    if (filters.betaStatus[0] === BetaStatus.Regular) {
      betaStatusFragment =
        statusAndType.length > 0 ? 'excluding beta' : 'excluding beta installations';
    } else {
      betaStatusFragment = statusAndType.length > 0 ? 'beta only' : 'beta installations only';
    }
  }

  if (statusAndType.length > 0) {
    return [`${statusAndType} installations`, betaStatusFragment].filter(x => x).join(', ');
  }
  return betaStatusFragment;
};

export const computeMapFullTitle = (
  locations: readonly LocationName[] | LoadsAndDischarges,
  vessels: ReadonlyArray<{ name: string }>,
  products: ReadonlyArray<{ name: string }>,
  players: ReadonlyArray<{ name: string }>,
  vesselFilters: MapVesselFiltersInput,
  installationFilters: MapInstallationFiltersInput,
) => {
  const locationFragment = getLocationFragment(locations);
  const otherSearchFragments = getOtherSearchFragments(vessels, products, players);

  const vesselFilterFragments = getVesselFilterFragments(vesselFilters);
  const installationFilterFragments = getInstallationFilterFragments(installationFilters);

  const joinedFragments = [otherSearchFragments, vesselFilterFragments, installationFilterFragments]
    .filter(x => x)
    .join(' | ');

  if (locationFragment.length > 0) {
    return joinedFragments.length === 0
      ? locationFragment
      : `${locationFragment}: ${joinedFragments}`.substring(0, BOOKMARK_TITLE_VAR_CHAR_LIMIT);
  }

  return joinedFragments.length > 0
    ? capitalizeFirst(joinedFragments).substring(0, BOOKMARK_TITLE_VAR_CHAR_LIMIT)
    : 'All vessels';
};

const granularityWordMap: { [key in Granularity]: string } = {
  [Granularity.DAYS]: 'day',
  [Granularity.WEEKS]: 'week',
  [Granularity.EIAS]: 'EIA week',
  [Granularity.MID_WEEKS]: 'midweek',
  [Granularity.MONTHS]: 'month',
  [Granularity.QUARTERS]: 'quarter',
  [Granularity.YEARS]: 'year',
};

const periodicityWordMap: { [key in Granularity]: string } = {
  [Granularity.DAYS]: 'daily',
  [Granularity.WEEKS]: 'weekly',
  [Granularity.EIAS]: 'EIA weekly',
  [Granularity.MID_WEEKS]: 'midweek',
  [Granularity.MONTHS]: 'monthly',
  [Granularity.QUARTERS]: 'quarterly',
  [Granularity.YEARS]: 'annual',
};

const flowsDirectionWordMap: { [key in FlowsDirection]: string } = {
  [FlowsDirection.IMPORT]: 'imports',
  [FlowsDirection.EXPORT]: 'exports',
  [FlowsDirection.NET_EXPORT]: 'net exports',
};

const fleetDevelopmentWordMap: { [key in FleetDevelopmentMetric]: string } = {
  [FleetDevelopmentMetric.AVAILABLE]: 'available',
  [FleetDevelopmentMetric.DELIVERIES]: 'deliveries',
  [FleetDevelopmentMetric.SCRAPPING]: 'scrapping',
  [FleetDevelopmentMetric.CONTRACTING]: 'contracting',
};

const fleetDevelopmentAggMetricWordMap: { [key in FleetDevelopmentAggMetric]: string } = {
  [FleetDevelopmentAggMetric.COUNT]: 'count',
  [FleetDevelopmentAggMetric.DEADWEIGHT]: 'DWT',
  [FleetDevelopmentAggMetric.CAPACITY]: 'capacity',
};

const flowsSplitWordMap: { [key in FlowsSplit]: string } = {
  [FlowsSplit.TOTAL]: '',
  [FlowsSplit.COMMODITY]: 'product',
  [FlowsSplit.GRADE]: 'grade',
  [FlowsSplit.CRUDE_QUALITY]: 'crude quality',
  [FlowsSplit.CARGO_TYPE]: 'cargo type',
  [FlowsSplit.DESTINATION_TRADING_REGION]: 'destination trading region',
  [FlowsSplit.DESTINATION_CONTINENT]: 'destination continent',
  [FlowsSplit.DESTINATION_SUBCONTINENT]: 'destination subcontinent',
  [FlowsSplit.DESTINATION_COUNTRY]: 'destination country',
  [FlowsSplit.DESTINATION_INSTALLATION]: 'destination installation',
  [FlowsSplit.DESTINATION_PORT]: 'destination port',
  [FlowsSplit.DESTINATION_PADD]: 'destination PADD',
  [FlowsSplit.ORIGIN_TRADING_REGION]: 'origin trading region',
  [FlowsSplit.ORIGIN_CONTINENT]: 'origin continent',
  [FlowsSplit.ORIGIN_SUBCONTINENT]: 'origin subcontinent',
  [FlowsSplit.ORIGIN_COUNTRY]: 'origin country',
  [FlowsSplit.ORIGIN_INSTALLATION]: 'origin installation',
  [FlowsSplit.ORIGIN_PORT]: 'origin port',
  [FlowsSplit.ORIGIN_PADD]: 'origin PADD',
  [FlowsSplit.VESSEL_TYPE]: 'vessel type',
  [FlowsSplit.LONG_HAUL_VESSEL_TYPE]: 'long-haul vessel type',
  [FlowsSplit.TRADE_STATUS]: 'trade status',
  [FlowsSplit.TRADE_TYPE]: 'trade type',
  [FlowsSplit.SOURCE_ETA]: 'source',
  [FlowsSplit.CHARTERER]: 'charterer',
  [FlowsSplit.ROUTE]: 'route',
  [FlowsSplit.BUYERS]: 'buyers',
  [FlowsSplit.SELLERS]: 'sellers',
};

const freightMetricsSplitWordMap: {
  [key in FreightMetricsSplit]: string;
} = {
  [FreightMetricsSplit.TOTAL]: '',
  [FreightMetricsSplit.COMMODITY]: 'commodity',
  [FreightMetricsSplit.DESTINATION_COUNTRY]: 'destination country',
  [FreightMetricsSplit.DESTINATION_INSTALLATION]: 'destination installation',
  [FreightMetricsSplit.DESTINATION_TRADING_REGION]: 'destination trading region',
  [FreightMetricsSplit.DESTINATION_PADD]: 'destination PADD',
  [FreightMetricsSplit.ORIGIN_COUNTRY]: 'origin country',
  [FreightMetricsSplit.ORIGIN_INSTALLATION]: 'origin installation',
  [FreightMetricsSplit.ORIGIN_TRADING_REGION]: 'origin trading region',
  [FreightMetricsSplit.ORIGIN_PADD]: 'origin PADD',
  [FreightMetricsSplit.VESSEL_TYPE]: 'vessel type',
  [FreightMetricsSplit.VESSEL_STATE]: 'vessel state',
  [FreightMetricsSplit.ORIGIN_SOURCE_ETA]: 'origin source',
  [FreightMetricsSplit.DESTINATION_SOURCE_ETA]: 'destination source',
  [FreightMetricsSplit.ENGINE_TYPE]: 'engine type',
};

const ballastCapacitySplitWordMap: {
  [key in BallastCapacitySplit]: string;
} = {
  [BallastCapacitySplit.TOTAL]: '',
  [BallastCapacitySplit.VESSEL_TYPE]: 'vessel type',
  [BallastCapacitySplit.COUNTRY]: 'country',
  [BallastCapacitySplit.SOURCE]: 'source',
  [BallastCapacitySplit.ETA_WINDOW]: 'Eta Window',
  [BallastCapacitySplit.VESSEL_AVAILABILITY]: 'vessel availability',
  [BallastCapacitySplit.ENGINE_TYPE]: 'engine type',
};

const refineryCapacitySplitWordMap: {
  [key in RefineryCapacitiesSplit]: string;
} = {
  [RefineryCapacitiesSplit.TOTAL]: '',
  [RefineryCapacitiesSplit.E_STATUS]: 'event status',
  [RefineryCapacitiesSplit.E_CAUSE]: 'event cause',
  [RefineryCapacitiesSplit.EVENT_TYPE]: 'event type',
};

const fleetUtilizationSplitWordMap: {
  [key in FleetUtilizationSplit]: string;
} = {
  [FleetUtilizationSplit.TOTAL]: '',
  [FleetUtilizationSplit.VESSEL_TYPE]: 'vessel type',
  [FleetUtilizationSplit.VESSEL_STATE]: 'vessel state',
  [FleetUtilizationSplit.VESSEL_SUB_STATE]: 'vessel state',
  [FleetUtilizationSplit.VESSEL_DIRECTION]: 'vessel direction',
  [FleetUtilizationSplit.PRODUCT]: 'product',
  [FleetUtilizationSplit.CURRENT_SUBREGIONS]: 'current subregions',
  [FleetUtilizationSplit.CURRENT_CONTINENTS]: 'current continents',
  [FleetUtilizationSplit.CURRENT_SUBCONTINENTS]: 'current subcontinents',
  [FleetUtilizationSplit.CURRENT_COUNTRIES]: 'current countries',
  [FleetUtilizationSplit.CURRENT_SEAS]: 'current seas',
  [FleetUtilizationSplit.PREVIOUS_TRADING_REGIONS]: 'previous trading regions',
  [FleetUtilizationSplit.PREVIOUS_CONTINENTS]: 'previous continents',
  [FleetUtilizationSplit.PREVIOUS_SUBCONTINENTS]: 'previous subcontinents',
  [FleetUtilizationSplit.PREVIOUS_COUNTRIES]: 'previous countries',
  [FleetUtilizationSplit.PREVIOUS_PORTS]: 'previous ports',
  [FleetUtilizationSplit.NEXT_TRADING_REGIONS]: 'next trading regions',
  [FleetUtilizationSplit.NEXT_CONTINENTS]: 'next continents',
  [FleetUtilizationSplit.NEXT_SUBCONTINENTS]: 'next subcontinents',
  [FleetUtilizationSplit.NEXT_COUNTRIES]: 'next countries',
  [FleetUtilizationSplit.NEXT_PORTS]: 'next ports',
  [FleetUtilizationSplit.ENGINE_TYPE]: 'engine type',
};

const fleetDevelopmentSplitWordMap: {
  [key in FleetDevelopmentSplit]: string;
} = {
  [FleetDevelopmentSplit.TOTAL]: '',
  [FleetDevelopmentSplit.VESSEL_TYPE]: 'vessel type',
  [FleetDevelopmentSplit.COMPLIANCE_METHOD]: 'compliance method',
  [FleetDevelopmentSplit.VESSEL_COATING]: 'vessel coating',
  [FleetDevelopmentSplit.ENGINE_TYPE]: 'engine type',
};

type FlowsTitleParams = {
  mainAreas: ReadonlyArray<{ name: string }>;
  flowDirection: FlowsDirection;
  secondaryAreas: ReadonlyArray<{ name: string }>;
  split: FlowsSplit;
  products: ReadonlyArray<{ name: string }>;
  granularity?: Granularity;
  viaRoute: ReadonlyArray<{ name: string }>;
};

export const computeFlowsTitle = ({
  mainAreas,
  flowDirection,
  secondaryAreas,
  split,
  products,
  granularity,
  viaRoute,
}: FlowsTitleParams): string => {
  const mainNames =
    mainAreas.length > 0 ? createFlowsTitleFragmentWithExclusions(mainAreas) : 'World';
  const secondaryNames = createFlowsTitleFragmentWithExclusions(secondaryAreas);
  const productNames = createTitleFragment(products);
  const viaRouteNames = createTitleFragment(viaRoute);
  const via = viaRouteNames.length ? `via ${viaRouteNames}` : '';

  let toSecondary = '';
  if (secondaryNames.length) {
    if (flowDirection === FlowsDirection.IMPORT) {
      toSecondary = `from ${secondaryNames}`;
    } else if (flowDirection === FlowsDirection.EXPORT) {
      toSecondary = `to ${secondaryNames}`;
    } else if (flowDirection === FlowsDirection.NET_EXPORT) {
      toSecondary = `to ${secondaryNames}`;
    }
  }

  const formattedSplit =
    split && split !== FlowsSplit.TOTAL ? `by ${flowsSplitWordMap[split]}` : '';

  let parentheses = '';
  if (formattedSplit.length || productNames.length) {
    parentheses =
      formattedSplit.length && productNames.length
        ? `(${productNames}, ${formattedSplit})`
        : `(${productNames}${formattedSplit})`;
  }

  const titleElements = [
    mainNames,
    granularity ? periodicityWordMap[granularity] : '',
    flowsDirectionWordMap[flowDirection],
    toSecondary,
    via,
    parentheses,
  ].filter(x => x.length);
  return capitalizeFirst(titleElements.join(' '));
};

export const computeFlowsSubtitle = (
  projection: FlowsProjection,
  granularity: Granularity,
): string => {
  if (projection === FlowsProjection.PREDICTIVE) {
    return `Current and future flows averaged on full ${granularityWordMap[granularity]} accounting all expected future cargoes`;
  }
  return `Average ${granularityWordMap[granularity]}-to-date flow for the current ${granularityWordMap[granularity]}`;
};

export const computeFlowsWidgetTitle = ({
  mainAreas,
  flowDirection,
  secondaryAreas,
  split,
  products,
  viaRoute,
}: FlowsTitleParams): string =>
  computeFlowsTitle({
    mainAreas: mainAreas.length === 0 ? [WORLD_ZONE] : mainAreas,
    flowDirection,
    secondaryAreas,
    split,
    products,
    viaRoute,
  });

type FreightMetricsTitleParams = {
  mainZones: ReadonlyArray<{ name: string }>;
  metric: FreightMetricsMetric;
  secondaryZones: ReadonlyArray<{ name: string }>;
  split: FreightMetricsSplit;
  products: ReadonlyArray<{ name: string }>;
  granularity?: Granularity;
  vesselStates: readonly FreightMetricsVesselState[] | null;
};

const freightMetricsWordMap: { [key in FreightMetricsMetric]: string } = {
  [FreightMetricsMetric.TON_MILES]: 'ton-miles',
  [FreightMetricsMetric.TON_DAYS]: 'ton-days',
  [FreightMetricsMetric.AVG_SPEED]: 'speed',
  [FreightMetricsMetric.AVG_DISTANCE]: 'distance',
};

const freightMetricVesselStatesName = (
  vesselStates: readonly FreightMetricsVesselState[],
): string => {
  if (vesselStates.length === 1 && vesselStates[0] === FreightMetricsVesselState.LOADED) {
    return 'loaded';
  }

  if (vesselStates.length === 1 && vesselStates[0] === FreightMetricsVesselState.BALLAST) {
    return 'ballast';
  }

  return 'overall';
};

const freightMetricsMetricTitle = (
  metric: FreightMetricsMetric,
  vesselStates: readonly FreightMetricsVesselState[],
): string => {
  if (metric === FreightMetricsMetric.TON_MILES || metric === FreightMetricsMetric.TON_DAYS) {
    return freightMetricsWordMap[metric];
  }

  return `average ${freightMetricVesselStatesName(vesselStates)} ${freightMetricsWordMap[metric]}`;
};

export const computeFreightMetricsTitle = ({
  mainZones,
  granularity,
  metric,
  secondaryZones,
  split,
  products,
  vesselStates,
}: FreightMetricsTitleParams): string => {
  const mainNames = mainZones.length > 0 ? createTitleFragment(mainZones) : 'World';
  const secondaryNames = createTitleFragment(secondaryZones);
  const productNames = createTitleFragment(products);

  let toSecondary = '';
  if (secondaryNames.length) {
    toSecondary = `to ${secondaryNames}`;
  }

  const formattedSplit =
    split && split !== FreightMetricsSplit.TOTAL ? `by ${freightMetricsSplitWordMap[split]}` : '';

  let parentheses = '';
  if (formattedSplit.length || productNames.length) {
    parentheses =
      formattedSplit.length && productNames.length
        ? `(${productNames}, ${formattedSplit})`
        : `(${productNames}${formattedSplit})`;
  }

  const titleElements = [
    mainNames,
    granularity ? periodicityWordMap[granularity] : '',
    freightMetricsMetricTitle(metric, vesselStates || []),
    toSecondary,
    parentheses,
  ].filter(x => x.length);
  return capitalizeFirst(titleElements.join(' '));
};

export const computeFreightMetricsSubtitle = (
  granularity: Granularity,
  metric: FreightMetricsMetric,
): string => {
  let metricPrefix = '';
  let metricSuffix = '';

  if (metric === FreightMetricsMetric.TON_MILES) {
    [metricPrefix, metricSuffix] = [
      'current ton-miles aggregated',
      '<br/>Mtm: million (10<sup>6</sup>) ton-miles',
    ];
  } else if (metric === FreightMetricsMetric.TON_DAYS) {
    [metricPrefix, metricSuffix] = [
      'current ton-days aggregated',
      '<br/>Ktd: kilo (10<sup>3</sup>) ton-days',
    ];
  } else if (metric === FreightMetricsMetric.AVG_SPEED) {
    [metricPrefix, metricSuffix] = [
      'average speed of laden vessels averaged',
      '<br/>kn: knots; unit for vessel speed',
    ];
  } else if (metric === FreightMetricsMetric.AVG_DISTANCE) {
    [metricPrefix, metricSuffix] = [
      'average distance travelled by laden vessels averaged',
      '<br/>nmi: nautical miles; unit for distance',
    ];
  } else {
    assertNever(metric);
  }
  return capitalizeFirst(
    `${metricPrefix} on full ${granularityWordMap[granularity]}${metricSuffix}`,
  );
};

export const computeFreightMetricsWidgetTitle = ({
  mainZones,
  metric,
  secondaryZones,
  split,
  products,
  vesselStates,
}: FreightMetricsTitleParams): string =>
  computeFreightMetricsTitle({
    mainZones: mainZones.length === 0 ? [WORLD_ZONE] : mainZones,
    metric,
    secondaryZones,
    split,
    products,
    vesselStates,
  });

type RefineryCapacityTitleParams = {
  areas: ReadonlyArray<InstallationWithResourceType | ZoneWithResourceType>;
  splitBy: RefineryCapacitiesSplit;
  metric: RefineryCapacitiesMetric;
  granularity?: Granularity;
};

const refineryCapacityMetricWordMap: { [key in RefineryCapacitiesMetric]: string } = {
  [RefineryCapacitiesMetric.EVENTS]: 'offline capacity',
  [RefineryCapacitiesMetric.CAPACITY]: 'available capacity',
};

export const computeRefineryCapacitiesTitle = ({
  areas,
  splitBy,
  metric,
  granularity,
}: RefineryCapacityTitleParams): string => {
  const locationNames = areas.length > 0 ? createTitleFragment(areas) : 'World';
  const metricName = refineryCapacityMetricWordMap[metric];
  const formattedSplit =
    splitBy &&
    splitBy !== RefineryCapacitiesSplit.TOTAL &&
    metric === RefineryCapacitiesMetric.EVENTS
      ? `by ${refineryCapacitySplitWordMap[splitBy]}`
      : '';

  let parentheses = '';
  if (formattedSplit.length) {
    parentheses = `(${formattedSplit})`;
  }

  const titleElements = [
    locationNames,
    granularity ? periodicityWordMap[granularity] : '',
    metricName,
    parentheses,
  ].filter(x => x.length);
  return capitalizeFirst(titleElements.join(' '));
};

export const computeRefineryCapacitiesSubtitle = () => 'Offline events per family type';

export const computeRefineryCapacitiesWidgetTitle = ({
  areas,
  splitBy,
  metric,
}: RefineryCapacityTitleParams) =>
  computeRefineryCapacitiesTitle({
    areas,
    splitBy,
    metric,
  });

type FleetDevelopmentTitleParams = {
  metric: FleetDevelopmentMetric;
  split: FleetDevelopmentSplit;
  aggMetric: FleetDevelopmentAggMetric;
  complianceMethod: readonly string[];
  granularity?: Granularity;
};

export const computeFleetDevelopmentTitle = ({
  metric,
  split,
  aggMetric,
  complianceMethod,
  granularity,
}: FleetDevelopmentTitleParams): string => {
  const formattedSplit =
    split && split !== FleetDevelopmentSplit.TOTAL
      ? `by ${fleetDevelopmentSplitWordMap[split]}`
      : '';
  const aggMetricNames = fleetDevelopmentAggMetricWordMap[aggMetric];
  const complianceNames = complianceMethod.map(x => x.toLowerCase()).join(', ');

  let parentheses = '';
  if (formattedSplit.length || complianceNames.length) {
    parentheses =
      formattedSplit.length && complianceNames.length
        ? `(${complianceNames}, ${formattedSplit})`
        : `(${complianceNames}${formattedSplit})`;
  }

  const titleElements = [
    granularity ? periodicityWordMap[granularity] : '',
    fleetDevelopmentWordMap[metric],
    'vessel',
    aggMetricNames,
    parentheses,
  ].filter(x => x.length);
  return capitalizeFirst(titleElements.join(' '));
};

export const computeFleetDevelopmentSubtitle = (
  granularity: Granularity,
  metric: FleetDevelopmentMetric,
  aggMetric: FleetDevelopmentAggMetric,
): string => {
  if (metric === FleetDevelopmentMetric.AVAILABLE) {
    if (aggMetric === FleetDevelopmentAggMetric.COUNT) {
      return `Number of active vessels, aggregated on full ${granularityWordMap[granularity]}`;
    }
    if (aggMetric === FleetDevelopmentAggMetric.DEADWEIGHT) {
      return `Sum of active vessels deadweight, aggregated on full ${granularityWordMap[granularity]}`;
    }
    if (aggMetric === FleetDevelopmentAggMetric.CAPACITY) {
      return `Sum of active vessels capacity, aggregated on full ${granularityWordMap[granularity]}`;
    }
    return assertNever(aggMetric);
  }

  if (metric === FleetDevelopmentMetric.DELIVERIES) {
    if (aggMetric === FleetDevelopmentAggMetric.COUNT) {
      return `Number of delivered vessels, aggregated on full ${granularityWordMap[granularity]}`;
    }
    if (aggMetric === FleetDevelopmentAggMetric.DEADWEIGHT) {
      return `Sum of delivered vessels deadweight, aggregated on full ${granularityWordMap[granularity]}`;
    }
    if (aggMetric === FleetDevelopmentAggMetric.CAPACITY) {
      return `Sum of delivered vessels capacity, aggregated on full ${granularityWordMap[granularity]}`;
    }
    return assertNever(aggMetric);
  }

  if (metric === FleetDevelopmentMetric.SCRAPPING) {
    if (aggMetric === FleetDevelopmentAggMetric.COUNT) {
      return `Number of demolished vessels, aggregated on full ${granularityWordMap[granularity]}`;
    }
    if (aggMetric === FleetDevelopmentAggMetric.DEADWEIGHT) {
      return `Sum of demolished vessels deadweight, aggregated on full ${granularityWordMap[granularity]}`;
    }
    if (aggMetric === FleetDevelopmentAggMetric.CAPACITY) {
      return `Sum of demolished vessels capacity, aggregated on full ${granularityWordMap[granularity]}`;
    }
    return assertNever(aggMetric);
  }

  if (metric === FleetDevelopmentMetric.CONTRACTING) {
    if (aggMetric === FleetDevelopmentAggMetric.COUNT) {
      return `Number of vessel orders placed, aggregated on full ${granularityWordMap[granularity]}`;
    }
    if (aggMetric === FleetDevelopmentAggMetric.DEADWEIGHT) {
      return `Sum of ordered vessels deadweight, aggregated on full ${granularityWordMap[granularity]}`;
    }
    if (aggMetric === FleetDevelopmentAggMetric.CAPACITY) {
      return `Sum of ordered vessels capacity, aggregated on full ${granularityWordMap[granularity]}`;
    }
    return assertNever(aggMetric);
  }

  return assertNever(metric);
};

export const computeFleetDevelopmentWidgetTitle = ({
  metric,
  split,
  aggMetric,
  complianceMethod,
}: FleetDevelopmentTitleParams): string =>
  computeFleetDevelopmentTitle({ metric, split, aggMetric, complianceMethod });

const algoWordMap: { [key in FleetMetricsAlgo]: string } = {
  [FleetMetricsAlgo.FLOATING_STORAGE]: 'floating storage',
  [FleetMetricsAlgo.COMMODITY_ON_WATER]: 'Commodities on water',
};

const fleetMetricsSplitWordMap: { [key in FleetMetricsSplit]: string } = {
  [FleetMetricsSplit.TOTAL]: 'total',
  [FleetMetricsSplit.COMMODITY]: 'commodity',
  [FleetMetricsSplit.GRADE]: 'grade',
  [FleetMetricsSplit.CRUDE_QUALITY]: 'crude quality',
  [FleetMetricsSplit.DESTINATION_TRADING_REGION]: 'destination trading region',
  [FleetMetricsSplit.DESTINATION_CONTINENT]: 'destination continent',
  [FleetMetricsSplit.DESTINATION_SUBCONTINENT]: 'destination subcontinent',
  [FleetMetricsSplit.DESTINATION_COUNTRY]: 'destination country',
  [FleetMetricsSplit.DESTINATION_INSTALLATION]: 'destination installation',
  [FleetMetricsSplit.DESTINATION_PORT]: 'destination port',
  [FleetMetricsSplit.ORIGIN_TRADING_REGION]: 'origin trading region',
  [FleetMetricsSplit.ORIGIN_CONTINENT]: 'origin continent',
  [FleetMetricsSplit.ORIGIN_SUBCONTINENT]: 'origin subcontinent',
  [FleetMetricsSplit.ORIGIN_COUNTRY]: 'origin country',
  [FleetMetricsSplit.ORIGIN_INSTALLATION]: 'origin installation',
  [FleetMetricsSplit.ORIGIN_PORT]: 'origin port',
  [FleetMetricsSplit.VESSEL_TYPE]: 'vessel type',
  [FleetMetricsSplit.TRADE_STATUS]: 'trade status',
  [FleetMetricsSplit.CHARTERER]: 'charterer',
  [FleetMetricsSplit.BUYERS]: 'buyer',
  [FleetMetricsSplit.SELLERS]: 'seller',
  [FleetMetricsSplit.CURRENT_CONTINENTS]: 'current continent',
  [FleetMetricsSplit.CURRENT_SUBCONTINENTS]: 'current subcontinent',
  [FleetMetricsSplit.CURRENT_COUNTRIES]: 'current country',
  [FleetMetricsSplit.CURRENT_SUBREGIONS]: 'current subregion',
  [FleetMetricsSplit.CURRENT_SEAS]: 'current sea',
  [FleetMetricsSplit.FLOATING_DAYS]: 'floating day',
};

const stockWordMap: { [key in Granularity]: string } = {
  [Granularity.DAYS]: '(stock EOD)',
  [Granularity.WEEKS]: '(stock on Sundays EOD)',
  [Granularity.EIAS]: '(stock on Fridays SOD)',
  [Granularity.MID_WEEKS]: '(stock on Wednesdays EOD)',
  [Granularity.MONTHS]: '(stock on last day of month SOD)',
  [Granularity.QUARTERS]: '(stock quarterly)',
  [Granularity.YEARS]: '(stock on last day of year SOD)',
};

const algoSubtitleWordMap: { [key in FleetMetricsAlgo]: string } = {
  [FleetMetricsAlgo.COMMODITY_ON_WATER]: `Volume of commodities on tankers at sea`,
  [FleetMetricsAlgo.FLOATING_STORAGE]: '',
};

type FleetMetricsTitleParams = {
  areas: ReadonlyArray<{ name: string }>;
  algo: FleetMetricsAlgo;
  products: ReadonlyArray<{ name: string }>;
  split: FleetMetricsSplit;
  granularity?: Granularity;
};

export const computeFleetMetricsTitle = ({
  areas,
  algo,
  products,
  split,
  granularity,
}: FleetMetricsTitleParams): string => {
  const areasNames = createTitleFragment(areas);
  const productNames = createTitleFragment(products);

  const formattedSplit =
    split && split !== FleetMetricsSplit.TOTAL ? `by ${fleetMetricsSplitWordMap[split]}` : '';

  let parentheses = '';
  if (productNames.length || formattedSplit.length) {
    parentheses =
      productNames.length && formattedSplit.length
        ? `(${productNames}, ${formattedSplit})`
        : `(${productNames}${formattedSplit})`;
  }

  const titleElements = [
    areasNames,
    granularity ? periodicityWordMap[granularity] : '',
    algoWordMap[algo],
    parentheses,
  ].filter(x => x);
  return capitalizeFirst(titleElements.join(' '));
};

export const computeFleetMetricsSubtitle = (
  granularity: Granularity,
  algo: FleetMetricsAlgo,
  floatingDays: string[] | null,
): string => {
  const nonNullFloatingDays = floatingDays ?? ['0', SLIDER_INFINITE_BOUND];
  if (algo === FleetMetricsAlgo.FLOATING_STORAGE) {
    const idleDuration =
      nonNullFloatingDays[1] === SLIDER_INFINITE_BOUND
        ? `for ${nonNullFloatingDays[0]} or more days`
        : `between ${nonNullFloatingDays[0]} and ${nonNullFloatingDays[1]} days`;
    return `Volume of commodities on tankers that are idled offshore ${idleDuration} ${stockWordMap[granularity]}`;
  }

  return `${algoSubtitleWordMap[algo]} ${stockWordMap[granularity]}`;
};

export const computeFleetMetricsWidgetTitle = ({
  areas,
  algo,
  products,
  split,
}: FleetMetricsTitleParams): string => computeFleetMetricsTitle({ areas, algo, products, split });

export const inventoriesSplitWordMap: { [key in InventoriesSplit]: string } = {
  [InventoriesSplit.TOTAL]: '',
  [InventoriesSplit.INSTALLATION]: 'installation',
  [InventoriesSplit.TANK_TYPE]: 'tank type',
  [InventoriesSplit.COUNTRY]: 'country',
  [InventoriesSplit.PLAYER]: 'player',
  [InventoriesSplit.STATUS]: 'onshore/offshore status',
  [InventoriesSplit.TANK_STATUS]: 'tank status',
};

type InventoriesTitleParams = {
  areas: ReadonlyArray<{ name: string }>;
  split: InventoriesSplit;
  fleetMetricsEnhancement: boolean;
  granularity?: Granularity;
  market: Market;
};

export const computeInventoriesTitle = ({
  areas,
  split,
  fleetMetricsEnhancement,
  granularity,
  market,
}: InventoriesTitleParams): string => {
  const parentheses =
    split === InventoriesSplit.TOTAL ? '' : `(by ${inventoriesSplitWordMap[split]})`;
  const defaultAreasNames = market === Market.LIQUIDS ? 'World' : '';
  const areasNames = areas.length > 0 ? createTitleFragment(areas) : defaultAreasNames;
  const commoditiesOnWater = fleetMetricsEnhancement ? 'including commodities-on-water' : '';
  const periodicityElement = granularity ? periodicityWordMap[granularity] : '';
  return capitalizeFirst(
    [areasNames, periodicityElement, 'inventories', commoditiesOnWater, parentheses]
      .filter(x => x)
      .join(' '),
  );
};

const inventoriesStockWordMap = (
  granularity: Granularity,
  droneData: boolean,
  split: InventoriesSplit,
): string => {
  if (droneData) {
    const datasetLabel = split === InventoriesSplit.TANK_STATUS ? 'Capacity' : 'Stocks';
    switch (granularity) {
      case Granularity.DAYS:
        return capitalizeFirst(`${datasetLabel} at midweek and end of week`);
      case Granularity.EIAS:
        return capitalizeFirst(`${datasetLabel} at end of week`);
      case Granularity.MID_WEEKS:
        return capitalizeFirst(`${datasetLabel} at midweek`);
      case Granularity.WEEKS:
      case Granularity.MONTHS:
      case Granularity.QUARTERS:
      case Granularity.YEARS:
        throw new Error(`Invalid granularity: ${granularity}`);
      default:
        return assertNever(granularity);
    }
  }
  switch (granularity) {
    case Granularity.DAYS:
      return 'End of day stocks';
    case Granularity.WEEKS:
      return 'End of week stocks';
    case Granularity.EIAS:
      return 'Stock on Fridays';
    case Granularity.MID_WEEKS:
      throw new Error(`Invalid granularity: ${granularity}`);
    case Granularity.MONTHS:
      return 'End of month stocks';
    case Granularity.QUARTERS:
      return 'End of quarter';
    case Granularity.YEARS:
      return 'End of year';
    default:
      return assertNever(granularity);
  }
};

export const computeInventoriesSubtitle = (
  granularity: Granularity,
  droneData: boolean,
  isLNG: boolean,
  formattedCapacity?: string,
  split = InventoriesSplit.TOTAL,
): string => {
  let subtitle = inventoriesStockWordMap(granularity, droneData, split);
  if (formattedCapacity !== undefined) {
    const commodity = isLNG ? 'LNG' : 'crude';
    subtitle = `${subtitle}. ${capitalizeFirst(`${commodity} capacity: ${formattedCapacity}`)}`;
  }
  return subtitle;
};
export const computeInventoriesWidgetTitle = ({
  areas,
  split,
  fleetMetricsEnhancement,
}: InventoriesTitleParams): string =>
  computeInventoriesTitle({
    areas: areas.length === 0 ? [WORLD_ZONE] : areas,
    split,
    fleetMetricsEnhancement,
    market: Market.LIQUIDS,
  });

const congestionSplitWordMap: { [key in CongestionSplit]: string } = {
  [CongestionSplit.TOTAL]: 'Total',
  [CongestionSplit.VESSEL_TYPE]: 'by vessel type',
  [CongestionSplit.PORT]: 'by port',
  [CongestionSplit.INSTALLATION]: 'by installation',
  [CongestionSplit.COUNTRY]: 'by country',
  [CongestionSplit.ORIGIN_PORT]: 'by origin port',
  [CongestionSplit.ORIGIN_COUNTRY]: 'by origin country',
  [CongestionSplit.VESSEL_OPERATION]: 'by vessel operation',
  [CongestionSplit.WAITING_STATUS]: 'by waiting status',
  [CongestionSplit.PRODUCT]: 'by product',
  [CongestionSplit.GRADE]: 'by grade',
  [CongestionSplit.ENGINE_TYPE]: 'by engine type',
};

const congestionMetricWordMap: { [key in CongestionMetric]: string } = {
  [CongestionMetric.COUNT]: 'count',
  [CongestionMetric.DURATION]: 'duration',
  [CongestionMetric.DEADWEIGHT]: 'DWT',
  [CongestionMetric.CAPACITY]: 'capacity',
};

type CongestionTitleParams = {
  zones: ReadonlyArray<{ name: string }>;
  split: CongestionSplit;
  metric: CongestionMetric;
  vesselOperation: readonly CongestionVesselOperation[];
  products: ReadonlyArray<{ name: string }>;
  granularity?: Granularity;
};

export const computeCongestionTitle = ({
  zones,
  split,
  metric,
  vesselOperation,
  products,
  granularity,
}: CongestionTitleParams): string => {
  const areasNames = zones.length > 0 ? createTitleFragment(zones) : 'World';
  const metricNames = congestionMetricWordMap[metric];
  const productNames = products.length > 0 ? [createTitleFragment(products)] : [];
  const vesselOperationNames = vesselOperation.map(x => x.toLowerCase());
  const formattedSplit =
    split === CongestionSplit.TOTAL ? [] : [`${congestionSplitWordMap[split]}`];
  const paranthesesContent = [...productNames, ...vesselOperationNames, ...formattedSplit];
  const parentheses = paranthesesContent.length > 0 ? `(${paranthesesContent.join(', ')})` : '';

  const titleElements = [
    areasNames,
    granularity ? periodicityWordMap[granularity] : '',
    'congestion',
    metricNames,
    parentheses,
  ].filter(x => x);
  return capitalizeFirst(titleElements.join(' '));
};

const congestionMetricSubtitleWordMap: { [key in CongestionMetric]: string } = {
  [CongestionMetric.COUNT]: 'Number of vessels waiting to go to berth',
  [CongestionMetric.DEADWEIGHT]: 'Sum of waiting vessels deadweight',
  [CongestionMetric.CAPACITY]: 'Sum of waiting vessels capacity',
  [CongestionMetric.DURATION]: 'Average waiting time (in days)',
};

export const computeCongestionSubtitle = (metric: CongestionMetric): string =>
  capitalizeFirst(congestionMetricSubtitleWordMap[metric]);

export const computeCongestionWidgetTitle = ({
  zones,
  split,
  metric,
  vesselOperation,
  products,
}: CongestionTitleParams): string =>
  computeCongestionTitle({
    zones: zones.length === 0 ? [WORLD_ZONE_BASE] : zones,
    split,
    metric,
    vesselOperation,
    products,
  });

const ballastCapacityMetricWordMap: { [key in BallastCapacityAggMetric]: string } = {
  [BallastCapacityAggMetric.COUNT]: 'count',
  [BallastCapacityAggMetric.DEADWEIGHT]: 'DWT',
  [BallastCapacityAggMetric.CAPACITY]: 'capacity',
};

type BallastCapacityTitleParams = {
  zones: ReadonlyArray<{ name: string }>;
  split: BallastCapacitySplit;
  aggMetric: BallastCapacityAggMetric;
  products: ReadonlyArray<{ name: string }>;
  granularity?: Granularity;
};

export const computeBallastCapacityTitle = ({
  zones,
  split,
  aggMetric,
  products,
  granularity,
}: BallastCapacityTitleParams): string => {
  const zoneNames = zones.length > 0 ? createTitleFragment(zones) : 'World';
  const metricNames = ballastCapacityMetricWordMap[aggMetric];
  const productNames = createTitleFragment(products);

  const formattedSplit =
    split && split !== BallastCapacitySplit.TOTAL ? `by ${ballastCapacitySplitWordMap[split]}` : '';

  let parentheses = '';
  if (formattedSplit.length || productNames.length) {
    parentheses =
      formattedSplit.length && productNames.length
        ? `(${productNames}, ${formattedSplit})`
        : `(${productNames}${formattedSplit})`;
  }

  const titleElements = [
    zoneNames,
    granularity ? periodicityWordMap[granularity] : '',
    'expected ballast capacity',
    metricNames,
    parentheses,
  ].filter(x => x);
  return capitalizeFirst(titleElements.join(' '));
};

export const computeBallastCapacitySubtitle = (aggMetric: BallastCapacityAggMetric): string =>
  aggMetric === BallastCapacityAggMetric.COUNT
    ? 'Number of expected ballast vessels aggregated basis future destination and ETA'
    : `Sum of expected ballast vessels deadweight aggregated basis future destination and ETA`;

export const computeBallastCapacityWidgetTitle = ({
  zones,
  split,
  aggMetric,
  products,
}: BallastCapacityTitleParams): string =>
  computeBallastCapacityTitle({ zones, split, aggMetric, products });

const fleetUtilizationMetricWordMap: { [key in FleetUtilizationAggMetric]: string } = {
  [FleetUtilizationAggMetric.COUNT]: 'count',
  [FleetUtilizationAggMetric.DEADWEIGHT]: 'DWT',
  [FleetUtilizationAggMetric.CAPACITY]: 'capacity',
};

type FleetUtilizationTitleParams = {
  zones: ReadonlyArray<{ name: string }>;
  split: FleetUtilizationSplit;
  aggMetric: FleetUtilizationAggMetric;
  percent: boolean;
  lastFamilyProducts: readonly string[];
  vesselState: readonly FleetUtilizationVesselState[];
  granularity?: Granularity;
};

export type DisclaimerInstallations = {
  [key: string]: string;
};

export const computeFleetUtilizationTitle = ({
  zones,
  split,
  aggMetric,
  percent,
  lastFamilyProducts,
  vesselState,
  granularity,
}: FleetUtilizationTitleParams): string => {
  const zoneNames = zones.length > 0 ? createTitleFragment(zones) : 'World';
  const metricNames = fleetUtilizationMetricWordMap[aggMetric];
  const vesselStateNames = vesselState.map(x => x.toLowerCase());
  const percentSymbol = percent ? '%' : '';

  const formattedSplit =
    split && split !== FleetUtilizationSplit.TOTAL
      ? [`by ${fleetUtilizationSplitWordMap[split]}`]
      : [];

  const paranthesesContent = [...vesselStateNames, ...lastFamilyProducts, ...formattedSplit];
  const parentheses = paranthesesContent.length > 0 ? `(${paranthesesContent.join(', ')})` : '';

  const titleElements = [
    zoneNames,
    granularity ? periodicityWordMap[granularity] : '',
    'vessel',
    metricNames,
    percentSymbol,
    parentheses,
  ].filter(x => x);
  return capitalizeFirst(titleElements.join(' '));
};

export const computeFleetUtilizationSubtitle = (
  aggMetric: FleetUtilizationAggMetric,
  granularity: Granularity,
  percent: boolean,
): string => {
  let aggMetricWord = '';
  if (percent) {
    return `Percentage of fleet utilized, aggregated on full ${granularityWordMap[granularity]}`;
  }
  if (aggMetric === FleetUtilizationAggMetric.CAPACITY) {
    aggMetricWord = 'Sum of vessels capacity';
  } else if (aggMetric === FleetUtilizationAggMetric.DEADWEIGHT) {
    aggMetricWord = 'Sum of vessels deadweight';
  } else {
    aggMetricWord = 'Number of vessels';
  }
  return `${aggMetricWord}, aggregated on full ${granularityWordMap[granularity]}`;
};

export const computeFleetUtilizationWidgetTitle = ({
  zones,
  split,
  aggMetric,
  percent,
  lastFamilyProducts,
  vesselState,
}: FleetUtilizationTitleParams): string =>
  computeFleetUtilizationTitle({
    zones,
    split,
    aggMetric,
    percent,
    lastFamilyProducts,
    vesselState,
  });

export const computeFixturesTitle = ({
  fromZones,
  toZones,
}: {
  fromZones: ReadonlyArray<{ name: string }>;
  toZones: ReadonlyArray<{ name: string }>;
}): string => {
  const fromZoneNames = fromZones.length > 0 ? createTitleFragment(fromZones) : 'World';
  const toZoneNames = toZones.length > 0 ? ` to ${createTitleFragment(toZones)}` : '';

  return `${fromZoneNames}${toZoneNames} fixtures`;
};

export const computeFixturesWidgetTitle = ({
  fromZones,
  toZones,
}: {
  fromZones: ReadonlyArray<{ name: string }>;
  toZones: ReadonlyArray<{ name: string }>;
}): string => {
  const fromZoneNames = fromZones.length > 0 ? createTitleFragment(fromZones) : 'World';
  const toZoneNames = toZones.length > 0 ? ` to ${createTitleFragment(toZones)}` : '';

  return `Latest ${fromZoneNames}${toZoneNames} fixtures`;
};

export const computePricesTitle = (
  contracts: PricesContractFilter[],
  granularity?: Granularity,
): string => {
  const granularityStr = granularity ? periodicityWordMap[granularity] : undefined;
  if (contracts.length === 0) {
    return capitalizeFirst([granularityStr, 'prices'].filter(x => x).join(' '));
  }
  const markets = [...new Set(contracts.map(contract => contract.market))].join(', ').toLowerCase();
  const titleElements = [
    granularityStr,
    markets,
    contracts[0].type?.toLowerCase(),
    'prices',
  ].filter(x => x);
  return capitalizeFirst(titleElements.join(' '));
};

export const computePricesWidgetTitle = (contracts: PricesContractFilter[]): string =>
  computePricesTitle(contracts);

export const computePricesSubtitle = (granularity: Granularity): string =>
  `Daily prices, averaged on full ${granularityWordMap[granularity]}`;

export const computeRefineriesTitle = ({
  areas,
}: {
  areas: Array<InstallationWithResourceType | ZoneWithResourceType>;
}): string => {
  const locations = areas.length > 0 ? createTitleFragment(areas) : 'World';
  return `${locations} events`;
};

export const computeFlowsSavedSearchTitle = (filters: FlowsStateHydrated) => {
  const presets =
    filters.projection === FlowsProjection.PREDICTIVE ? CURRENT_PRESET_LABELS : PRESET_LABELS;
  const dates = formatDateRange(filters.dateRange, presets, filters.seasonal, filters.granularity);
  const title = `${computeFlowsTitle(filters)} (${dates})`;
  return title.substring(0, BOOKMARK_TITLE_VAR_CHAR_LIMIT);
};

export const computeFleetMetricsSavedSearchTitle = (filters: FleetMetricsStateHydrated) => {
  const dates = formatDateRange(
    filters.dateRange,
    PRESET_LABELS,
    filters.seasonal,
    filters.granularity,
  );
  const title = `${computeFleetMetricsTitle(filters)} (${dates})`;
  return title.substring(0, BOOKMARK_TITLE_VAR_CHAR_LIMIT);
};

export const computeCongestionSavedSearchTitle = (filters: CongestionStateHydrated) => {
  const dates = formatDateRange(
    filters.dateRange,
    PRESET_LABELS,
    filters.seasonal,
    filters.granularity,
  );
  const title = `${computeCongestionTitle(filters)} (${dates})`;
  return title.substring(0, BOOKMARK_TITLE_VAR_CHAR_LIMIT);
};

export const computeFreightMetricsSavedSearchTitle = (filters: FreightMetricsStateHydrated) => {
  const dates = formatDateRange(
    filters.dateRange,
    PRESET_LABELS,
    filters.seasonal,
    filters.granularity,
  );
  const title = `${computeFreightMetricsTitle(filters)} (${dates})`;
  return title.substring(0, BOOKMARK_TITLE_VAR_CHAR_LIMIT);
};

export const computeFleetDevelopmentSavedSearchTitle = (filters: FleetDevelopmentStateHydrated) => {
  const dates = formatDateRange(
    filters.dateRange,
    PRESET_LABELS,
    filters.seasonal,
    filters.granularity,
  );
  const title = `${computeFleetDevelopmentTitle(filters)} (${dates})`;
  return title.substring(0, BOOKMARK_TITLE_VAR_CHAR_LIMIT);
};

export const computeBallastCapacitySavedSearchTitle = (filters: BallastCapacityStateHydrated) => {
  const dates = formatDateRange(filters.dateRange, PRESET_LABELS, false, filters.granularity);
  const title = `${computeBallastCapacityTitle(filters)} (${dates})`;
  return title.substring(0, BOOKMARK_TITLE_VAR_CHAR_LIMIT);
};

export const computeFleetUtilizationSavedSearchTitle = (filters: FleetUtilizationStateHydrated) => {
  const dates = formatDateRange(
    filters.dateRange,
    PRESET_LABELS,
    filters.seasonal,
    filters.granularity,
  );
  const title = `${computeFleetUtilizationTitle(filters)} (${dates})`;
  return title.substring(0, BOOKMARK_TITLE_VAR_CHAR_LIMIT);
};

export const computeInventoriesSavedSearchTitle = (
  filters: InventoriesStateHydrated,
  market: InventoriesMarket,
) => {
  const dates = formatDateRange(
    filters.dateRange,
    PRESET_LABELS,
    filters.seasonal,
    filters.granularity,
  );
  const title = `${computeInventoriesTitle({
    ...filters,
    market,
  })} (${dates})`;
  return title.substring(0, BOOKMARK_TITLE_VAR_CHAR_LIMIT);
};

export const computeMapSearchSavedSearchTitle = (
  locations: readonly LocationName[] | LoadsAndDischarges,
  vessels: ReadonlyArray<{ name: string }>,
  products: ReadonlyArray<{ name: string }>,
  players: ReadonlyArray<{ name: string }>,
) => {
  const title = computeMapSearchTitle(locations, vessels, products, players);
  return title.substring(0, BOOKMARK_TITLE_VAR_CHAR_LIMIT);
};

export const computeMapPageViewSavedSearchTitle = (itemName: string) =>
  itemName.substring(0, BOOKMARK_TITLE_VAR_CHAR_LIMIT);

export const computePricesSavedSearchTitle = (filters: PricesBookmarkFilterState) => {
  const granularity = granularityKeyMap[filters.granularity];
  const dates = formatDateRange(filters.dateRange, PRESET_LABELS, false, granularity);
  const title = `${computePricesTitle(filters.contracts, granularity)} (${dates})`;
  return title.substring(0, BOOKMARK_TITLE_VAR_CHAR_LIMIT);
};
