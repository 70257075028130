fragment installation on Installation {
  hasCargoTracking
  hasStorage
  id
  isInUnitedStates
  name
  iirName
  port {
    id
  }
  portCost
  type
  unlocodes
  commodityTypes
}

fragment installationSearch on InstallationSearch {
  highlight {
    field
    value
  }
  installation {
    ...installation
  }
  score
}

fragment installationWithCapacities on Installation {
  id
  name
  status
  fullName
  commodityTypes
  nominalAnnualCapacity {
    energy
    mass
    volume
    volume_gas: volumeGas
  }
  capacityHolders {
    holdingCapacity {
      energy
      mass
      volume
      volume_gas: volumeGas
    }
  }
}
