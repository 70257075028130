import { deserializeDate, serializeDate } from '@kpler/terminal-utils';

import { formatFloat } from 'src/helpers/formatNumber.helper';
import { getMinSince, getMaxUntil } from 'src/main/map/panels/player/playerEdit/playerEdit.helper';

import type { PlayerEdit, PlayerStaticData } from 'types/edits/player';
import { ResourceType } from 'types/legacy-globals';

export const formatFromStaticData = (player: PlayerStaticData): PlayerEdit => {
  const { directParents, ...other } = player;
  const parents = directParents
    .map(({ share, since, until, ...others }) => {
      const sinceDate = since ? deserializeDate(since) : null;
      const untilDate = until ? deserializeDate(until) : null;
      return {
        share: formatFloat((share ?? 0) * 100, 3),
        since: sinceDate,
        until: untilDate,
        ...others,
      };
    })
    .sort((a, b) => {
      const untilA = a.until ?? getMaxUntil();
      const untilB = b.until ?? getMaxUntil();
      return untilB.diff(untilA);
    });
  return { ...other, parents, resourceType: ResourceType.STATIC_PLAYER_EDIT };
};

export const formatToStaticData = (player: PlayerEdit): PlayerStaticData => {
  const { parents, ...other } = player;
  const directParents = parents.flatMap(({ share, ownerPlayer, since, until, ...others }) =>
    ownerPlayer
      ? [
          {
            share: formatFloat(share / 100, 5),
            ownerPlayer,
            since: since?.isSame(getMinSince()) || !since ? null : serializeDate(since),
            until: until?.isSame(getMaxUntil()) || !until ? null : serializeDate(until),
            ...others,
          },
        ]
      : [],
  );
  return { ...other, directParents };
};
