import { formatFromStaticData, formatToStaticData } from 'src/adapters/players.adapter';

import { axiosApi, axiosStaticV2 } from 'src/services/axios.service';

import type { PlayerEdit, PlayerStaticData } from 'types/edits/player';
import type { PlayerDetails } from 'types/player';
import type { EditApiResponse, CreateApiResponse, DeleteApiResponse } from 'types/staticData';

const getPlayer = (id: string | number): Promise<PlayerDetails> =>
  axiosApi.get<PlayerDetails>(`/players/${id}`);

const getPlayerFromStaticData = (id: string | number): Promise<PlayerEdit> =>
  axiosStaticV2
    .get<{ data: PlayerStaticData }>(`/players/${id}`)
    .then(data => formatFromStaticData(data.data));

const editPlayer = (id: string | number, player: PlayerEdit): Promise<EditApiResponse> =>
  axiosStaticV2.put(`/players/${id}`, formatToStaticData(player));

const createPlayer = (player: PlayerEdit): Promise<CreateApiResponse> =>
  axiosStaticV2.post('players', formatToStaticData(player));

const deletePlayer = (playerId: string | number): Promise<DeleteApiResponse> =>
  axiosStaticV2.delete(`players/${playerId}`);

export default {
  getPlayer,
  getPlayerFromStaticData,
  editPlayer,
  deletePlayer,
  createPlayer,
};
