import { ResearchNewsNotificationsFrequency } from '@kpler/terminal-graphql';

import type { UserNotificationSettingsForm } from './TheUserNotifications.types';
import type { ObjectBase } from '@kpler/generic-utils';

export const FREQUENCY_OPTIONS = Object.freeze<
  Array<ObjectBase<ResearchNewsNotificationsFrequency>>
>([
  { id: ResearchNewsNotificationsFrequency.Immediately, name: 'Immediately' },
  { id: ResearchNewsNotificationsFrequency.Daily, name: 'Daily' },
  { id: ResearchNewsNotificationsFrequency.Weekly, name: 'Weekly' },
]);

export const DEFAULT_SETTINGS = Object.freeze<UserNotificationSettingsForm>({
  researchUpdates: ResearchNewsNotificationsFrequency.Immediately,
  reports: ResearchNewsNotificationsFrequency.Immediately,
  news: ResearchNewsNotificationsFrequency.Immediately,
  areResearchUpdatesEnabled: false,
  areReportsEnabled: false,
  areNewsEnabled: false,
});

export enum NotificationsRoutesTab {
  POWER = 'power-notifications',
  VESSELS = 'vessels-notifications',
}
