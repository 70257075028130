import { assertDefined } from '@kpler/generic-utils';

import colors from 'src/scss/colors.constants';

import { formatValueOrFallback } from 'src/helpers/formatNumber.helper';
import { sortAlphabetically } from 'src/helpers/sort.helper';

import type {
  ConvertedTimeSeries,
  ConvertedTimeSeriesAsMap,
  ConvertedTimeSeriesEntry,
  ConvertedTimeSeriesSplitValue,
  TimeSerieSplit,
} from 'types/chart';
import type { TimeSeries, ValueQuantityObject } from 'types/series';

// @TODO if we need to check if multiple datasets are empty,
// could do it here to avoid iterating over array once per dataset.
export const checkTimeSeriesEmpty = <T>(
  series: ReadonlyArray<TimeSeries<T>>,
  datasetName: string,
  accessorFn: (values: T) => number,
): boolean => {
  if (!series || series.length === 0) {
    return true;
  }
  const datasetIndex = series[0].datasets.findIndex(ds => ds.datasetName === datasetName);
  return series.every(
    serie => !serie.datasets.length || !accessorFn(serie.datasets[datasetIndex].values),
  );
};

export const checkConvertedTimeSeriesEmpty = (
  series: readonly ConvertedTimeSeries[],
  datasetName: string,
): boolean => {
  if (!series || series.length === 0) {
    return true;
  }
  const datasetIndex = series[0].series.findIndex(ds => ds.key === datasetName);
  if (datasetIndex === -1) {
    return true;
  }

  return series.every(serie => !serie.series.length || !serie.series[datasetIndex].value);
};

const isEstimated = (split: TimeSerieSplit): boolean => split.id.split('_').length > 1;
const isConfirmed = (split: TimeSerieSplit): boolean => split.id.split('_').length === 1;

export const intercedeEstimatedSplits = (sortedSplits: TimeSerieSplit[]): TimeSerieSplit[] => {
  const sortedEstimatedSplits = sortedSplits.filter(split => isEstimated(split));
  const sortedConfirmedSplits = sortedSplits.filter(split => isConfirmed(split));

  sortedEstimatedSplits.forEach(eSplit => {
    const idx = sortedConfirmedSplits.findIndex(split => split.id === eSplit.id.split('_')[0]);
    sortedConfirmedSplits.splice(idx > -1 ? idx + 1 : sortedConfirmedSplits.length, 0, eSplit);
  });

  return sortedConfirmedSplits;
};

export const formatSplitName = (split: TimeSerieSplit): string =>
  isEstimated(split) ? `${split.name} (Estimated)` : split.name;

export const moveSplitIdToEndOfSplit = (splits: TimeSerieSplit[], splitId: string) => {
  const indexOfSplit = splits.findIndex(x => x.id.toLowerCase() === splitId.toLowerCase());
  if (indexOfSplit !== -1) {
    splits.push(splits.splice(indexOfSplit, 1)[0]);
  }
};

export enum SplitOrder {
  ALPHABETICAL,
  CONTRIBUTION_DESC,
}

export const computeSplitOrder = (
  timeSeries: readonly ConvertedTimeSeries[],
  serieKey: string,
  splitOrder = SplitOrder.CONTRIBUTION_DESC,
): readonly TimeSerieSplit[] => {
  if (!timeSeries.length) {
    return [];
  }

  const timeSeriesWithDataset = timeSeries.filter(
    timeserie => timeserie.series.findIndex(x => x.key === serieKey) >= 0,
  );
  const serieIndex = timeSeriesWithDataset[0].series.findIndex(x => x.key === serieKey);
  assertDefined(serieIndex);

  const splitsWithWeight = new Map<
    string,
    { historicalContribution: number; split: TimeSerieSplit }
  >();
  timeSeriesWithDataset.forEach(timeSerie => {
    timeSerie.series[serieIndex].splitValues?.forEach(split => {
      const key = split.id;
      const existingValue = splitsWithWeight.get(key);
      const newValue = {
        historicalContribution: (existingValue?.historicalContribution ?? 0) + (split.value ?? 0),
        split: existingValue?.split ?? { id: split.id, name: split.name },
      };

      splitsWithWeight.set(key, newValue);
    });
  });

  const sortedSplits = [...splitsWithWeight.entries()]
    .sort((a, b) => {
      if (splitOrder === SplitOrder.ALPHABETICAL) {
        return a[1].split.name.localeCompare(b[1].split.name);
      }
      return Math.abs(b[1].historicalContribution) - Math.abs(a[1].historicalContribution);
    })
    .map(x => ({
      id: x[1].split.id.toString(),
      name: x[1].split.name,
    }));

  moveSplitIdToEndOfSplit(sortedSplits, 'other');
  moveSplitIdToEndOfSplit(sortedSplits, 'unknown');

  return intercedeEstimatedSplits(sortedSplits);
};

export const computeSeasonalSeriesOrder = (
  timeSeries: readonly ConvertedTimeSeries[],
): readonly TimeSerieSplit[] => {
  const seriesWithDuplicates = timeSeries.flatMap(period =>
    period.series.map(dataset => ({ id: dataset.key, name: dataset.key })),
  );
  const orderedSeries: TimeSerieSplit[] = seriesWithDuplicates
    .filter((item, i, arr) => arr.findIndex(x => x.id === item.id) === i)
    .sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }));
  const lastIndex = orderedSeries.length - 1;
  for (let i = orderedSeries.length - 1; i >= 0; i--) {
    orderedSeries[i].color = colors.graphColors[(lastIndex - i) % colors.graphColors.length];
  }
  return orderedSeries;
};

export const convertTimeSeries = <T, U = string>(
  timeSeries: ReadonlyArray<TimeSeries<T, U>>,
  conversionFn: (values: T, date: string, datasetName: U) => number,
  propsFn?: (values: T) => { [key: string]: any },
  extraDataLabelFn?: (values: T) => number | undefined,
): ReadonlyArray<ConvertedTimeSeries<U>> =>
  timeSeries.map(timeSerie => ({
    date: timeSerie.date,
    series: timeSerie.datasets.map(dataset => {
      const props = propsFn === undefined ? {} : { props: propsFn(dataset.values) };
      const obj: ConvertedTimeSeriesEntry<U> = {
        key: dataset.datasetName,
        value: conversionFn(dataset.values, timeSerie.date, dataset.datasetName),
        ...props,
      };

      if (extraDataLabelFn) {
        obj.extraDataValue = extraDataLabelFn(dataset.values);
      }
      if (dataset.splitValues) {
        obj.splitValues = dataset.splitValues.map(split => {
          const splitValue: ConvertedTimeSeriesSplitValue = {
            name: split.name,
            value: conversionFn(split.values, timeSerie.date, dataset.datasetName),
            id: split.id?.toString() ?? split.name,
          };

          if (extraDataLabelFn) {
            splitValue.extraDataValue = extraDataLabelFn(split.values);
          }
          return splitValue;
        });
      }
      return obj;
    }),
  }));

export const isOthersSplit = (splitId: string | null): boolean =>
  splitId?.toLowerCase() === 'others';

export const getSplitValuesToIncludeOrExclude = (
  series: ReadonlyArray<TimeSeries<any>>,
  splitId: string | null,
  datasetName: string,
): {
  splitValues: string[];
  splitValuesToExclude: string[];
} => {
  if (series.length === 0) {
    return {
      splitValues: [],
      splitValuesToExclude: [],
    };
  }

  const datasetIndex = series[0].datasets.findIndex(x => x.datasetName === datasetName) ?? 0;

  const splitsWithoutOther = series.flatMap(
    seriesItem =>
      seriesItem.datasets[datasetIndex].splitValues
        ?.filter(x => !isOthersSplit(x.name))
        .map(x => x.id) || [],
  );
  const uniqueSplits = [...new Set(splitsWithoutOther)];
  const splitValues = splitId && !isOthersSplit(splitId) ? [splitId] : [];
  const splitValuesToExclude = isOthersSplit(splitId) ? uniqueSplits : [];
  return {
    splitValues,
    splitValuesToExclude,
  };
};

// @TODO refactor into a generic function
export const getValueFromSeries = (
  selectedSeriesForPeriod: TimeSeries<ValueQuantityObject>,
  serieIndex: number,
  splitValueId?: string | number,
): ValueQuantityObject => {
  const serieObject = selectedSeriesForPeriod.datasets[serieIndex];
  if (!splitValueId) {
    return serieObject.values;
  }
  if (serieObject.splitValues === undefined) {
    throw new Error('Must have splitValues.');
  }
  if (serieObject.splitValues.filter(x => x.id === splitValueId).length > 1) {
    throw new Error('splitValues.id should not be duplicated.');
  }
  const splitValue = serieObject.splitValues.find(x => x.id === splitValueId);
  if (splitValue !== undefined) {
    return splitValue.values;
  }
  return { value: 0 };
};

export const getTimeSeriesAsMap = <T>(
  arr: ReadonlyArray<ConvertedTimeSeries<T>>,
): ReadonlyArray<ConvertedTimeSeriesAsMap<T>> =>
  arr.map(period => ({
    date: period.date,
    series: new Map<T, ConvertedTimeSeriesEntry<T>>(period.series.map(x => [x.key, x])),
  }));

export const addMovingAverageToTimeSeriesAsMap = <T extends string>(
  data: ReadonlyArray<ConvertedTimeSeriesAsMap<T>>,
  key: T,
  movingAverageSize: number,
  futureIndex?: number,
) =>
  data.map((period, idx, arr) => {
    if (idx > movingAverageSize && (futureIndex === undefined || idx <= futureIndex)) {
      const subset = arr.slice(idx + 1 - movingAverageSize, idx + 1);
      const sum = subset.reduce((acc, current) => acc + (current.series?.get(key)?.value ?? 0), 0);
      const series: Map<string, any> = new Map(period.series);
      series.set('movingAverage', { key: 'movingAverage', value: sum / movingAverageSize });
      return { ...period, series };
    }
    return period;
  });

const getValue = <T>(
  period: ConvertedTimeSeriesAsMap<T>,
  key: T,
  split?: string,
): number | undefined => {
  const dataset = period.series.get(key);
  return split === undefined
    ? (dataset?.value ?? undefined)
    : (dataset?.splitValues?.find(x => x.id === split)?.value ?? undefined);
};
const getDeltaClassFromValue = (value: number | undefined) => {
  if (value === 0) {
    return '';
  }
  if (value === undefined) {
    return 'text-secondary';
  }
  return value > 0 ? 'green' : 'red';
};

type FormatOrFallbackOptions<T> = {
  formatFn: null | ((x: number) => string);
  period: ConvertedTimeSeriesAsMap<T>;
  key: T;
  split?: string;
  fallback?: string;
  dp?: number;
};
export const formatOrFallback = <T>({
  formatFn,
  period,
  key,
  split,
  fallback,
  dp,
}: FormatOrFallbackOptions<T>) => {
  const value = getValue(period, key, split);
  return formatValueOrFallback(formatFn, value, dp, fallback);
};

export const getCellClass = <T>(
  period: ConvertedTimeSeriesAsMap<T>,
  key: T,
  split?: string,
): string => {
  const value = getValue(period, key, split);
  return value === undefined ? 'text-secondary' : '';
};

export const getDeltaCellClass = <T>(
  period: ConvertedTimeSeriesAsMap<T>,
  key: T,
  split?: string,
): string => {
  const value = getValue(period, key, split);
  return getDeltaClassFromValue(value);
};

export const filterTimeseriesByDatasetsAndSort = <T extends string>(
  timeseries: ReadonlyArray<ConvertedTimeSeries<T>>,
  datasets: readonly T[],
): ReadonlyArray<ConvertedTimeSeries<T>> =>
  timeseries.map(timeserie => ({
    date: timeserie.date,
    series: timeserie.series
      .filter(timeserieEntry => datasets.includes(timeserieEntry.key))
      .sort((a, b) => sortAlphabetically(a.key, b.key)),
  }));
