const getTimestamp = (date: string) => {
  const parsedDate = Date.parse(date);

  return Number.isNaN(parsedDate) ? Number(date) : parsedDate;
};

export const sortAlphabetically = (strA: string, strB: string): number =>
  strA
    .toLowerCase()
    .localeCompare(strB.toLowerCase(), 'en', { sensitivity: 'base', numeric: true });

export const sortDate = (dateA: string, dateB: string) => {
  const optionA = getTimestamp(dateA);
  const optionB = getTimestamp(dateB);

  if (optionA > optionB) {
    return 1;
  }

  if (optionA < optionB) {
    return -1;
  }

  return 0;
};

export const sortByProperty = <T>(property: string, reverseOrder = false) => {
  const sortOrder = reverseOrder ? -1 : 1;

  return (itemA: T, itemB: T) => {
    const result =
      itemA[property as keyof T] < itemB[property as keyof T]
        ? -1
        : itemA[property as keyof T] > itemB[property as keyof T]
          ? 1
          : 0;
    return result * sortOrder;
  };
};
