import { slugify } from 'src/domains/insight/common/utils/slugify';

import type {
  CommoditySlug,
  SectionContext,
} from 'src/domains/insight/common/components/navigationMenu/types';

export const getRouteName = (args: {
  commoditySlug: CommoditySlug;
  context: SectionContext;
  reportType?: string;
}): string => {
  const { commoditySlug, context, reportType } = args;
  return `${commoditySlug}-${context}${reportType ? `-${slugify(reportType)}` : ''}`;
};
