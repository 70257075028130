import { slugify } from 'src/domains/insight/common/utils/slugify';

import type {
  CommoditySlug,
  SectionContext,
} from 'src/domains/insight/common/components/navigationMenu/types';

export const getRoutePath = (args: {
  commoditySlug: CommoditySlug;
  context: SectionContext;
  reportType?: string;
}): string =>
  `${args.commoditySlug}/${args.context}${args.reportType ? `/${slugify(args.reportType)}` : ''}`;
