fragment player on Player {
  id
  name
}

fragment playerSearch on PlayerSearch {
  score
  highlight {
    field
    value
  }
  player {
    ...player
  }
}
