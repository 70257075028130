#import 'src/main/graphql/products/Product.fragment.gql'

fragment productSearch on ProductSearch {
  score
  highlight {
    field
    value
  }
  product {
    ...product
  }
}
