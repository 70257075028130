fragment zone on Zone {
  id
  hasStorage
  isInUnitedStates
  name
  type
}

fragment zoneSearch on ZoneSearch {
  highlight {
    field
    value
  }
  score
  zone {
    ...zone
  }
}
