export const zoneIds = {
  ALGERIA: 17,
  ANGOLA: 35,
  ARA: 43,
  ARA_FLOATING_STORAGE: 6294,
  ATLANTIC_FLOATING_STORAGE: 6290,
  BALTIC_SEA: 87,
  BALTICS_FLOATING_STORAGE: 6293,
  BLACK_SEA: 129,
  BRAZIL: 141,
  CANADA: 167,
  CAPE_HORN: 1683,
  CAPE_OF_GOOD_HOPE: 171,
  CARIBBEAN_SEA: 181,
  CHINA: 213,
  CHINA_FLOATING_STORAGE: 5858,
  COLOMBIA: 219,
  EAST_SINGAPORE_FLOATING_STORAGE: 6093,
  ECUADOR: 297,
  EQUATORIAL_GUINEA: 307,
  FRANCE: 325,
  GABON: 333,
  GIBRALTAR: 343,
  GREECE: 353,
  INDIA: 447,
  IRAN: 455,
  IRAN_FLOATING_STORAGE: 5856,
  IRAQ: 457,
  ITALY: 467,
  JAPAN: 477,
  JAPAN_SOUTH_KOREA_FLOATING_STORAGE: 6287,
  KHOR_FAKKAN_ANCHORAGE: 4339,
  KUWAIT: 505,
  LIBYA: 529,
  MALACCA_STRAIT_FLOATING_STORAGE: 5861,
  MED_FLOATING_STORAGE: 6289,
  MED_SEA: 583,
  MEXICO: 589,
  NETHERLANDS: 627,
  NIGERIA: 637,
  NON_OPEC: 5822,
  NORTH_SEA: 661,
  NORTH_SEA_FLOATING_STORAGE: 5860,
  NORTHERN_SEA_ROUTE: 657,
  NORWAY: 665,
  OPEC: 5821,
  PADD_1: 689,
  PADD_3: 697,
  PADD_5: 699,
  PANAMA_CANAL: 2200,
  KIEL_CANAL: 4100,
  CORINTH_CANAL: 4102,
  PERSIAN_GULF: 719,
  PERSIAN_GULF_FLOATING_STORAGE: 6292,
  QATAR: 739,
  RED_SEA: 741,
  REPUBLIC_OF_THE_CONGO: 743,
  RUSSIA: 757,
  SAUDIA_ARABIA: 787,
  SINGAPORE_STRAIT_FLOATING_STORAGE: 5857,
  SOUTH_KOREA: 873,
  SPAIN: 877,
  SUEZ_CANAL: 3971,
  TAIWAN: 899,
  THAILAND: 911,
  TURKEY: 935,
  UAE: 943,
  UK: 945,
  UNITED_STATES: 947,
  US_GULF_COAST_FLOATING_STORAGE: 5859,
  VENEZUELA: 961,
  VENEZUELA_CARIBBEAN_FLOATING_STORAGE: 6288,
  WEST_AFRICAN_GULF_FLOATING_STORAGE: 5920,
  WESTERN_PETROLEUM_B_ANCHORAGE: 3737,
  WORLD: 4115,
  WORLD_FLOATING_STORAGE: 5975,
  WORLD_OCEANS: 4142,
};

export const productIds = Object.freeze<Record<string, number>>({
  AMMONIA: 1076,
  BAUXITE: 1188,
  CLEAN: 1362,
  CLEAN_PRODUCTS: 1328,
  COAL: 1334,
  CONDENSATE: 1348,
  CRUDE_OIL: 1368,
  CRUDE_OIL_CONDENSATE: 1370,
  DIRTY: 1398,
  DPP: 1400,
  DRY_BULK: 1414,
  ETHANE: 1472,
  EX_LPG: 1482,
  GRAINS_OILSEEDS: 2721,
  IRON_ORE: 1630,
  LIGHT_ENDS: 1730,
  LIQUIDS: 1746,
  LNG: 1750,
  LPG: 1772,
  MIDDLE_DISTILLATES: 1846,
  MINOR_BULKS: 1854,
  NON_PETROLEUM_CLEAN: 1920,
  OLEFINS: 2016,
  PETCOKE: 2018,
  UNKNOWN: 2338,
});

export const bolProviderIds = [25, 258, 259, 260, 4790, 4774, 4105, 288, 261];

export const ROOT_PRODUCTS = [productIds.DRY_BULK, productIds.LIQUIDS, productIds.EX_LPG];
