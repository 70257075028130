import type { DateRange, DateRangePreset, PastFutureDateRangePreset } from '@kpler/terminal-utils';
import type { DemandKind } from 'src/domains/power/Analytics/Visualization/Demand/filters';
import type { Timezone } from 'src/domains/power/Forecasts/constants';
import type { LongtermDashboardForwardCurveTypes } from 'src/domains/power/LongTermDashboard/constants';
import type { PowerInstallationLegend, PowerLegend } from 'src/domains/power/constants';
import type {
  Fuel,
  AnalyticsSupplyMetrics,
  Region,
  ForecastModel,
  ForecastRun,
  ForecastHorizon,
  SortOrder,
} from 'src/domains/power/enums';
import type { ProductionUnit, Provider } from 'src/domains/power/types';
import type { LatLon } from 'types/legacy-globals';

type Bounds = {
  northEast: LatLon;
  southWest: LatLon;
};

export type MapViewOption = 'status' | 'fuel';

export type Country = {
  isoCode: string;
  name: string;
  bounds: Bounds;
};

export enum Granularity {
  HOURS = 'hours',
  DAYS = 'days',
  MONTHS = 'months',
}

export type UnavailabilityType = 'planned' | 'unplanned';
export type EventType = 'active' | 'cancelled' | 'withdrawn';
export type RangeType = 'event' | 'publication';

export type DashboardGranularity = Exclude<Granularity, Granularity.MONTHS>;

export enum SplitBy {
  UNIT = 'unit',
}

export enum LongTermDashboardMode {
  SINGLE = 'Single',
  COMPARISON = 'Comparison',
}

export type LongTermDashboardProductType = 'Base' | 'Peak' | 'Off peak';

export type LongTermDashboardCommoditiesType = 'Gas' | 'Power';

export type LongTermDashboardScenarioType = 'precomposed' | 'new';

export enum LongTermGranularity {
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
  QUARTERS = 'quarters',
  YEARS = 'years',
}

export type DehydratedState = {
  mapView: PowerLegend;
  installationView: string;
  country?: string;
  installation?: string;
  fuels: readonly string[];
  dateRange: PastFutureDateRangePreset | DateRange; // Used for outages
  providers: readonly Provider[];
  outageUnavailabilityType?: UnavailabilityType;
  outageEventType?: EventType;
  outageRangeType: RangeType;
  onlyCommissioned: boolean;
  dates: DateRangePreset | DateRange; // Used for analytics
  granularity: Granularity;
  snapshotDate?: string;
  metrics: readonly AnalyticsSupplyMetrics[];
  seasonal: boolean;
  price: boolean;
  showPercentages: boolean;
  showTemperature: boolean;
  splitByUnit: boolean;
  region?: Region;
  forecastCountries: readonly string[];
  forecastModel?: ForecastModel;
  forecastModels: ForecastModel[];
  forecastRun?: ForecastRun;
  forecastHorizon?: string;
  forecastHorizons: readonly ForecastHorizon[];
  forecastDateRange: DateRangePreset | DateRange;
  forecastGranularity: Granularity;
  forecastTimezone: Timezone;
  timezone: Timezone;
  demandKind: DemandKind;
  spotDashboardGranularity: DashboardGranularity;
  spotDashboardSrmcGasEfficiency?: number;
  spotDashboardSrmcCoalEfficiency?: number;
  spotDashboardBiddingZone?: string;
  orderBy: SortOrder;
  productType: readonly LongTermDashboardProductType[];
  longTermDashboardLocation: readonly string[];
  commodities: readonly LongTermDashboardCommoditiesType[];
  scenarios: readonly string[];
  longTermDashboardGranularity: LongTermGranularity;
  lastTwoYears: boolean;
  tradingDates: readonly string[];
  longTermDashboardMode: LongTermDashboardMode;
  longTermDarkSpark: boolean;
  longTermLivePrices: boolean;
  longTermSparkEfficiencyFactor?: number;
  longTermDarkEfficiencyFactor?: number;
  longTermCurveType: readonly LongtermDashboardForwardCurveTypes[];
  longTermLivePriceProduct: string;
  longTermLivePriceSecondProduct: string;
};

export type HydratedState = {
  mapView: PowerLegend;
  installationView: PowerInstallationLegend;
  country?: Country;
  installation?: ProductionUnit;
  fuels: readonly Fuel[];
  dateRange: PastFutureDateRangePreset | DateRange;
  providers: readonly Provider[];
  dates: DateRangePreset | DateRange;
  outageUnavailabilityType?: UnavailabilityType;
  outageEventType?: EventType;
  outageRangeType: RangeType;
  onlyCommissioned: boolean;
  granularity: Granularity;
  snapshotDate?: string;
  metrics: readonly AnalyticsSupplyMetrics[];
  seasonal: boolean;
  price: boolean;
  showPercentages: boolean;
  showTemperature: boolean;
  splitByUnit: boolean;
  region?: Region;
  forecastCountries: readonly string[];
  forecastModel?: ForecastModel;
  forecastModels: ForecastModel[];
  forecastRun?: ForecastRun;
  forecastHorizon?: string;
  forecastHorizons: readonly ForecastHorizon[];
  forecastDateRange: DateRangePreset | DateRange;
  forecastGranularity: Granularity;
  forecastTimezone: Timezone;
  timezone: Timezone;
  demandKind: DemandKind;
  spotDashboardGranularity: DashboardGranularity;
  spotDashboardSrmcGasEfficiency?: number;
  spotDashboardSrmcCoalEfficiency?: number;
  spotDashboardBiddingZone?: string;
  orderBy: SortOrder;
  productType: readonly LongTermDashboardProductType[];
  longTermDashboardLocation: readonly string[];
  commodities: readonly LongTermDashboardCommoditiesType[];
  scenarios: readonly string[];
  longTermDashboardGranularity: LongTermGranularity;
  lastTwoYears: boolean;
  tradingDates: readonly string[];
  longTermDashboardMode: LongTermDashboardMode;
  longTermDarkSpark: boolean;
  longTermLivePrices: boolean;
  longTermSparkEfficiencyFactor?: number;
  longTermDarkEfficiencyFactor?: number;
  longTermCurveType: readonly LongtermDashboardForwardCurveTypes[];
  longTermLivePriceProduct: string;
  longTermLivePriceSecondProduct: string;
};
