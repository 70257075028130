import { ARRAY_REPEAT_SERIALIZER } from '@kpler/generic-utils';
import axios from 'axios';

export const axiosCharteringApi = axios.create({
  baseURL: process.env.NX_PUBLIC_CHARTERING_BACKEND_URL,
  paramsSerializer: ARRAY_REPEAT_SERIALIZER,
  headers: {
    'Use-Access-Token': 'false',
  },
});
