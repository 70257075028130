export const savedForLaterEn = {
  title: 'Saved for later',
  filterAll: 'All',
  filterNews: 'News',
  filterMarketPulse: 'Market Pulse',
  filterReports: 'Reports',
  filterWebinars: 'Webinars',
  searchPlaceholder: 'Search your saved posts',
  emptyList: 'Your reading list is empty.',
  clickToSave: 'Click the',
  nextToArticle: 'next to an article to save it for later.',
  readMore: 'Read more',
  helpDesk: 'in our help desk.',
  actionSaved: 'Saved',
  actionSave: 'Save for later',
};
