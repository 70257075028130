export const commonEn = {
  buttons: {
    showMore: 'show more',
    upgrade: 'Upgrade',
    unlock: 'Unlock',
    savedForLater: 'Saved for later',
    draftPreview: 'Draft preview',
  },
  search: {
    placeholder: 'Ask a question or search by keyword...',
  },
  navigation: {
    news: 'News',
    reports: 'Reports',
    webinars: 'Webinars',
    marketPulse: 'Market Pulse',
    weeklyReports: 'Weekly Reports',
    monthlyReports: 'Monthly Reports',
  },
  labels: {
    relatedContent: 'Related Content',
    published: 'Published',
    updated: 'Updated',
    publishedOn: 'Published on',
    updatedOn: 'Updated on',
    previous: 'Previous',
    next: 'Next',
  },
  download: {
    buttons: {
      issue: 'Download issue',
      article: 'Download article',
      presentation: 'Download presentation',
      fileExtensionPdf: 'PDF',
    },
  },
  readingTime: {
    minRead: '{{minutes}} min read',
  },
  report: {
    summaryTitle: 'Contents',
  },
  readArticle: 'Read full article',
};
