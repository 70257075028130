import Vue from 'vue';

import type { Provide } from './app/providers/types';
import type { Component, VueConstructor } from 'vue';

export type App = {
  config: VueConstructor['config'];
  use: VueConstructor['use'];
  mixin: VueConstructor['mixin'];
  component: VueConstructor['component'];
  mount: Vue['$mount'];
  unmount: Vue['$destroy'];
};

type Props = Record<string, unknown>;

export function createApp(component: Component, props?: Props, provide?: Provide): App {
  let mountedVM: Vue | undefined;

  const app: App = {
    config: Vue.config,
    use: Vue.use.bind(Vue),
    mixin: Vue.mixin.bind(Vue),
    component: Vue.component.bind(Vue),

    mount: (el, hydrating) => {
      if (!mountedVM) {
        mountedVM = new Vue({
          components: { ChildComponent: component },
          provide: {
            ...provide,
          },
          propsData: props,
          render: h => h('ChildComponent', { props }),
        });

        mountedVM.$mount(el, hydrating);
        return mountedVM;
      }

      return mountedVM;
    },
    unmount: () => {
      if (mountedVM) {
        mountedVM.$destroy();
        mountedVM = undefined;
      }
    },
  };

  return app;
}
