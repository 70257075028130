import { userHasAccessToComplianceReadFactory } from '@kpler/compliance-common';

import store from 'src/store';

import { complianceRoutesName } from './constants';

import type { RouteRecordRaw } from '@kpler/vue2-utils';

const ComplianceWorkspace = () =>
  import(/* webpackChunkName: "compliance" */ './ComplianceWorkspace.vue');

const ComplianceFleet = () =>
  userHasAccessToComplianceReadFactory(store)
    ? import(/* webpackChunkName: "compliance" */ './pages/ComplianceFleet/ComplianceFleet.vue')
    : import(
        /* webpackChunkName: "compliance" */ './pages/ComplianceFleet/ComplianceFleetPreview.vue'
      );

const complianceRoutes: RouteRecordRaw = {
  name: 'compliance',
  path: 'compliance-workspace',
  meta: { title: 'Compliance' },
  component: ComplianceWorkspace,
  redirect: '/compliance-workspace/fleet',
  children: [
    {
      name: complianceRoutesName.FLEET,
      path: 'fleet',
      component: ComplianceFleet,
    },
  ],
};

export default complianceRoutes;
