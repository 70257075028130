import type { WidgetVesselFragment } from '@kpler/terminal-graphql';
import type { SerializedDateRange } from '@kpler/terminal-utils';
import type { Market } from '@kpler/web-ui';
import type {
  LatLon,
  ResourceType,
  VesselTypeClassification,
  BaseSearchResult,
} from 'types/legacy-globals';
import type { CurrentCargo, Position } from 'types/map';
import type { Player } from 'types/player';
import type { QuantityObject } from 'types/quantity';
import type { Zone } from 'types/zone';

export enum VesselStatePayload {
  LAID_UP = 'laidUp',
  UNDER_CONSTRUCTION = 'underConstruction',
  INACTIVE = 'inactive',
  UNKNOWN = 'unknown',
  LOADED = 'loaded',
  BALLAST = 'ballast',
}

export enum VesselStateCategory {
  OPEN = 'open',
  LOADED = 'loaded',
  BALLAST = 'ballast',
  FLOATING_STORAGE = 'floating_storage',
  CONSTRUCTION = 'construction',
  OTHER = 'other',
}

export enum VesselBehaviour {
  BUNKER = 'bunker',
  FEEDER = 'feeder',
  FEEDER_STORAGE = 'feeder_storage',
}

export type VesselBase = BaseSearchResult & {
  resourceType: ResourceType.VESSEL;
  hasLastPosition: boolean;

  // Needed for the vessel list export, but might be worth not to use it in other places, and
  // clarify what is the distinction between state and status:
  status: string;
};

type VesselCommon = VesselBase & {
  capacityMultiUnits: QuantityObject;
  cargoMetrics: {
    dailyBoilOffRate?: number;
    numberTanks?: number;
    cargoType?: string;
    capacity?: number;
    cargoSystem?: string;
  };
  classification?: {
    carrierType?: 'Ocean' | string;
    /**
     * @deprecated use `vesselTypeClass` instead for the Merge platform.
     * It should be removed once the 4 platforms will be decommissioned
     */
    vesselTypes: { [key in VesselTypeClassification]?: string };
  };
  /**
   * @property vesselTypeClass - Represents the vessel type for the Merge platform.
   * It replaces the `classification.vesselTypes` property.
   * The vessel type is now unique in the merge and does not depend on the classification.
   */
  vesselTypeClass?: string;
  deadWeight: number;
  engineMetrics?: {
    powerPlantType: string;
    horsePower?: number;
    maxSpeed?: number;
  };
  hasAssumptions: boolean;
  isBunker: boolean;
  isFeeder: boolean;
  isFeederStorage: boolean;
  lastPosition: {
    speed: number;
    course: number;
    geo: LatLon;
    receivedTime: string;
    draught: number | null;
    draughtChange: number | null;
    draughtComputed: number;
    currentCargo: CurrentCargo;
    quantity: QuantityObject;
  } | null;
  nextDestination: {
    id: number;
    eta: string;
    installation: any | null;
    zone: any | null;
  } | null;
  statcode: {
    code: string;
    name: string;
  };
  state: VesselStatePayload;
  vesselController?: {
    default: any;
  };
  isOpen: boolean;
  isFloatingStorage: boolean;
};

export type VesselMapPayload = VesselCommon & {
  beta: boolean;
  build?: {
    buildYear: number;
  };
  hasAssumptions: boolean;
  imo: string;
  isBunker: boolean;
  isEthyleneCapable?: boolean;
  isFeeder: boolean;
  isFeederStorage: boolean;
  lastRawAisSignals: {
    rawDestination: string;
    eta: string | null;
    sourceType: string;
  } | null;
  mmsi: string;
  commodityTypes: Market[];
  currentCommodityType: Market;
};

export type Vessel = VesselCommon & {
  build?: {
    buildYear: number;
    buildCountry: string;
  };
  buildYear?: number;
  buildAt?: string;
  deadAt?: string;
  empiricalMaxDraughtChange: number;
  orderAt?: string;
  complianceMethod?: string;
  flagName?: string;
  imo: string;
  mmsi: string;
  players: {
    builders: Player[];
    insurers: Player[];
    managers: Player[];
    operators: Player[];
    owners: Player[];
  } | null;
  portCallsMetrics: {
    unloadedThisYearInTon: number;
    unloads?: number;
  };
  price?: number;
  status: 'Active' | 'Inactive' | 'Under Construction';
  vesselAvailability: VesselAvailability[];
  commodityTypes: Market[];
  currentCommodityType?: Market;
  beta?: boolean;
};

export type VesselAvailability = {
  comment: string;
  condition: string;
  delivery: Zone;
  id: number;
  open: string;
  reportDate: string;
  redelivery: Zone;
  until: string | null;
};

export type GroupedVesselAvailability = {
  reportDate: string;
  openDatesRange: SerializedDateRange;
  locations: Zone[];
  comments: string;
};

export enum VesselTrackType {
  STS = 'sts',
}

export type VesselTrack = {
  vesselId: number;
  positions: Position[];
  useVesselPosition: boolean;
  type?: VesselTrackType;
  isActive: boolean;
  isKept: boolean;
};

export type VesselTrackWithPayload = VesselTrack & {
  vessel: VesselMapPayload | WidgetVesselFragment | null;
};

export enum VessselStatcode {
  ASPHALT_BITUMEN = 'A13C2LA',
}

export type VesselDisplayConfig = {
  trackedProducts: string[];
  overrideTrackedProducts: boolean;
  showOpenVessels?: boolean;
};
