import { assertDefined } from '@kpler/generic-utils';
import { Market } from '@kpler/web-ui';

import { UNITS_BY_DOMAIN } from 'src/constants/unitsByDomain.constants';

import type { AppStore } from 'src/store/types';
import { AnalyticsDataType } from 'types/analytics';
import { TemporalUnitName, UnitName } from 'types/unit';

type StoreGetter = () => AppStore;
type DataTypeInUnits =
  | AnalyticsDataType.FLOWS
  | AnalyticsDataType.REFINERY_CAPACITIES
  | AnalyticsDataType.FLEET_METRICS
  | AnalyticsDataType.INVENTORIES
  | AnalyticsDataType.CONGESTION
  | AnalyticsDataType.BALLAST_CAPACITY
  | AnalyticsDataType.FLEET_UTILIZATION
  | AnalyticsDataType.FLEET_DEVELOPMENT;
type UnitReturn<T> = T extends AnalyticsDataType.FLOWS
  ? UnitName | TemporalUnitName
  : T extends AnalyticsDataType.REFINERY_CAPACITIES
    ? TemporalUnitName
    : UnitName;

function isStoreGetter(storeInjection: AppStore | StoreGetter): storeInjection is StoreGetter {
  return typeof storeInjection === 'function';
}

export class UnitService {
  static readonly INJECT_KEY = 'unitService';

  #storeGetter: StoreGetter;
  #dataTypeToAccessibleMarketsRecord: Record<DataTypeInUnits, Set<Market>>;

  constructor(storeInstance: AppStore);
  constructor(storeGetter: StoreGetter);
  constructor(storeInjection: AppStore | StoreGetter) {
    assertDefined(
      storeInjection,
      'storeInstance or storeGetter must be defined to be injected in ConversionService.',
    );

    this.#storeGetter = isStoreGetter(storeInjection) ? storeInjection : () => storeInjection;
    this.#dataTypeToAccessibleMarketsRecord = this.createDataTypeToAccessibleMarketsRecord();
  }

  get injectKey() {
    return UnitService.INJECT_KEY;
  }

  private get store() {
    return this.#storeGetter();
  }

  private createDataTypeToAccessibleMarketsRecord = () => ({
    [AnalyticsDataType.FLOWS]: this.store.getters.accessibleMarketsForFlows,
    [AnalyticsDataType.FLEET_METRICS]: this.store.getters.accessibleMarketsForFlows,
    [AnalyticsDataType.REFINERY_CAPACITIES]: this.store.getters.accessibleMarketsForRefineries,
    [AnalyticsDataType.INVENTORIES]: this.store.getters.accessibleMarketsForInventory,
    [AnalyticsDataType.CONGESTION]: this.store.getters.accessibleMarketsForFreight,
    [AnalyticsDataType.BALLAST_CAPACITY]: this.store.getters.accessibleMarketsForFreight,
    [AnalyticsDataType.FLEET_DEVELOPMENT]: this.store.getters.accessibleMarketsForFreight,
    [AnalyticsDataType.FLEET_UTILIZATION]: this.store.getters.accessibleMarketsForFreight,
  });

  getUnitDependingOnMarkets<T extends DataTypeInUnits, R extends UnitReturn<T>>(
    dataType: T,
    units: R[],
  ): R {
    if (dataType === AnalyticsDataType.REFINERY_CAPACITIES) {
      return units[0];
    }

    const isTonsPartOfTheUnits = new Set(units).has(UnitName.TONS as R);
    const defaultUnit = isTonsPartOfTheUnits ? UnitName.TONS : UnitName.KTONS;
    const accessibleMarkets = this.#dataTypeToAccessibleMarketsRecord[dataType];
    const isMoreThanOneMarket = accessibleMarkets.size > 1;
    if (isMoreThanOneMarket) {
      return defaultUnit as R;
    }
    if (accessibleMarkets.has(Market.LIQUIDS)) {
      return UnitName.MMBBL as R;
    }
    if (accessibleMarkets.has(Market.LNG)) {
      return UnitName.CM as R;
    }

    return defaultUnit as R;
  }

  getDefaultUnit<T extends DataTypeInUnits, R extends UnitReturn<T>>(dataType: T): R {
    const units = UNITS_BY_DOMAIN[dataType];
    if (units.length === 0) {
      throw new Error(`No default units for ${dataType}.`);
    }

    return this.getUnitDependingOnMarkets<T, R>(dataType, units as R[]);
  }

  checkIsUnitName(value: string | null): value is UnitName {
    return UnitService.isUnitName(value);
  }

  checkIsUnitOrTemporalUnitName(value: string | null): value is UnitName | TemporalUnitName {
    return UnitService.isUnitOrTemporalUnitName(value);
  }

  private static stringIsUnitName(value: string): value is UnitName {
    return new Set(Object.values(UnitName)).has(value as UnitName);
  }

  static isUnitName(value: string | null): value is UnitName {
    return value !== null && UnitService.stringIsUnitName(value);
  }

  static isUnitOrTemporalUnitName(value: string | null): value is UnitName | TemporalUnitName {
    return (
      value !== null &&
      (UnitService.stringIsUnitName(value) ||
        new Set(Object.values(TemporalUnitName)).has(value as TemporalUnitName))
    );
  }
}
