import store from 'src/store';

import type { RouteRecordRaw } from '@kpler/vue2-utils';

const userHasAccessToPrices = (): boolean =>
  store.getters.userHasPermission('commodity_prices:read') ||
  (store.getters.userHasAccessToFreightFeature &&
    store.getters.userHasPermission('freight_prices:read'));

const ThePrices = () =>
  userHasAccessToPrices()
    ? import(/* webpackChunkName: "prices" */ 'src/main/analytics/prices/ThePrices.vue')
    : import(/* webpackChunkName: "prices" */ 'src/main/analytics/prices/ThePricesPreview.vue');

const ThePricesRightPanel = () =>
  import(/* webpackChunkName: "prices" */ 'src/main/analytics/prices/ThePricesRightPanel.vue');

const pricesRoutes: RouteRecordRaw = {
  name: 'prices',
  path: 'prices',
  component: ThePrices,
  meta: { title: 'Prices' },
  children: [
    {
      name: 'prices-details',
      path: ':seriesId/details/:date',
      component: ThePricesRightPanel,
    },
  ],
};

export default pricesRoutes;
