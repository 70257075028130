#import 'src/main/graphql/search/SearchZone.fragment.gql'
#import 'src/main/graphql/search/SearchInstallation.fragment.gql'
#import 'src/main/graphql/search/SearchVessel.fragment.gql'
#import 'src/main/graphql/search/SearchProduct.fragment.gql'
#import 'src/main/graphql/search/SearchPlayer.fragment.gql'

query hydrateSearch(
  $zoneIds: [ID!]!
  $installationIds: [ID!]!
  $vesselIds: [ID!]!
  $productIds: [ID!]!
  $playerIds: [ID!]!
) {
  zonesById(ids: $zoneIds) {
    ...zone
  }
  installationsById(ids: $installationIds) {
    ...installation
  }
  vesselsById(ids: $vesselIds) {
    ...vessel
  }
  productsById(ids: $productIds) {
    ...product
  }
  playersById(ids: $playerIds) {
    ...player
  }
}
