import type { FeatureCollection, Point, MultiPolygon } from 'geojson';
import type { ProductType } from 'types/edits/product';
import type { ResourceType } from 'types/legacy-globals';
import type { ZoneType } from 'types/zone';

export enum PlatformStaticData {
  LNG = 'lng',
  LPG = 'lpg',
  COMMODITIES = 'commodities',
  DRY = 'coal',
}

export type SearchQueryParamsStaticData = {
  q: string;
  type: ResourceType | null;
};

export type BaseSearchResult = {
  id: number;
  name: string;
  resourceType: ResourceType;
  latitude?: number;
  longitude?: number;
};

export type InstallationSearchResult = BaseSearchResult & {
  platforms: PlatformStaticData[];
  isStorageSelected: boolean;
};

export type ZoneSearchResult = BaseSearchResult & {
  type: ZoneType;
  platforms: PlatformStaticData[];
};

export type UnlocodeSearch = BaseSearchResult & {
  locodeCountry: string;
  locodeLocation: string;
};

export type ProductSearch = BaseSearchResult & {
  type: ProductType;
};

export type SearchResultsStaticData = {
  installation: InstallationSearchResult[];
  player: BaseSearchResult[];
  zone: ZoneSearchResult[];
  berth: BaseSearchResult[];
  product: ProductSearch[];
  provider: BaseSearchResult[];
  vessel: BaseSearchResult[];
  unlocode: UnlocodeSearch[];
};

export enum LocationType {
  INSTALLATION = 'installation',
  ZONE = 'zone',
  BERTH = 'berth',
  TANK = 'tank',
}

export type RecentlyAddedGeojson = FeatureCollection<Point | MultiPolygon, { id: number }>;

export enum EditMode {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum OperationType {
  DELETE = 'delete',
  SAVE = 'save',
}

export type Quantity = {
  metric: number | null;
  unit: string | null;
};

export type ValidateField = { valid: boolean; message: string | null };

export type Alias = {
  alias: string;
};

export type AliasEdit = {
  alias: string | null;
  readonly: boolean;
};

export type TOptions = Array<{ id: string | null | number; name: string }>;

export type CreateApiResponse = { status: 201 };
export type EditApiResponse = { status: 200 };
export type DeleteApiResponse = { status: 204 };

type statusResponse = {
  statusCode: number;
  message: string;
};

export type multiStatusResponse = {
  statuses: statusResponse[];
};
