import { onUnmounted } from 'vue';

import type { AppStore } from 'src/store/types';

export const useShowIdsKeyboardShortcut = (store: AppStore) => {
  const toggleIds = (event: KeyboardEvent) => {
    if (event.key !== 'i' || !event.ctrlKey) {
      return;
    }
    store.dispatch('setDebug', !store.state.settings.debug);
  };

  window.addEventListener('keydown', toggleIds);

  onUnmounted(() => {
    window.removeEventListener('keydown', toggleIds);
  });
};
