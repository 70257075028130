import { createPopper } from '@popperjs/core';
import { h } from 'vue';

import BaseTooltip from 'src/components/BaseTooltip.vue';

import { createApp } from 'src/createApp';

import type { TooltipComponent, TooltipProps, TooltipState, TooltipString } from './Tooltip.types';
import type Vue from 'vue';
import type { DirectiveOptions } from 'vue';

const isComponent = (props: TooltipProps): props is TooltipComponent => 'component' in props;

const isText = (props: TooltipProps): props is TooltipString => 'text' in props;

const tooltipState = new WeakMap<HTMLElement, TooltipState>();

const onEnterFunc = (event: MouseEvent, data: TooltipProps) => {
  const { disabled } = data;

  const element = event.target as HTMLElement;
  const state = tooltipState.get(element);
  if (state === undefined) {
    throw new Error('Tooltip state is missing.');
  }

  state.active = true;

  if (disabled) {
    return;
  }

  let tooltip: Vue;
  if (isComponent(data)) {
    const { component, props, provide } = data;
    tooltip = createApp({
      provide,
      render: () => h(BaseTooltip, [h(component, { props })]),
    }).mount();
  } else if (isText(data)) {
    const { text, classes } = data;
    tooltip = createApp({
      render: () => h(BaseTooltip, { props: { classes } }, text),
    }).mount();
  } else {
    const { html, classes } = data;
    tooltip = createApp({
      render: () => h(BaseTooltip, { props: { classes }, domProps: { innerHTML: html } }),
    }).mount();
  }

  const popup = tooltip.$el as HTMLElement;
  document.querySelector('body')?.appendChild(popup);

  const popper = createPopper(element, popup, {
    placement: data.placement || 'top',
    modifiers: [
      { name: 'arrow', options: { element: '.tooltip-arrow' } },
      { name: 'offset', options: { offset: [0, 6] } },
    ],
  });

  // The popup content isn't drawn yet, so Popper.js will not place it correctly
  // We use update() to tell Popper.js to update the placement on the next UI update
  popper.update();

  state.tooltip = tooltip;
  state.popper = popper;
};

const onLeave = (event: MouseEvent) => {
  const state = tooltipState.get(event.target as HTMLElement);
  if (state === undefined) {
    throw new Error('Tooltip state is missing.');
  }

  const el = state.tooltip?.$el;
  el?.parentNode?.removeChild(el);

  state.active = false;
  state.popper?.destroy();
  state.popper = null;
  state.tooltip?.$destroy();
  state.tooltip = null;
};

const directive: DirectiveOptions = {
  bind(el, { value }: { value?: TooltipProps }) {
    if (value === undefined) {
      throw new Error('Tooltip requires a value.');
    }

    const onEnter = (event: MouseEvent) => onEnterFunc(event, value);
    const state: TooltipState = {
      popper: null,
      tooltip: null,
      active: false,
      onEnter,
    };
    tooltipState.set(el, state);

    el.addEventListener('mouseenter', onEnter);
    el.addEventListener('mouseleave', onLeave);
  },
  update(el, { value }: { value?: TooltipProps }) {
    if (value === undefined) {
      throw new Error('Tooltip requires a value.');
    }

    const state = tooltipState.get(el);
    if (state === undefined) {
      throw new Error('Tooltip state is missing.');
    }

    const onEnter = (event: MouseEvent) => onEnterFunc(event, value);
    el.removeEventListener('mouseenter', state.onEnter);
    el.addEventListener('mouseenter', onEnter);
    state.onEnter = onEnter;

    if (state.active) {
      el.dispatchEvent(new Event('mouseleave'));
      el.dispatchEvent(new Event('mouseenter'));
    }
  },
  unbind(el) {
    const state = tooltipState.get(el);
    if (state === undefined) {
      throw new Error('Tooltip state is missing.');
    }

    el.dispatchEvent(new Event('mouseleave'));
    el.removeEventListener('mouseenter', state.onEnter);
    el.removeEventListener('mouseleave', onLeave);
  },
};

export default directive;
