import { featureFlagService } from 'src/app/providers/singletons/featureFlagService';
import appRoutes from 'src/app/routes';
import tonnageListRoutes from 'src/domains/chartering/TheTonnageListWorkspace/routes';
import { emissionsRoutes } from 'src/domains/emissions';
import freightRoutes from 'src/domains/freight/common/routes/freight.routes';
import insightRoutes from 'src/domains/insight/insight.routes';
import { myAccessRoutes } from 'src/domains/myAccess';
import installationMetricsRoutes from 'src/domains/onshore-asset-monitoring/lngInstallationMetrics/installationMetrics.routes';
import { powerRoutes } from 'src/domains/power';
import refineriesRoutes from 'src/domains/refineries/routes';
import supplyDemandRoutes from 'src/domains/supplyDemand/supplyDemand.routes';
import { userManagementRoutes } from 'src/domains/userManagement';
import pricesRoutes from 'src/main/analytics/prices/prices.routes';
import dashboardRoutes from 'src/main/dashboard/dashboard.routes';
import dataRoutes from 'src/main/data/data.routes';
import mapRoutes from 'src/main/map/map.routes';
import pivotRoutes from 'src/main/pivot/pivot.routes';
import researchNewsRoutes from 'src/main/research-news/research-news.routes';
import userRoutes from 'src/main/settings/user.routes';
import store from 'src/store';

import { getAuthService } from './app/providers/singletons/authService';
import { cargoWorkspaceRoutes } from './domains/cargo';
import { complianceRoutes } from './domains/compliance/workspace';
import { freightWorkspaceRoutes } from './domains/freight/TheFreightWorkspace';
import { notificationRoutes } from './domains/notifications';
import { oilInventoriesWorkspaceRoutes } from './domains/onshore-asset-monitoring/oilInventories';
import { TheTermsOfUseOptIn } from './main/TheTermsOfUseOptIn';
import { checkIsMobileUser } from './main/helpers/device.helper';
import TheMobileDisclaimer from './public/TheMobileDisclaimer.vue';
import { getUserInfo } from './services/user.service';

import { getHomepageRoute } from 'src/helpers/homepage.helper';

// eslint-disable-next-line no-restricted-imports
import type { NavigationGuardNext, RawLocation, Route, RouteConfig } from 'vue-router';
/* eslint-disable @typescript-eslint/naming-convention */
const TheMainContainer = () => import('src/main/TheMainContainer.vue');
const LoginError = () => import('src/public/LoginError.vue');
const LoginRedirect = () => import('src/public/LoginRedirect.vue');
const FormResetPassword = () => import('src/public/FormResetPassword.vue');
const FormResetPasswordWithToken = () => import('src/public/FormResetPasswordWithToken.vue');
const ThePublicContainer = () => import('src/public/ThePublicContainer.vue');

export const authenticatedRoutes: RouteConfig[] = [
  ...appRoutes,
  mapRoutes,
  ...dashboardRoutes,
  userRoutes(() => store),
  dataRoutes,
  freightRoutes,
  pivotRoutes,
  powerRoutes,
  researchNewsRoutes,
  insightRoutes,
  pricesRoutes,
  supplyDemandRoutes,
  installationMetricsRoutes,
  refineriesRoutes,
  emissionsRoutes,
  myAccessRoutes,
  userManagementRoutes,
  complianceRoutes,
  // can't use store directly since it ends up storing `undefined`
  notificationRoutes(() => store),
  cargoWorkspaceRoutes,
  oilInventoriesWorkspaceRoutes,
  freightWorkspaceRoutes,
  ...tonnageListRoutes,
];

export const routes = [
  {
    path: '/',
    components: {
      default: TheMainContainer,
    },
    children: authenticatedRoutes,
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      const isMobileUser = checkIsMobileUser();
      if (isMobileUser) {
        return next('mobile-disclaimer');
      }
      const authService = getAuthService();
      const hasToken = await authService.hasToken();
      if (!hasToken) {
        await authService.login(to.fullPath);
        return;
      }

      if (!authService.hasUserData()) {
        await authService.loadUserData();
      }

      const userInfo = await getUserInfo();

      if (store.state.user.user && !featureFlagService?.isInitialized) {
        await featureFlagService?.init(store.state.user.user);
      }

      if (!userInfo.hasSignedTermsOfUse) {
        const nextRoute: RawLocation = { name: 'terms-of-use-optin' };
        if (to.query.redirect) {
          nextRoute.query = { redirect: to.query.redirect };
        } else if (to.fullPath !== '/') {
          nextRoute.query = { redirect: to.fullPath };
        }
        return next(nextRoute);
      }

      if (to.path === '/') {
        return next(getHomepageRoute(store.state.settings.homepage));
      }

      return next();
    },
  },
  {
    name: 'mobile-disclaimer',
    path: '/mobile-disclaimer',
    component: TheMobileDisclaimer,
    meta: { title: 'Download the application' },
  },
  {
    name: 'terms-of-use-optin',
    path: '/terms-of-use',
    component: TheTermsOfUseOptIn,
    meta: { title: 'Terms of use' },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      const authService = getAuthService();
      const hasToken = await authService.hasToken();
      if (!hasToken) {
        await authService.login(to.fullPath);
        return;
      }

      if (!authService.hasUserData()) {
        await authService.loadUserData();
      }

      const userInfo = await getUserInfo();

      if (userInfo.hasSignedTermsOfUse) {
        return next(getHomepageRoute(store.state.settings.homepage));
      }

      return next();
    },
  },
  {
    path: '/',
    components: {
      default: ThePublicContainer,
    },
    children: [
      {
        // @TODO add webserver check on token validity
        path: '/reset-password/:emailToken',
        name: 'reset-password-with-token',
        component: FormResetPasswordWithToken,
        meta: {
          title: 'Update password',
        },
      },
      {
        path: '/reset-password',
        name: 'reset-password',
        component: FormResetPassword,
        meta: {
          title: 'Reset password',
        },
      },
      {
        path: '/oauth/callback',
        component: LoginRedirect,
        meta: {
          title: 'Logging you in...',
        },
      },
      {
        path: '/oauth/error',
        name: 'loginError',
        component: LoginError,
        props: true,
        meta: {
          title: 'Login Error',
        },
      },
    ],
  },
  {
    path: '/oauth/logout',
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      const authService = getAuthService();
      await authService.handleLogoutRedirectCallback();
      next(to.query.origin as string);
    },
  },
  {
    path: '*',
    redirect: '/',
  },
];
