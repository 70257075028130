import {
  userHasAccessToInternalEmissionsFeaturesFactory,
  userHasAccessToEmissionsOverviewFactory,
} from 'src/domains/emissions/hooks/useEmissionsPermissions';
import store from 'src/store';

import { createAuthorizationHook } from 'src/helpers/router.helper';

import type { RouteRecordRaw } from '@kpler/vue2-utils';

const TheEmissionsWrapper = () =>
  import(/* webpackChunkName: "emissions" */ 'src/domains/emissions/TheEmissionsWrapper.vue');

const TheEmissionsNewContainer = () =>
  import(
    /* webpackChunkName: "emissions" */ 'src/domains/emissions/TheEmissionsNewContainerMerge.vue'
  );

const TheEmissionsNewOverviewPage = () =>
  userHasAccessToEmissionsOverviewFactory(store)()
    ? import(
        /* webpackChunkName: "emissions" */ 'src/domains/emissions/pagesM/new/overview/TheEmissionsNewOverviewPage.vue'
      )
    : import(
        /* webpackChunkName: "emissions" */ 'src/domains/emissions/pagesM/new/overview/TheEmissionsNewOverviewPagePreview.vue'
      );

const TheEmissionsNewFleetPage = () =>
  userHasAccessToEmissionsOverviewFactory(store)()
    ? import(
        /* webpackChunkName: "emissions" */ 'src/domains/emissions/pagesM/new/fleet/TheEmissionsNewFleetPage.vue'
      )
    : import(
        /* webpackChunkName: "emissions" */ 'src/domains/emissions/pagesM/new/fleet/TheEmissionsNewFleetPagePreview.vue'
      );

const TheEmissionsNewCargoPage = () =>
  userHasAccessToEmissionsOverviewFactory(store)()
    ? import(
        /* webpackChunkName: "emissions" */ 'src/domains/emissions/pagesM/new/cargo/TheEmissionsNewCargoPage.vue'
      )
    : import(
        /* webpackChunkName: "emissions" */ 'src/domains/emissions/pagesM/new/cargo/TheEmissionsNewCargoPagePreview.vue'
      );

const TheEmissionsNewVesselsPage = () =>
  userHasAccessToEmissionsOverviewFactory(store)()
    ? import(
        /* webpackChunkName: "emissions" */ 'src/domains/emissions/pagesM/new/vessels/TheEmissionsNewVesselsPage.vue'
      )
    : import(
        /* webpackChunkName: "emissions" */ 'src/domains/emissions/pagesM/new/vessels/TheEmissionsNewVesselsPagePreview.vue'
      );

const TheEmissionsVesselDetailPage = () =>
  import(
    /* webpackChunkName: "emissions" */ 'src/domains/emissions/pages/vesselDetail/TheEmissionsVesselDetailPage.vue'
  );

const TheEmissionsPlayersPage = () =>
  import(
    /* webpackChunkName: "emissions" */ 'src/domains/emissions/pages/cargoPlayers/TheEmissionsCargoPlayersPage.vue'
  );

const emissionsRoutes: RouteRecordRaw = {
  name: 'emissions',
  path: 'emissions',
  component: TheEmissionsWrapper,
  redirect: { name: 'emissions-new-fleet' },
  meta: { title: 'Maritime Carbon Emissions' },
  children: [
    {
      path: '',
      name: 'emissions-new',
      component: TheEmissionsNewContainer,
      redirect: { name: 'emissions-new-fleet' },
      children: [
        {
          name: 'emissions-new-overview',
          path: 'overview',
          component: TheEmissionsNewOverviewPage,
        },
        {
          name: 'emissions-new-fleet',
          path: 'fleet',
          component: TheEmissionsNewFleetPage,
        },
        {
          name: 'emissions-new-vessels',
          path: 'vessels',
          component: TheEmissionsNewVesselsPage,
        },
        {
          name: 'emissions-new-cargo',
          path: 'cargo',
          component: TheEmissionsNewCargoPage,
        },
      ],
    },
    {
      path: 'vessel/:vesselId',
      name: 'emissions-vessel-detail',
      component: TheEmissionsVesselDetailPage,
      beforeEnter: createAuthorizationHook(() => {
        const userHasAccessToEmissionsOverview = userHasAccessToEmissionsOverviewFactory(store);
        return userHasAccessToEmissionsOverview();
      }, 'emissions-new-fleet'),
    },
    {
      path: 'cargo-players',
      name: 'emissions-cargo-players',
      component: TheEmissionsPlayersPage,
      beforeEnter: createAuthorizationHook(() => {
        const userHasAccessToInternalEmissionsFeatures =
          userHasAccessToInternalEmissionsFeaturesFactory(store);
        return userHasAccessToInternalEmissionsFeatures();
      }, 'emissions-new-fleet'),
    },
  ],
};

export default emissionsRoutes;
