import { apolloClient } from './apollo.service';

import { axiosApi } from 'src/services/axios.service';

import { myProfile } from 'src/main/graphql/User.gql';

import type {
  MyProfileQuery,
  MyProfileQueryVariables,
  UserInfoFragment,
} from '@kpler/terminal-graphql';
import type { User } from 'types/user';

const getUser = (id: string): Promise<User> => axiosApi.get(`users/${id}`);

export const getUserInfo = async (): Promise<UserInfoFragment> => {
  const { data } = await apolloClient.query<MyProfileQuery, MyProfileQueryVariables>({
    query: myProfile,
  });
  return data.userInfo;
};

export default {
  getUser,
};
