import { useInjectStore } from 'src/store/useInjectStore';

import type { AppStore } from 'src/store/types';

const REFINERIES_FEEDSTOCK_INTERNAL = 'refineries:feedstock:data_access:full';
const REFINERES_MARGINS_INTERNAL_READ = 'refineries:margins:ui_access:full';
const REFINERIES_MARGIS_INTERNAL_TRIAL_READ = 'refineries:margins:ui_access:limited';
export const userHasAccessToRefineriesFactory = (store: AppStore) => () =>
  store.getters.userHasAccessToRefineries;

export const userHasAccessToInternalRefineriesMarginsFactory = (store: AppStore) => () =>
  store.getters.userHasPermission(REFINERES_MARGINS_INTERNAL_READ) ||
  store.getters.userHasPermission(REFINERIES_MARGIS_INTERNAL_TRIAL_READ);
export const userHasAccessToInternalRefineriesGradesFeedstockFactory = (store: AppStore) => () =>
  store.getters.userHasPermission(REFINERIES_FEEDSTOCK_INTERNAL);
export const userHasAccessToInternalRefineriesIIRFactory = (store: AppStore) => () =>
  store.getters.userHasPermission('refineries_internal') ||
  store.getters.userHasPermission('refineries:read');
export const usePermissions = () => {
  const store = useInjectStore();

  return {
    userHasAccessToRefineries: userHasAccessToRefineriesFactory(store),
    userHasAccessToInternalRefineriesMargins:
      userHasAccessToInternalRefineriesMarginsFactory(store),
    userHasAccessToGradesFeedstock: userHasAccessToInternalRefineriesGradesFeedstockFactory(store),
    userHasAccessToInternalRefineriesIIR: userHasAccessToInternalRefineriesIIRFactory(store),
  };
};
