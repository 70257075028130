import { ARRAY_REPEAT_SERIALIZER } from '@kpler/generic-utils';
import axios from 'axios';

import { createAuthInterceptor } from 'src/services/axios.service';
import { CookiesService } from 'src/services/cookies.service';
import ToastService from 'src/services/toast.service';

const VERSION_HEADERS = Object.freeze({ 'x-web-application-version': window.version });

const getApiUrlFromRuntimeFallbackOnBuildtime = (): string | undefined => {
  const urlFromRuntime = CookiesService.get('arbitrageApi');
  const urlFromBuildtime = process.env.NX_PUBLIC_ARBITRAGE_API;

  const url = urlFromRuntime ?? urlFromBuildtime;
  if (!url) {
    ToastService.toastError('Arbitrage API URL is not defined');
    console.error(
      new Error('Required env var NX_PUBLIC_ARBITRAGE_API is not defined at build time or runtime'),
    );
  }
  return url;
};

export const arbitrageAxiosClient = axios.create({
  baseURL: getApiUrlFromRuntimeFallbackOnBuildtime(),
  headers: VERSION_HEADERS,
  paramsSerializer: ARRAY_REPEAT_SERIALIZER,
});

export const setupAxiosArbitrageApiInterceptors = () => {
  arbitrageAxiosClient.interceptors.request.use(
    createAuthInterceptor({ includeUseAccessTokenHeader: false }),
  );
  arbitrageAxiosClient.interceptors.response.use(res => res.data);
};
