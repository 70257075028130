query inventoriesSeries(
  $cargoTrackingEnhancement: Boolean!
  $endDate: String!
  $fleetMetricsEnhancement: Boolean!
  $floatingStorageDuration: FloatingStorageDuration
  $granularity: Granularity!
  $locationIds: [Int!]!
  $locationResourceType: ResourceType!
  $numberOfSplits: Float
  $splitCriteria: InventoriesSplit!
  $startDate: String!
  $platform: Platform
  $droneData: Boolean
  $addEiaDatasets: Boolean
  $selection: Selection
  $droneDataAtmEnhancement: Boolean
) {
  inventoriesSeries(
    cargoTrackingEnhancement: $cargoTrackingEnhancement
    endDate: $endDate
    fleetMetricsEnhancement: $fleetMetricsEnhancement
    floatingStorageDuration: $floatingStorageDuration
    granularity: $granularity
    locationIds: $locationIds
    locationResourceType: $locationResourceType
    numberOfSplits: $numberOfSplits
    splitCriteria: $splitCriteria
    startDate: $startDate
    platform: $platform
    droneData: $droneData
    addEiaDatasets: $addEiaDatasets
    selection: $selection
    droneDataAtmEnhancement: $droneDataAtmEnhancement
  )
}

query inventoriesSnapshot(
  $cargoTrackingEnhancement: Boolean!
  $endDate: String!
  $fleetMetricsEnhancement: Boolean
  $floatingStorageDuration: FloatingStorageDuration
  $granularity: Granularity!
  $locationIds: [Int!]!
  $locationResourceType: ResourceType!
  $splitCriteria: InventoriesSnapshotSplit!
  $platform: Platform!
  $filters: StorageFilter!
  $notFilters: StorageFilter!
  $droneData: Boolean
) {
  inventoriesSnapshot(
    cargoTrackingEnhancement: $cargoTrackingEnhancement
    endDate: $endDate
    fleetMetricsEnhancement: $fleetMetricsEnhancement
    floatingStorageDuration: $floatingStorageDuration
    granularity: $granularity
    locationIds: $locationIds
    locationResourceType: $locationResourceType
    splitCriteria: $splitCriteria
    platform: $platform
    filters: $filters
    notFilters: $notFilters
    droneData: $droneData
  ) {
    ... on InventoriesSnapshot {
      volume
      granularity
      endDate
      deltaVolume
      capacity
      revisitRate
      onshoreOutflow: demand
      onshoreInflow: supply
    }
    ... on InventoriesSnapshotInstallation {
      splitBy
      details {
        ... on InventoriesSnapshotDetailBase {
          id
          name
          capacity
        }
        ... on InventoriesSnapshotDetailInstallationBase {
          volume
          deltaVolume
          lastImageDate
          penultimateImageDate
        }
        ... on InventoriesSnapshotDetailInstallationWithCargoTrackingEnhancementExtraProperties {
          volume
          deltaVolume
          lastImageDate
          penultimateImageDate
          onshoreOutflow: demand
          onshoreInflow: supply
        }
      }
    }
    ... on InventoriesSnapshotTank {
      splitBy
      details {
        ... on InventoriesSnapshotDetailBase {
          __typename
          id
          name
          capacity
        }
        ... on InventoriesSnapshotDetailTankOil {
          volume
          deltaVolume
          roofType
          tankType
          lastImageDate
        }
        ... on InventoriesSnapshotDetailTankProducts {
          volume
          deltaVolume
          roofType
          tankType
        }
      }
    }
    ... on InventoriesSnapshotCountry {
      splitBy
      details {
        id
        name
        capacity
        volume
        deltaVolume
        revisitRate
      }
    }
  }
}
