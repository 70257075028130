import {
  Granularity,
  deserializeDate,
  getFirstDayOfPeriodIncludingDate,
  getLastDayOfPeriodIncludingDate,
  serializeDate,
  toMoment,
  convertPredictivePresetToRefineriesDateRange,
} from '@kpler/terminal-utils';
import moment from 'moment';

import { FlowsProjection } from 'src/domains/cargo/flows/flows.types';
import { REFINERY_MIN_DATE } from 'src/domains/refineries/constants';
import colors from 'src/scss/colors.constants';

import type {
  AvailableCapacityTimeSeriesQuery,
  HydrateQuery,
  HydrateZonesPlayersQuery,
  OfflineEventTimeSeriesQuery,
} from '@kpler/terminal-graphql';
import { ChartGrouping, ChartType } from 'types/chart';
import type { InstallationWithResourceType } from 'types/installation';
import { ResourceType } from 'types/legacy-globals';
import type { VolumeQuantityObject } from 'types/quantity';
import type { OfflineEventColumn, RefineryCapacitiesTopFiltersMapping } from 'types/refineries';
import {
  OfflineEventCauses,
  OfflineEventStatus,
  OfflineEventTypes,
  RefineryCapacitiesMetric,
} from 'types/refineries';
import type { TimeSeriesPayload } from 'types/series';
import type { ZoneWithResourceType } from 'types/zone';

export const OFFLINE_EVENT_LABEL_MAP: { readonly [key in OfflineEventColumn]: string } =
  Object.freeze({
    eventId: 'Event ID',
    eventKind: 'Event kind',
    unitName: 'Unit name',
    unitId: 'Unit ID',
    ownerName: 'Owner name',
    plantId: 'Installation secondary ID',
    plantName: 'Installation secondary name',
    physCity: 'City',
    pCounty: 'County',
    plantState: 'State',
    country: 'Country',
    marketReg: 'Market region',
    worldReg: 'World region',
    uStatus: 'Unit status',
    primFuel: 'Primary fuel',
    secndFuel: 'Secondary fuel',
    fuelGroup: 'Fuel group',
    uCapacity: 'Unit capacity (bd)',
    capOffline: 'Offline capacity (bd)',
    capUom: 'Unit',
    powerUsag: 'Power usage',
    startDate: 'Start',
    endDate: 'End',
    eDuration: 'Duration',
    eventType: 'Event type',
    precision: 'Precision',
    eCause: 'Event cause',
    eStatus: 'Event status',
    prevStart: 'Prev start',
    prevEnd: 'Prev end',
    heatrate: 'Heat rate',
    elecondesc: 'Electric utility connection name',
    utypeDesc: 'Unit type',
    paddReg: 'PADD region',
    isortoregion: 'ISO/RTO region',
    pTradeRegion: 'Trade region',
    mmcfDemand: 'MMCF demand',
    gasRegion: 'Gas region',
    comments: 'Comments',
    confirm: 'Confirm',
    parentname: 'Parent name',
    latitude: 'Latitude',
    longitude: 'Longitude',
    liveDate: 'Live date',
    releaseDt: 'Release date',
    installationName: 'Installation name',
    installationId: 'Installation ID',
    unitTypeShortName: 'Unit type',
  });

export const MIN_DATE = moment.utc().subtract(10, 'years').endOf('month');
export const MAX_DATE = moment.utc().add(10, 'years').endOf('month');

export const eventTypeOptions = Object.values(OfflineEventTypes).map(x => ({ id: x, name: x }));
export const eventCauseOptions = Object.values(OfflineEventCauses).map(x => ({ id: x, name: x }));
export const eventStatusOptions = Object.values(OfflineEventStatus).map(x => ({ id: x, name: x }));

export const offlineEventsTimeSeriesPayloadGraphqlToFrontend = (
  sourceQuery: OfflineEventTimeSeriesQuery,
): TimeSeriesPayload<VolumeQuantityObject> => {
  const source = sourceQuery.offlineEventAnalytics;
  return {
    metadata: {
      datasetNames: source.metadata.datasetNames,
      splitValueNames: source.metadata.splitValueNames,
    },
    series: source.series.map(serie => ({
      date: serie.date,
      datasets: serie.datasets.map(dataset => ({
        datasetName: dataset.datasetName as string,
        values: dataset.values as VolumeQuantityObject,
        splitValues: dataset.splitValues?.map(splitValue => ({
          name: splitValue.name,
          id: splitValue.splitId,
          values: splitValue.values as VolumeQuantityObject,
        })),
      })),
    })),
  };
};

export const availableCapacityTimeSeriesPayloadGraphqlToFrontend = (
  sourceQuery: AvailableCapacityTimeSeriesQuery,
): TimeSeriesPayload<VolumeQuantityObject> => {
  const source = sourceQuery.availableCapacitiesAnalytics;
  return {
    metadata: {
      datasetNames: source.metadata.datasetNames,
    },
    series: source.series.map(serie => ({
      date: serie.date,
      datasets: serie.datasets.map(dataset => ({
        datasetName: dataset.datasetName as string,
        values: dataset.values as VolumeQuantityObject,
      })),
    })),
  };
};

export function nullIfEmpty<T>(arr: readonly T[]): readonly T[] | null;
export function nullIfEmpty<T>(arr: T[]): T[] | null;
export function nullIfEmpty<T>(arr: readonly T[] | T[]): readonly T[] | T[] | null {
  if (arr.length === 0) {
    return null;
  }
  return arr;
}

export function nullIfFullOrEmpty<T>(arr: readonly T[], fullSize: number): readonly T[] | null;
export function nullIfFullOrEmpty<T>(arr: T[], fullSize: number): T[] | null;
export function nullIfFullOrEmpty<T>(
  arr: readonly T[] | T[],
  fullSize: number,
): readonly T[] | T[] | null {
  if (arr.length === fullSize || arr.length === 0) {
    return null;
  }
  return arr;
}

const getEventsConfig = (dataset: string) => ({
  label: dataset,
  key: dataset,
  series: [
    {
      type: ChartType.BAR,
      key: dataset,
    },
  ],
  grouping: ChartGrouping.STACKED,
  average: null,
});

const getCapacityConfig = (dataset: string) => ({
  label: dataset,
  key: dataset,
  series: [
    { type: ChartType.BAR, key: dataset, label: 'Available capacity' },
    {
      type: ChartType.LINE,
      key: `capa_${dataset}`,
      color: colors.graphColors[9],
      label: 'Total capacity',
    },
  ],
  average: null,
  grouping: ChartGrouping.STACKED,
});

export const getRefineriesChartConfig = (datasets: string[], metric: RefineryCapacitiesMetric) => ({
  facets: datasets
    .filter(dataset => !dataset.includes('capa_'))
    .map(metric === RefineryCapacitiesMetric.EVENTS ? getEventsConfig : getCapacityConfig),
});

export const getAreasFromHydratedResponse = (
  hydratedResponse: HydrateQuery | HydrateZonesPlayersQuery,
) => {
  const { installationsById, zonesById } = hydratedResponse;
  const installationIds = installationsById.map(i => i.id);
  const zoneIds = zonesById.map(i => i.id);

  const installationsInfo = Object.fromEntries(
    installationsById.map(installation => [installation.id, installation]),
  );

  const installations: InstallationWithResourceType[] = installationIds.map(installation => ({
    ...installationsInfo[installation],
    resourceType: ResourceType.INSTALLATION,
  }));

  const zonesInfo = Object.fromEntries(zonesById.map(zone => [zone.id, zone]));

  const zones: ZoneWithResourceType[] = zoneIds.map(zone => ({
    ...zonesInfo[zone],
    resourceType: ResourceType.ZONE,
  }));

  return [...installations, ...zones];
};

export const getRefineriesMaxEndDate = (showFutureData: boolean, granularity: Granularity) => {
  if (showFutureData) {
    return moment.utc().add(1, 'year').endOf('month');
  }
  return [Granularity.MONTHS, Granularity.YEARS].includes(granularity) && moment.utc().date() < 3
    ? moment.utc().subtract(1, 'month').endOf('month')
    : moment.utc();
};

export const getRefineryDatesFromFilters = (filters: RefineryCapacitiesTopFiltersMapping) => {
  const showFuture = filters.projection === FlowsProjection.PREDICTIVE;
  const minDate = deserializeDate(REFINERY_MIN_DATE);
  const maxDate = getRefineriesMaxEndDate(showFuture, filters.granularity);

  const { startDate, endDate } =
    typeof filters.dateRange === 'string'
      ? convertPredictivePresetToRefineriesDateRange(
          filters.dateRange,
          minDate,
          maxDate,
          showFuture,
        )
      : filters.dateRange;

  const lastDayOfPeriod = getLastDayOfPeriodIncludingDate(endDate, filters.granularity);
  const shouldUseTodayAsEndDate =
    filters.projection === FlowsProjection.ACTUAL &&
    moment.utc(lastDayOfPeriod).isAfter(moment.utc());

  return {
    startDate: getFirstDayOfPeriodIncludingDate(startDate, filters.granularity),
    endDate: shouldUseTodayAsEndDate ? serializeDate(toMoment()) : lastDayOfPeriod,
  };
};
