import type { RouteRecordRaw } from '@kpler/vue2-utils';

const Forbidden = () => import('src/app/Forbidden.vue');

const REDIRECTIONS = [
  {
    path: 'analytics/flows*',
    redirect: 'cargo/flows*',
  },
  {
    path: 'analytics/fleet-metrics*',
    redirect: 'cargo/fleet-metrics*',
  },
  {
    path: 'analytics/ballast-capacity*',
    redirect: 'freight/ballast-capacity*',
  },
  {
    path: 'analytics/congestion*',
    redirect: 'freight/congestion*',
  },
  {
    path: 'analytics/fleet-development*',
    redirect: 'freight/fleet-development*',
  },
  {
    path: 'analytics/fleet-utilization*',
    redirect: 'freight/fleet-utilization*',
  },
  {
    path: 'analytics/freight-metrics*',
    redirect: 'freight/freight-metrics*',
  },
  {
    path: 'analytics/inventories*',
    redirect: 'oil-inventories/overview*',
  },
  {
    path: 'analytics/refineries-capacities*',
    redirect: 'refineries/capacities*',
  },
  {
    path: 'data/refineries-events*',
    redirect: 'refineries/events*',
  },
  {
    path: 'dashboard/drone-inventories*',
    redirect: 'oil-inventories/drones*',
  },
  {
    path: 'dashboard/inventories-by-region*',
    redirect: 'lng-installation-metrics/inventories-overview*',
  },
  {
    path: 'dashboard/overview',
    redirect: 'dashboard/overview-lng',
  },
  {
    path: 'map/search/capacity',
    redirect: 'map/search/installations',
  },
  {
    path: 'map/search/capacity/countries*',
    redirect: 'map/search/installations/countries*',
  },
  {
    path: 'map/search/capacity/installations*',
    redirect: 'map/search/installations/terminals*',
  },
  {
    path: 'map/search/capacity/shareholders*',
    redirect: 'map/search/installations/shareholders*',
  },
];

export const AppRoutes = {
  FORBIDDEN: '403',
} as const;

const appRoutes: RouteRecordRaw[] = [
  ...REDIRECTIONS,
  {
    path: '403',
    name: AppRoutes.FORBIDDEN,
    component: Forbidden,
    meta: {
      title: 'Forbidden page',
    },
  },
];

export default appRoutes;
