import { isDefined } from '@kpler/generic-utils';
import { CargoState, VesselStatus } from '@kpler/terminal-graphql';

import type {
  InstallationWithCapacitiesFragment,
  WidgetVesselFragment,
} from '@kpler/terminal-graphql';
import type { Installation } from 'types/installation';
import { InstallationTypeCategory, InstallationActivityType } from 'types/installation';
import type { CargoProduct } from 'types/map';
import type { VesselMapPayload } from 'types/vessel';
import { VesselStatePayload, VesselStateCategory } from 'types/vessel';

const map: { [key: string]: InstallationTypeCategory } = {
  'OPEC Refinery': InstallationTypeCategory.EXPORT,
  'Crude Oil Production': InstallationTypeCategory.EXPORT,
  'Crude Oil Export Terminal': InstallationTypeCategory.EXPORT,
  'Oil Product Export Terminal': InstallationTypeCategory.EXPORT,
  'Oil Export Terminal': InstallationTypeCategory.EXPORT,
  'LPG Production': InstallationTypeCategory.EXPORT,
  'LPG Chemicals Export Terminal': InstallationTypeCategory.EXPORT,
  'LPG Export Terminal': InstallationTypeCategory.EXPORT,
  'LPG Multi Purpose Export Terminal': InstallationTypeCategory.EXPORT,
  'Ammonia Export Terminal': InstallationTypeCategory.EXPORT,
  'Clean Products Production': InstallationTypeCategory.EXPORT,
  'Clean Products Export Terminal': InstallationTypeCategory.EXPORT,
  'Oil Products Production': InstallationTypeCategory.EXPORT,
  'Dirty Products Export Terminal': InstallationTypeCategory.EXPORT,
  'Coal Production': InstallationTypeCategory.EXPORT,
  'Iron Ore Production': InstallationTypeCategory.EXPORT,
  'Coal Export Terminal': InstallationTypeCategory.EXPORT,
  'Other Export Terminal': InstallationTypeCategory.EXPORT,
  'Multi-Purpose Export Terminal': InstallationTypeCategory.EXPORT,
  Export: InstallationTypeCategory.EXPORT,

  'Non OPEC Refinery': InstallationTypeCategory.IMPORT,
  'Crude Oil Import Terminal': InstallationTypeCategory.IMPORT,
  'Oil Product Import Terminal': InstallationTypeCategory.IMPORT,
  'Oil Import Terminal': InstallationTypeCategory.IMPORT,
  'Crude Oil Consumption': InstallationTypeCategory.IMPORT,
  'LPG Cracker': InstallationTypeCategory.IMPORT,
  'LPG Chemicals Import Terminal': InstallationTypeCategory.IMPORT,
  'LPG Multi Purpose Import Terminal': InstallationTypeCategory.IMPORT,
  'LPG Import Terminal': InstallationTypeCategory.IMPORT,
  'LPG Consumption': InstallationTypeCategory.IMPORT,
  'Ammonia Import Terminal': InstallationTypeCategory.IMPORT,
  'Clean Products Consumption': InstallationTypeCategory.IMPORT,
  'Clean Products Import Terminal': InstallationTypeCategory.IMPORT,
  'Oil Products Consumption': InstallationTypeCategory.IMPORT,
  'Dirty Products Import Terminal': InstallationTypeCategory.IMPORT,
  'Coal Consumption': InstallationTypeCategory.IMPORT,
  'Iron Ore Consumption': InstallationTypeCategory.IMPORT,
  'Coal Import Terminal': InstallationTypeCategory.IMPORT,
  'Other Import Terminal': InstallationTypeCategory.IMPORT,
  'Multi-Purpose Import Terminal': InstallationTypeCategory.IMPORT,
  Import: InstallationTypeCategory.IMPORT,

  Shipyard: InstallationTypeCategory.SHIPYARD,
};

export const installationTypeToCategory = (type: string): InstallationTypeCategory => map[type];

export const installationToActivity = (installation: {
  hasBerths: boolean;
  type: string;
  isStorageSelected: boolean;
}): InstallationActivityType => {
  if (map[installation.type] === InstallationTypeCategory.SHIPYARD) {
    return InstallationActivityType.SHIPYARD;
  }
  if (!installation.isStorageSelected) {
    if (map[installation.type] === InstallationTypeCategory.IMPORT) {
      return InstallationActivityType.IMPORT_CARGO_ONLY;
    }
    return InstallationActivityType.EXPORT_CARGO_ONLY;
  }
  if (installation.isStorageSelected && !installation.hasBerths) {
    return InstallationActivityType.STORAGE_ONLY;
  }
  if (map[installation.type] === InstallationTypeCategory.IMPORT) {
    return InstallationActivityType.IMPORT_STORAGE_CARGO;
  }
  return InstallationActivityType.EXPORT_STORAGE_CARGO;
};

export const vesselStatusToTimeClassFragment = (status: string): 'past' | 'future' | 'current' => {
  if (status === 'Inactive') {
    return 'past';
  }
  if (status === 'Under Construction') {
    return 'future';
  }
  return 'current';
};

export const installationToTimeClassFragment = (
  installation: Installation | InstallationWithCapacitiesFragment,
): 'past' | 'future' | 'current' => {
  const { status } = installation;
  if (['Decommissioned', 'Suspended'].includes(status)) {
    return 'past';
  }
  if (['Under Construction', 'Planned and Proposed', 'Approved', 'Speculative'].includes(status)) {
    return 'future';
  }
  return 'current';
};

const isVesselFragment = (v: VesselMapPayload | WidgetVesselFragment): v is WidgetVesselFragment =>
  (v as WidgetVesselFragment).__typename !== undefined;

export const getVesselCargoState = (
  vessel: VesselMapPayload | WidgetVesselFragment,
): CargoState => {
  const loadedCondition = isVesselFragment(vessel)
    ? vessel.lastPosition?.currentCargo?.products !== undefined
    : vessel.lastPosition?.currentCargo?.loaded;

  return loadedCondition ? CargoState.Loaded : CargoState.Ballast;
};

export const getVesselCargoProducts = (vessel: VesselMapPayload): readonly CargoProduct[] =>
  vessel.lastPosition?.currentCargo?.products || [];

export const getVesselStatus = (
  vessel: VesselMapPayload | WidgetVesselFragment,
  showOpenVessels: boolean,
): VesselStatus => {
  if (vessel.isFloatingStorage) {
    return VesselStatus.FloatingStorage;
  }

  if (showOpenVessels && vessel.isOpen) {
    return VesselStatus.Open;
  }
  if (vessel.state === VesselStatePayload.UNDER_CONSTRUCTION) {
    return VesselStatus.UnderConstruction;
  }

  if (vessel.state === VesselStatePayload.INACTIVE) {
    return VesselStatus.Inactive;
  }
  return VesselStatus.InService;
};

// @TODO remove
export const getVesselStateCategory = (
  vessel: VesselMapPayload | WidgetVesselFragment,
  showOpenVessels: boolean,
): VesselStateCategory => {
  // @TODO state on hover
  // It would be preferable to change these settings in the paint layer instead of the layout layer:
  // https://docs.mapbox.com/mapbox-gl-js/style-spec/#layer-paint
  // => Changes to a paint property are cheap

  if (vessel.isFloatingStorage) {
    return VesselStateCategory.FLOATING_STORAGE;
  }

  if (showOpenVessels && vessel.isOpen) {
    return VesselStateCategory.OPEN;
  }

  if (vessel.state === VesselStatePayload.UNDER_CONSTRUCTION) {
    return VesselStateCategory.CONSTRUCTION;
  }

  // PERFORMANCE_FIX related to mobile vessel fetching
  if (!isDefined(vessel.name)) {
    return vessel.state === VesselStatePayload.LOADED
      ? VesselStateCategory.LOADED
      : VesselStateCategory.BALLAST;
  }

  const loadedCondition = isVesselFragment(vessel)
    ? vessel.lastPosition?.currentCargo?.products !== undefined
    : vessel.lastPosition?.currentCargo?.loaded;

  return loadedCondition ? VesselStateCategory.LOADED : VesselStateCategory.BALLAST;
};

// @TODO remove
export const isVesselActive = (state: string): boolean => state !== VesselStatePayload.INACTIVE;
