import type {
  CommoditySection,
  PropsPageWithTags,
  PropsPageWithReportType,
  ContentOwnership,
  PropsPageWithTagsAndContentOwnership,
} from 'src/domains/insight/common/components/navigationMenu/types';

export const buildPropsWithTags = (section: CommoditySection): PropsPageWithTags => {
  const props: PropsPageWithTags = {
    title: section.title,
    hashTagIds: [],
    parentCommodityTagIds: [],
  };
  if (section.parentCommodityTagIds && section.parentCommodityTagIds.length > 0) {
    props.parentCommodityTagIds = section.parentCommodityTagIds;
  }
  if (section.hashtagIds && section.hashtagIds.length > 0) {
    props.hashTagIds = section.hashtagIds;
  }
  return props;
};

export const buildPropsWithTagsAndContentOwnership = (
  section: CommoditySection,
  contentOwnership: ContentOwnership,
): PropsPageWithTagsAndContentOwnership => {
  const commodityTagProps = buildPropsWithTags(section);
  return {
    ...commodityTagProps,
    contentOwnership,
  };
};

export const buildPropsWithReportType = (
  reportType: string,
  section: string,
  type: 'Weekly' | 'Monthly',
): PropsPageWithReportType => ({
  title: reportType,
  breadcrumbs: [section, `${type} Reports`],
  reportType,
});
