import { InstallationTypeFilter } from '@kpler/terminal-graphql';

import { installationToActivity } from 'src/main/helpers/itemIcon.helper';

import type { Market } from '@kpler/web-ui';
import type { InstallationMapPayload } from 'types/installation';
import { InstallationActivityType } from 'types/installation';
import type { MapFiltersPayload, MapVessel } from 'types/map';
import { ZoneType } from 'types/zone';

export const getInstallationStatus = (installation: InstallationMapPayload): string =>
  installation.status;

export const mainInstallationFiltersFilter = (visibleItems: MapFiltersPayload | null) => {
  const visibleInstallations = visibleItems === null ? null : new Set(visibleItems.installationIds);
  return (installation: InstallationMapPayload): boolean =>
    visibleInstallations === null || visibleInstallations.has(installation.id);
};

export const createFilterHideIfMoreThanTwoVesselsAreSelected = (
  selectedVessels: readonly MapVessel[],
) => {
  const selectedVesselsSet = new Set(selectedVessels.map(x => x.id));
  return (): boolean => selectedVesselsSet.size < 2;
};

export const selectedInstallationsFilter = (selectedInstallationIds: readonly number[]) => {
  const selectedInstallationsSet = new Set(selectedInstallationIds);
  return (installation: InstallationMapPayload): boolean =>
    selectedInstallationsSet.has(installation.id);
};

const ACTIVITY_TYPE_MAP: {
  readonly [key in
    | InstallationActivityType
    | ZoneType.ANCHORAGE]: readonly InstallationTypeFilter[];
} = Object.freeze({
  [InstallationActivityType.STORAGE_ONLY]: [InstallationTypeFilter.Storage],
  [InstallationActivityType.IMPORT_CARGO_ONLY]: [InstallationTypeFilter.Import],
  [InstallationActivityType.EXPORT_CARGO_ONLY]: [InstallationTypeFilter.Export],
  [InstallationActivityType.IMPORT_STORAGE_CARGO]: [
    InstallationTypeFilter.Storage,
    InstallationTypeFilter.Import,
  ],
  [InstallationActivityType.EXPORT_STORAGE_CARGO]: [
    InstallationTypeFilter.Storage,
    InstallationTypeFilter.Export,
  ],
  [InstallationActivityType.SHIPYARD]: [InstallationTypeFilter.Shipyard],
  [ZoneType.ANCHORAGE]: [InstallationTypeFilter.Anchorage],
});

export const installationTypesFilter =
  (installationTypes: Set<InstallationTypeFilter>) =>
  (installation: InstallationMapPayload): boolean => {
    const refineryFilterSelectedAndInstallationIsRefinery =
      installationTypes.has(InstallationTypeFilter.Refinery) && installation.isRefinery;

    if (installationTypes.size === 0) {
      return true;
    }

    return (
      refineryFilterSelectedAndInstallationIsRefinery ||
      [...installationTypes].some(type =>
        ACTIVITY_TYPE_MAP[installationToActivity(installation)].includes(type),
      )
    );
  };

export const marketFilter =
  (markets: Set<Market>) =>
  (installation: InstallationMapPayload): boolean =>
    installation.commodityTypes.some(market => markets.has(market));
