import type { Market } from '@kpler/web-ui';
import type { MapView } from 'src/features/map/enums';
import type { EmptyObject, Homepage, VesselTypeClassification } from 'types/legacy-globals';
import type { MapBackground } from 'types/map';
import type { UnitName } from 'types/unit';

export enum MapDefaultTab {
  VOYAGES = 'voyages',
  PORT_CALLS = 'portCalls',
  TRADES = 'trades',
  INVENTORIES = 'inventories',
}

export enum FreightFleet {
  GAS = 'gas',
  DRY = 'dry',
  LIQUIDS = 'liquids',
}

export type HomepageConfig =
  | { page: Homepage.DASHBOARD; dashboardId: string }
  | { page: Homepage.RESEARCH_NEWS }
  | { page: Homepage.MAP }
  | { page: Homepage.FLOWS }
  | { page: Homepage.FLEET_METRICS }
  | { page: Homepage.INVENTORIES }
  | { page: Homepage.CONGESTION }
  | { page: Homepage.BALLAST_CAPACITY }
  | { page: Homepage.FLEET_DEVELOPMENT }
  | { page: Homepage.FLEET_UTILIZATION }
  | { page: Homepage.FREIGHT_METRICS }
  | { page: Homepage.SUPPLY_DEMAND };

export type LegacyHomepageConfig =
  | Homepage.MAP
  | Homepage.FLOWS
  | Homepage.FLEET_METRICS
  | Homepage.INVENTORIES
  | Homepage.CONGESTION
  | Homepage.BALLAST_CAPACITY
  | Homepage.FLEET_DEVELOPMENT
  | Homepage.FLEET_UTILIZATION
  | Homepage.FREIGHT_METRICS;

export type UserSettings = {
  map: {
    automaticMapType: boolean;
    autoZoom: boolean;
    highlightVesselSelection: boolean;
    labelVisibility: boolean;
    priceVisibility: boolean;
    weatherVisibility: boolean;
    internalOnly: {
      zoneRangesDisplayed: boolean;
      displayBadCoverage: boolean;
      fusionTableLayers?: Record<string, unknown>;
    };
    background?: MapBackground;
    defaultTab?: MapDefaultTab;
    portsAndBerths?: boolean;
    showLabels?: boolean;
    darkMap?: boolean;
    markets: Market[];
    view?: MapView;
  };
  data: {
    forecast: boolean;
    internalOnly: {
      ids: boolean;
      hasVesselFeeder: boolean;
      hasVesselFeederStorage: boolean;
      isDroneDataAtmEnhancementActivated: boolean;
    };
  };
  l10n?: {
    language: string;
  };
  layout?: {
    leftPanel: boolean;
  };
  /** @deprecated removed in the merge platform */
  productSwitch: {
    isRootActivated: boolean;
    products: string[];
  };
  pricesWelcomeModalVisibility?: boolean;
  isProductEstimationActivated?: boolean;
  vesselSizeCustomRangesByDataType?: Record<string, [number | null, number | null]>;
  homepage: HomepageConfig | LegacyHomepageConfig;
  vesselTypeClassification?: VesselTypeClassification.OIL | VesselTypeClassification.CPP;
  trackedCargo: {
    activeUnit: {
      cargo: UnitName;
    };
  };
  insight?: {
    darkMode: boolean;
  };
};

export type SettingsResponse = UserSettings | EmptyObject;

export type CommercialProduct = {
  id: string;
  isTrial: boolean;
};

export type ContractType = {
  fullname: string;
  name: string;
  order: number;
};

export type Service = {
  commodityTypes: Array<{
    id: number;
    isTrial: boolean;
  }>;
  name: string;
  products: CommercialProduct[];
};

export type RawServiceFromApi = {
  commodityTypes: Array<{
    id: number;
    isTrial: boolean;
  }>;
  name: string;
  products: Array<{
    id: number | null;
    isTrial: boolean;
  }>;
};

export type User = {
  id: string;
  accountId: string;
  firstName: string;
  lastName: string;
  email: string;
  permissions: string[];
  services: Service[];
};

export type RawUserFromApi = {
  id: string;
  accountId: string;
  firstName: string;
  lastName: string;
  email: string;
  permissions: string[];
  services: RawServiceFromApi[];
};

export type UserEditInfo = {
  createdAt: string | null;
  createdBy: string | null;
  updatedAt: string | null;
  updatedByAnalyst: string | null;
};

export type UserInfoForm = {
  city: string;
  country: string;
  jobTitle: string;
  jobCategoryId: string | null;
  jobCategoryOther: string;
  phoneNumber: string;
  linkedinProfileUrl: string;
  iceNumber: string;
};
