export const installationIds = {
  FREEPORT_LNG_REGASIFICATION: 3519,
  FREEPORT: 3604,
  SABINE_PASS_LNG_TERMINAL_LIQUEFACTION: 3605,
  SABINE_PASS_LNG_TERMINAL_REGASIFICATION: 3527,
  CAMERON: 3663,
  COVE_POINT: 3786,
  CALCACIEU_PASS: 3842,
  ELBA_ISLAND: 3878,
  CORPUS_CHRISTI: 3706,
  SOUTH_HOOK: 3514,
  GRAIN: 3513,
  DRAGON: 3512,
  GATE: 3490,
  ZEEBRUGGE: 3430,
  MONTOIR: 3448,
  DUNKERQUE: 3445,
  FOS_CAVAOU: 3446,
  FOS_TONKIN: 3447,
  BILBAO: 3497,
  CARTAGENA: 3498,
  SAGUNTO: 3499,
  BARCELONA: 3500,
  HUELVA: 3503,
  MUGARDOS: 3504,
  REVITHOUSSA: 3449,
  KRK_LNG_FSRU: 3851,
  SINES: 3492,
  KLAIPEDA: 3729,
  SWINOUJSCIE: 3491,
  TOSCANA: 3455,
  LA_SPEZIA: 3457,
  ROVIGO: 3456,
  QCLNG: 3582,
  APLNG: 3583,
  GLNG: 3584,
  WILHELMSHAVEN: 3636,
  EEMSENERGY: 10872,
  BRUNSBUTTEL: 10979,
  LUBMIN: 11062,
  PIOMBINO: 11163,
  MUKRAN: 11558,
  ALEXANDROUPOLIS: 3810,
  MURMANSK: 9753,
  KAMCHATKA: 10579,
  CALCASIEU_PASS: 3842,
  EL_MUSEL: 3501,
  LE_HAVRE: 10907,
  PLAQUEMINES: 7077,
};
