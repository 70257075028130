import { AppRoutes } from 'src/app/routes';
import store from 'src/store';

import type { RouteRecordRaw } from '@kpler/vue2-utils';

const ThePivotContainer = () =>
  import(/* webpackChunkName: "pivot" */ 'src/main/pivot/ThePivotContainer.vue');

const pivotRoutes: RouteRecordRaw = {
  name: 'pivot',
  path: 'pivot/:id',
  component: ThePivotContainer,
  meta: { title: 'Pivot' },
  props: route => ({ worksheetId: String(route.params.id) }),
  beforeEnter: (to, from, next) => {
    if (!store.getters.userHasPermission('pivot_table:read')) {
      return next({ name: AppRoutes.FORBIDDEN });
    }
    return next();
  },
};

export default pivotRoutes;
