import useUpgradeModal from 'src/domains/insight/common/components/upgradePackages/useUpgradeModal';
import { INSIGHT_HOMEPAGE_ROUTE } from 'src/domains/insight/insight.constants';
import store from 'src/store';

import { forceRefreshGuard } from 'src/services/appUpdate.service';

import type { NavigationGuardNextRaw } from '@kpler/vue2-utils';
import type { RouteLocationNormalized } from '@kpler/web-ui';

const { toggleModal } = useUpgradeModal();

export const checkUserAccess =
  (permissions: string[]) =>
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNextRaw) => {
    const nextWithRefresh = forceRefreshGuard(to, next);
    if (permissions.length > 0 && !store.getters.userHasOnePermission(permissions)) {
      toggleModal();
      return nextWithRefresh({ name: INSIGHT_HOMEPAGE_ROUTE });
    }
    return nextWithRefresh();
  };
