import { AppRoutes } from 'src/app/routes';
import store from 'src/store';

import type { RouteRecordRaw } from '@kpler/vue2-utils';

const TheDataContainer = () =>
  import(/* webpackChunkName: "graphs" */ 'src/main/data/TheDataContainer.vue');

const TheFixtures = () =>
  store.getters.userHasPermission('fixture_data:read')
    ? import(/* webpackChunkName: "fixtures" */ 'src/main/data/fixtures/TheFixtures.vue')
    : import(/* webpackChunkName: "fixtures" */ 'src/main/data/fixtures/TheFixturesPreview.vue');

const TheRefineriesEventsData = () =>
  import(
    /* webpackChunkName: "refineries" */ 'src/domains/refineries/Tabs/Events/TheRefineriesEventsData.vue'
  );

const dataRoutes: RouteRecordRaw = {
  name: 'data',
  path: 'data',
  component: TheDataContainer,
  redirect: 'data/fixtures',
  children: [
    {
      name: 'fixtures',
      path: 'fixtures',
      component: TheFixtures,
      meta: { title: 'Fixtures' },
    },
    {
      name: 'refineries-events',
      path: 'refineries-events',
      component: TheRefineriesEventsData,
      meta: { title: 'Refineries events' },
      beforeEnter: (to, from, next) => {
        if (!store.getters.userHasPermission('refineries:read')) {
          return next({ name: AppRoutes.FORBIDDEN });
        }
        return next({ name: 'refineries-workspace-events' });
      },
    },
  ],
};

export default dataRoutes;
