import type {
  CommoditySection,
  CommoditySlug,
} from 'src/domains/insight/common/components/navigationMenu/types';

// TODO: Map weeklyReportToPermission and monthlyReportToPermission these to ids or slugs
export const weeklyReportToPermission: Record<string, string> = {
  'Crude Oil': 'insight:reports:crude-oil-weekly:read',
  'Tankers Weekly': 'insight:reports:tankers-weekly:read',
  'Refined Products': 'insight:reports:refined-products-weekly:read',
  'Refinery Margins': 'insight:reports:refinery-margins-weekly:read',
  'Natural Gas and LNG Weekly': 'insight:reports:natural-gas-lng-weekly:read',
  'Dry Bulks Weekly': 'insight:reports:dry-bulks-weekly:read',
  'Cross Commodity Weekly': 'insight:reports:cross-commodity-weekly:read',
  'Macro Weekly': 'insight:reports:macro-weekly:read',
};

export const monthlyReportToPermission: Record<string, string> = {
  'Bottom of the Barrel Monthly': 'insight:reports:bottom-of-the-barrel-monthly:read',
  'Middle of the Barrel Monthly': 'insight:reports:middle-of-the-barrel-monthly:read',
  'Top of the Barrel Monthly': 'insight:reports:top-of-the-barrel-monthly:read',
  'The Refined Products View': 'insight:reports:refined-products-global-review:read',
  'Tankers Monthly': 'insight:reports:tankers-monthly:read',
  'Crude Aggregated Flows and Inventories': 'insight:reports:crude-oil-barrel:read',
  'The Crude View': 'insight:reports:the-crude-view:read',
  'Crude Oil Barrel': 'insight:reports:crude-oil-barrel:read',
  'LNG Spotlight': 'insight:reports:lng-spotlight:read',
  'LNG Monthly': 'insight:reports:lng-and-natural-gas-monthly:read',
  'European Gas': 'insight:reports:natural-gas-us-and-europe-monthly:read',
  'Thermal & Met Coal Monthly': 'insight:reports:thermal-and-met-coal-monthly:read',
  'Commodity Prospects Monthly': 'insight:reports:cross-commodity-monthly:read',
  'Commodity Geopolitics': 'insight:reports:cross-commodity-monthly:read',
  'Iron Ore Monthly': 'insight:reports:iron-ore-monthly:read',
  'Grains Monthly': 'insight:reports:grains-monthly:read',
  'Risk & Compliance Monthly': 'insight:reports:risk-and-compliance-monthly:read',
  'Biofuels Monthly': 'insight:reports:biofuels-monthly:read',
  'OPEC+ in Focus': 'insight:reports:opec+-in-focus-monthly:read',
};

export const commodityTitleToNewsPermissions: Record<CommoditySlug, string[]> = {
  oil: [],
  'gas-lng': ['insight:news:lng:read'],
  dry: [],
  'cross-commodities': [],
  'risk-and-compliance': ['insight:news:riskandcompliance:read'],
  power: [],
};

export const commodityTitleToResearchUpdatesPermissions: Record<CommoditySlug, string[]> = {
  oil: [
    'insight:research-updates:liquids:read',
    'insight:research-updates:petchems:read',
    'insight:research-updates:lpg:read',
  ],
  'gas-lng': ['insight:research-updates:lng:read'],
  dry: ['insight:research-updates:dry:read'],
  'cross-commodities': [],
  'risk-and-compliance': ['insight:research-updates:riskandcompliance:read'],
  power: ['insight:research-updates:power:read'],
};

export const commodityTitleToWebinarPermissions: Record<CommoditySlug, string[]> = {
  oil: ['insight:webinars:liquids:read', 'insight:webinars:petchems:read'],
  'gas-lng': ['insight:webinars:lng:read'],
  dry: ['insight:webinars:dry:read'],
  'cross-commodities': ['insight:webinars:geopolitical:read'],
  'risk-and-compliance': [],
  power: [],
};

export const getPermissionsFromCommodity = (commodity: CommoditySection) => {
  const weeklyReportsPermissions = commodity.weeklyReport.map(
    report => weeklyReportToPermission[report],
  );
  const monthlyReportsPermissions = commodity.monthlyReport.map(
    report => monthlyReportToPermission[report],
  );
  const researchUpdatesPermissions = commodityTitleToResearchUpdatesPermissions[commodity.slug];
  const webinarPermissions = commodityTitleToWebinarPermissions[commodity.slug];
  const newsPermissions = commodity.hasNews ? ['insight:news:lng:read'] : [];
  return [
    ...weeklyReportsPermissions,
    ...monthlyReportsPermissions,
    ...researchUpdatesPermissions,
    ...webinarPermissions,
    ...newsPermissions,
  ];
};
