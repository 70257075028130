export const upgradePackagesEn = {
  oilAndRefinedProducts: {
    title: 'Oil & Refined Products',
    description:
      "Kpler's Oil & Refined Products Insights provide comprehensive analysis of global commodity flows, price trends, and supply-demand dynamics. The service delivers actionable buy/sell signals and includes daily updates, detailed weekly and monthly reports, and timely geopolitical impact assessments. Built to simplify complex market data, these insights help users identify trading opportunities and navigate market challenges effectively.",
  },
  lngAndNaturalGas: {
    title: 'LNG & Natural Gas',
    description:
      "Kpler's LNG & Natural Gas Insights deliver in-depth analysis of market drivers, pricing trends, and regional trade flows. This product highlights arbitrage opportunities and offers forward-looking trading recommendations through weekly and monthly reports. Supported by proprietary data, it provides a clear view of short- and medium-term market developments to help users stay ahead in the dynamic, global LNG and European and American natural gas markets.",
  },
  riskAndCompliance: {
    title: 'Risk & Compliance',
    description:
      "Kpler's Risk and Compliance product offers comprehensive, expert-driven risk and compliance monitoring with proprietary data for proactive oversight. Supports real-time tracking, regulatory adherence, and risk mitigation decisions with independent insights on shipment movements and evolving market dynamics.",
  },
  drySD: {
    title: 'Dry S&D',
    description:
      "Kpler's dry bulks S&D products provide weekly dry bulk research, monthly reports, 18 month balance & price forecasts for thermal and met coal, in addition to grains supply & demand data for wheat, corn, soybean and barley.",
  },
};
