import type { RouteRecordRaw } from '@kpler/vue2-utils';

const researchNewsRoutes: RouteRecordRaw = {
  name: 'research-news',
  path: 'research-news',
  redirect: to => {
    const { page, ...query } = to.query;
    return { path: `/insight${page}`, query };
  },
};

export default researchNewsRoutes;
