import type { SupplyDemandZone } from 'src/domains/supplyDemand/types';
import { UnitName, UnmanagedUnitName } from 'types/unit';

export enum GrainsSupplyDemandMetric {
  BEGINNING_STOCKS = 'beginning_stocks',
  PLANTED_AREA = 'planted_area',
  HARVESTED_AREA = 'harvested_area',
  YIELD = 'yield',
  PRODUCTION = 'production',
  IMPORTS_MARKETING_YEAR = 'imports_marketing_year',
  SUPPLY_TOTAL = 'supply_total',
  BIOFUEL_USE = 'biofuel_use',
  INDUSTRIAL_USE = 'industrial_use',
  SEEDS = 'seed_use',
  FSI_CONSUMPTIONS = 'fsi_consumptions',
  FEED_USE = 'feed_use',
  DOMESTIC_USE_TOTAL = 'domestic_use_total',
  EXPORTS_MARKETING_YEAR = 'exports_marketing_year',
  TOTAL_USE = 'total_use',
  ENDING_STOCK = 'ending_stock',
  STOCK_TO_USE = 'stock_to_use',
}

export const GRAINS_METRICS_LABELS: { readonly [key in GrainsSupplyDemandMetric]: string } =
  Object.freeze({
    [GrainsSupplyDemandMetric.BEGINNING_STOCKS]: 'Beginning stocks',
    [GrainsSupplyDemandMetric.PLANTED_AREA]: 'Planted area',
    [GrainsSupplyDemandMetric.HARVESTED_AREA]: 'Harvested area',
    [GrainsSupplyDemandMetric.YIELD]: 'Yield',
    [GrainsSupplyDemandMetric.PRODUCTION]: 'Production',
    [GrainsSupplyDemandMetric.IMPORTS_MARKETING_YEAR]: 'Imports',
    [GrainsSupplyDemandMetric.SUPPLY_TOTAL]: 'Supply total',
    [GrainsSupplyDemandMetric.BIOFUEL_USE]: 'Biofuel use',
    [GrainsSupplyDemandMetric.INDUSTRIAL_USE]: 'Industrial use',
    [GrainsSupplyDemandMetric.SEEDS]: 'Seeds',
    [GrainsSupplyDemandMetric.FSI_CONSUMPTIONS]: 'FSI consumptions',
    [GrainsSupplyDemandMetric.FEED_USE]: 'Feed use',
    [GrainsSupplyDemandMetric.DOMESTIC_USE_TOTAL]: 'Domestic use total',
    [GrainsSupplyDemandMetric.EXPORTS_MARKETING_YEAR]: 'Exports',
    [GrainsSupplyDemandMetric.TOTAL_USE]: 'Total use',
    [GrainsSupplyDemandMetric.ENDING_STOCK]: 'Ending stock',
    [GrainsSupplyDemandMetric.STOCK_TO_USE]: 'Stocks/use',
  });

export const GRAINS_METRICS_UNITS: {
  readonly [key in GrainsSupplyDemandMetric]: UnitName | UnmanagedUnitName;
} = Object.freeze({
  [GrainsSupplyDemandMetric.BEGINNING_STOCKS]: UnitName.KTONS,
  [GrainsSupplyDemandMetric.PLANTED_AREA]: UnmanagedUnitName.KHA,
  [GrainsSupplyDemandMetric.HARVESTED_AREA]: UnmanagedUnitName.KHA,
  [GrainsSupplyDemandMetric.YIELD]: UnmanagedUnitName.TON_HA,
  [GrainsSupplyDemandMetric.PRODUCTION]: UnitName.KTONS,
  [GrainsSupplyDemandMetric.IMPORTS_MARKETING_YEAR]: UnitName.KTONS,
  [GrainsSupplyDemandMetric.SUPPLY_TOTAL]: UnitName.KTONS,
  [GrainsSupplyDemandMetric.BIOFUEL_USE]: UnitName.KTONS,
  [GrainsSupplyDemandMetric.INDUSTRIAL_USE]: UnitName.KTONS,
  [GrainsSupplyDemandMetric.SEEDS]: UnitName.KTONS,
  [GrainsSupplyDemandMetric.FSI_CONSUMPTIONS]: UnitName.KTONS,
  [GrainsSupplyDemandMetric.FEED_USE]: UnitName.KTONS,
  [GrainsSupplyDemandMetric.DOMESTIC_USE_TOTAL]: UnitName.KTONS,
  [GrainsSupplyDemandMetric.EXPORTS_MARKETING_YEAR]: UnitName.KTONS,
  [GrainsSupplyDemandMetric.TOTAL_USE]: UnitName.KTONS,
  [GrainsSupplyDemandMetric.ENDING_STOCK]: UnitName.KTONS,
  [GrainsSupplyDemandMetric.STOCK_TO_USE]: UnmanagedUnitName.PERCENTAGE,
});

export enum Products {
  CORN = 1354,
  SOYBEAN = 2216,
  WHEAT = 2402,
}

export const productsOptions = [
  { id: Products.CORN, name: 'Corn' },
  { id: Products.SOYBEAN, name: 'Soybean' },
  { id: Products.WHEAT, name: 'Wheat' },
];

/**
 * This is currently hard-coded here, in order to go for the simplest solution, keeping things flexible while delivering the value.
 * The values are findable here: https://github.com/Kpler/supply-demand-grains-administration/blob/main/data/static_data/commodity_zone.csv
 */
export const zonesByProducts: {
  [key in Products]: Array<SupplyDemandZone & { isMajorExporter: boolean }>;
} = {
  [Products.CORN]: [
    {
      id: 947,
      name: 'United States',
      isMajorExporter: true,
      type: 'country',
    },
    {
      id: 141,
      name: 'Brazil',
      isMajorExporter: true,
      type: 'country',
    },
    {
      id: 55,
      name: 'Argentina',
      isMajorExporter: true,
      type: 'country',
    },
    {
      id: 941,
      name: 'Ukraine',
      isMajorExporter: true,
      type: 'country',
    },
    {
      id: 757,
      name: 'Russia',
      isMajorExporter: true,
      type: 'country',
    },
    {
      id: 167,
      name: 'Canada',
      isMajorExporter: false,
      type: 'country',
    },
    {
      id: 447,
      name: 'India',
      isMajorExporter: false,
      type: 'country',
    },
  ],
  [Products.SOYBEAN]: [
    {
      id: 947,
      name: 'United States',
      isMajorExporter: true,
      type: 'country',
    },
    {
      id: 141,
      name: 'Brazil',
      isMajorExporter: true,
      type: 'country',
    },
    {
      id: 55,
      name: 'Argentina',
      isMajorExporter: true,
      type: 'country',
    },
  ],
  [Products.WHEAT]: [
    {
      id: 947,
      name: 'United States',
      isMajorExporter: true,
      type: 'country',
    },
    {
      id: 113302,
      name: 'EU-27',
      isMajorExporter: true,
      type: 'community',
    },
    {
      id: 757,
      name: 'Russia',
      isMajorExporter: true,
      type: 'country',
    },
    {
      id: 941,
      name: 'Ukraine',
      isMajorExporter: true,
      type: 'country',
    },
    {
      id: 167,
      name: 'Canada',
      isMajorExporter: true,
      type: 'country',
    },
    {
      id: 65,
      name: 'Australia',
      isMajorExporter: true,
      type: 'country',
    },
    {
      id: 55,
      name: 'Argentina',
      isMajorExporter: true,
      type: 'country',
    },
  ],
};
